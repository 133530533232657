import { mergeStyleSets } from "@fluentui/react";

export const previewListingStyles = mergeStyleSets({
    containerItem: {
        alignSelf: "center",
        maxWidth: "524px",
        padding: "4px",
        cursor: "pointer",
        ":hover":{
            borderRadius: "8px",
            backgroundColor: "#EEEEEE",
        }
    },
    divItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        position: "relative"
    },
    listName: {
        maxWidth: "486px",
        overflow: "hidden",
        display:"inline-block", 
        textOverflow: "ellipsis" , 
        whiteSpace: "nowrap", 
    },
    currentContent: {
        borderRadius: "8px",
        backgroundColor: "#EEEEEE",
    },
    openThumbnail: {
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
    },
    divFlex: {
        display: "flex",
        justifyContent: "center",
    }
});

export const previewItemStyles = mergeStyleSets({
    showListContainer: {
        backgroundColor: "#FBFBFB", 
        display: "flex",
        minHeight: "54px", 
        justifyContent: "space-between",
        overflowX: "auto",
        gap: "8px",
        "::-webkit-scrollbar":{
            display: "none"
        }
    },
    arrow: {
        position: "sticky",
        top: "0px",
        width: "35px",
        backgroundColor: "#FBFBFB",
        display: "flex",
        alignItems: "center",
        zIndex: 1,
    },
    svgArrow: {
        cursor: "pointer",
        ":hover":{
            backgroundColor: "#FBFBFB",
            borderRadius: "50%"
        }
    }
})
