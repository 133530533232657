import { useBoolean } from "@fluentui/react-hooks";
import { Divider } from "@mui/material";
import moment from "moment";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import LoaderComp from '../../../components/Loader/LoaderComp';
import MenuCont from "../../../components/Menu/Menu";
import { MenuConfig } from "../../../components/Menu/MenuConfig";
import Status from "../../../components/Status/Status";
import { UseStatus } from "../../../components/Status/hooks/useStatus";
import { GridContext } from '../../../context/GridProvider/GridContext';
import { GridContextType } from '../../../context/GridProvider/GridContextTypes';
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { Archiving, Temporality, WorkSpaceUserName } from "../../../model/ItemModel";
import { TagName } from "../../../model/Tags";
import { ResetTemporality, SetArchiving, SetDraft, SetRelease, UndoDraft } from "../../../services/Item/ItemServicesCRUD";
import { Download, GetD4SignStatus } from "../../../services/Item/ItemServicesGet";
import { CheckHaveTheAppInstall, CreateOpenFile } from "../../../services/OpenFileServices";
import DocumentDetail from "../../DocumentDetail/DocumentDetail";
import stylesList from "../../Style/List.module.css";
import { CopyFolderStructureDrawerParameters } from "../PanelItens/CopyFolderStructureDrawer";
import { EditFolderOpen } from '../PanelItens/EditFolder';
import { RenameConfig } from '../PanelItens/RenameComp';
import { ShareFileConfig } from "../PanelItens/ShareFile/ShareFileComp";
import { TemporalityConfig } from "../PanelItens/TemporalityDocument";
import Tags from "../Tags";
import D4SignFilewView from "../Viwer/Signed/D4SignFilewView";
import styles from "../WorkSpaceItemList.module.css";
import { filesSelected } from "../WorkSpaceItemListFile";
import WorkSpaceItemListFileComponentImage from "../WorkSpaceItemListFileComponentImage";
import { CanShow } from "../WorkSpaceItemListStyle/CanShow";
import { checkbox, createdAt, description, file, GridColumnDeleted, icon, menu, size, status, tags, term, validationRule } from "../WorkSpaceItemListStyle/ColumnStandard";
import { returnStyles } from "../WorkSpaceItemListStyle/WorkSpaceItemListStyle";
import { ClockField } from "./ClockField";
import { DescriptionField } from "./Components/DescriptionField/DescriptionField";
import Loupe from "./Components/Loupe/Loupe";
import { NameField } from "./Components/NameField/NameField";
import { ReplaceFile } from "./Components/ReplaceFile/ReplaceFile";
import DialogUnduDraft from "./DialogUnduDraft/DialogUnduDraft";
import ModalTemporalityComponent from "./ModalTemporalityComponent";
import TagsField from "./TagsField";
import ValidationRuleButton from "./ValidationRuleButton/ValidationRuleButton";
import { WorkItemPropsTyped } from "./WorkItemProps/WorkItemProps";

export interface CanShowAlert {
  setIsCheckedExternal(set: boolean): void;
  getFilesSelected(): filesSelected;
}

export interface IUserToSend {
  logins: string[]
  emails: string[]
  term: string
}

const WorkSpaceItemListFileComponent: React.ForwardRefExoticComponent<WorkItemPropsTyped> = React.forwardRef(({ 
  workItemProps:{
    workspaceItem,
    setIsReleaze,
    setValidationRule,
    nameOrg,
    deleteItem,
    setFolderExternal,
    idWorkspace,
    nameWorkspace,
    setPopupError,
    setPopupOk,
    onclickRevision,
    onclikMoveOpen,
    layoutPendencies,
    renameUpdate,
    temporalityDocument,
    editFolderOpen,
    setShareFileConfig,
    permissionType,
    isAdmin,
    boxLayout,
    objectReference,
    onClickFlow,
    handleMultiDrag,
    handleChange,
    onDrop,
    refViwer,
    exibirViewer,
    isCurrentViewerItem,
    setMenuConfigConst,
    updateNameDescriptionByIndex,
    setTemporalityAndExpiredExternal,
    indexOfArray,
    openDetailModal,
    batchWaiter,
    batchRefresh,
    selectedCheckbox,
    currentBatchStatus,
    updateTagName,
    notificationEmail,
    setWorkspace,
    updateArchivenByIndex,
    setIsD4SignSignedConst,
    setCopyFolderStructureDrawerParametersExternal,
    columnsSended,
    currentFolder,
    openDownloadAppModal,
    activeLoader,
    disableLoader,
}, isUpload}, ref) => {

  const { columns } = React.useContext(GridContext) as GridContextType;

  const switchColumns: GridColumnDeleted[] = columnsSended != undefined ? columnsSended : columns;

  function setWorkspaceInternal(workSpaceUserName:WorkSpaceUserName)
  {
    if(setWorkspace != undefined)
      setWorkspace(workSpaceUserName, indexOfArray, isUpload);
  }

  function handleMultiDragInternal(ev: React.DragEvent<HTMLDivElement>): void {
    if (isChecked == false) {
      setIsChecked(true);
    }

    if (handleMultiDrag)
      handleMultiDrag(ev, getFilesSelectedInternal());
  }

  const idFile:string = workspaceItem.id?.toString() + "fileInput";

  useImperativeHandle(
    ref,
    () => ({
      setIsCheckedExternal(set: boolean) {
        setIsChecked(set);
      },
      getFilesSelected() {
        return getFilesSelectedInternal();
      },
    }),
  )
  function getFilesSelectedInternal() {
    let select: filesSelected = {
      extension: workspaceItem.extension!,
      id: workspaceItem.id!,
      img: <WorkSpaceItemListFileComponentImage extension={workspaceItem.extension} isFolder={workspaceItem.itemType === "folder" ? true : false} />,
      isFolder: workspaceItem.itemType === "folder",
      name: workspaceItem.name!,
      isRelease: workspaceItem.isRelease!,
      validationRule: workspaceItem.validationRule!,
    }
    return select;
  }

  const handleChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (handleChange != undefined) {
      handleChange(getFilesSelectedInternal(), event.target.checked)
    }
  }

  const { t, i18n } = useTranslation();

  const profile = useProfile();

  const [isSet, setIsSet] = useState<boolean>(false);
  const [isHover, setisHover] = useState<boolean>(false);
  const [unvalidType, setUnvalidType] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);
  const respValidaionRule: boolean = workspaceItem.validationRule != undefined && workspaceItem.validationRule.isTheUserResponsible != undefined ? workspaceItem.validationRule.isTheUserResponsible : false;
  const [isValidateViwer, setIsValidateViwer] = useState<boolean>(false);
  const [isValidateReadViwer, setIsValidateReadViwer] = useState<boolean>(false);
  const [paintBlue, setPaintBlue] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);

  const [tagInternal, setTagInternal] = useState<TagName[] | undefined>(workspaceItem.tagName);

  const [isModalTemporalityOpen, { setTrue: showModalTemporality, setFalse: hideModalTemporality }] = useBoolean(false);

  const [isEditeName, setIsEditedName] = useState<boolean>(false);

  const [gridBody, setGridBody] = useState(switchColumns);
  
  const [hideUndoDraftDialog, { toggle: toggleHideUndoDrafDialog }] = useBoolean(true);
  const [isOPenModalD4Sign, setIsOPenModalD4Sign] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function closeDetail() {
    setIsDetailOpen(false);
  }

  const isReleaseConst: boolean = workspaceItem.isRelease == true;

  const [isOpenTag, { setTrue: openPanelTag, setFalse: dismissPanelTag }] = useBoolean(false);
  const currentStatus = UseStatus(isReleaseConst!, workspaceItem.validationRule, workspaceItem.itemType!, batchWaiter, workspaceItem.archiving, workspaceItem.expired, workspaceItem.isD4SignSigned );
  
  function onClickTag() {
    openPanelTag()
  }

  const editFolder = () => {
    if (workspaceItem.name && editFolderOpen && workspaceItem.id) {
      let editFolderOpenVar: EditFolderOpen = {
        idMongo: workspaceItem.id,
        ownerId: workspaceItem.ownerId,
        ownerName: workspaceItem.ownerName,
        nameChanged: workspaceItem.name,
        sensitivity: workspaceItem.sensitivity,
        notificationEmail: notificationEmail!
      }
      editFolderOpen(editFolderOpenVar);
    }
  }

  const renameExternal = () => {
    if (workspaceItem.id && workspaceItem.itemType) {
      let renameConfig: RenameConfig = {
        itemId: workspaceItem.id,
        nameChanged: workspaceItem.name!,
        extensionChanged: workspaceItem.extension,
        isFolder: workspaceItem.itemType != "file",
      };

        renameUpdate(renameConfig);
      }
    };

    const validateDocumentExternal = () => {
      if (workspaceItem.id && temporalityDocument) {
        let temporalityConfig: TemporalityConfig = {
          itemId: workspaceItem.id,
          setTemporalityInternal:setTemporalityInternal
        };
        temporalityDocument(temporalityConfig);
      }
    };

    function setTemporalityInternal(newTemporality?:Temporality, expired?: boolean, archiving?: boolean, draft?: boolean)
    {
      if(archiving){
        updateArchivenByIndex(indexOfArray, true, isUpload);
      }
      if(draft){
        setIsReleaze(false, indexOfArray, isUpload);
      }
      if(setTemporalityAndExpiredExternal != undefined)
        setTemporalityAndExpiredExternal(indexOfArray, isUpload, newTemporality, expired, archiving);
    }

    useEffect(() => {
      if (permissionType == 4) {
        setUnvalidType(true);

      } else {
        setUnvalidType(false);

        if (permissionType == 2) {
          setIsValidateViwer(true);
          setIsValidateReadViwer(false);
        } else if (permissionType == 1 || permissionType == 3) {
          setIsValidateViwer(false);
          setIsValidateReadViwer(false);
        } else {
          setIsValidateReadViwer(true);
          setIsValidateViwer(false);
        }
      }
    }, [
      workspaceItem.itemType,
      permissionType,
      i18n.language,
    ]);

    useEffect(() => {
      
      if(currentStatus === "Draft" && (workspaceItem.currentUserDraft == profile.id)){
        setPaintBlue(true);
      }
      else if(currentStatus !== "Draft" && currentStatus !== "Release" && currentStatus !== "---" && respValidaionRule){
        setPaintBlue(true);
      }
      else
      {
        setPaintBlue(false);
      }

      let orgAdmin: boolean = false;
      if (profile.organizations != undefined) {
        for (let organization of profile.organizations) {
          if (nameOrg == organization.internalName) {
            orgAdmin = true;
          }
        }
      }

    }, [profile, workspaceItem.validationRule, isReleaseConst, respValidaionRule, workspaceItem.currentUserDraft]);

  const onDownloadProgress = (progressEvent: any) => {
    setProgress(
      Math.round((progressEvent.loaded * 100) / progressEvent.total)
    );
  };

  function downloadFile() {
    if (workspaceItem.name) {
      Download(workspaceItem.id!, onDownloadProgress)
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", workspaceItem.name + "." + workspaceItem.extension);
          document.body.appendChild(link);

          link.click();
          link.parentNode?.removeChild(link);
          setPopupOk(true, t("DownloadSuccess.message"));
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            let blob = new Blob([error.response.data]);
            blob
              .text()
              .then((message) => {
                setPopupError(true, message);
              })
              .catch(() => {
                setPopupError(true, t("DownloadFileError.message"));
              });
          } else {
            setPopupError(true, t("DownloadFileError.message"));
          }
        });
    }
  }

    function verifyTemporalityToDraft(){
      if(workspaceItem.itemTemporality != null){
        showModalTemporality()
      }
      else{
        setDraft()
      }
    }

      const setDraft = () => {
        if (workspaceItem.id != undefined && batchRefresh) {
          setIsSet(true);
          SetDraft(workspaceItem.id)
            .then((message) => {
              setIsD4SignSignedConst(undefined, false, indexOfArray, isUpload);
              setValidationRule(undefined, indexOfArray, isUpload);
              batchRefresh();
            })
            .catch((error) => {
              if (error.response && error.response.data && typeof error.response.data === 'string') {
                setPopupError(true, error.response.data);
              }
              else {
                setPopupError(true, t("DraftError.message"));
              }
            })
            .finally(() => {
              setIsSet(false);
            });
        }
      }
      

      function UpdateArchivingExt() {

        let archivingl: boolean = workspaceItem.archiving != true;
        if (workspaceItem.id != undefined) {
          let archivingConfig: Archiving =
          {
            idItem: workspaceItem.id,
            archiving: archivingl
          };
          setTemporalityInternal(undefined, false)
          SetArchiving(archivingConfig)
            .then(() => {
              if (archivingl) {
                updateArchivenByIndex(indexOfArray, true, isUpload)
              }
              else {
                updateArchivenByIndex(indexOfArray, false, isUpload)
              }
            })
            .catch((error) => {
              if (error.response && error.response.data && typeof error.response.data === 'string') {
                setPopupError(true, error.response.data);
              }
              else {
                setPopupError(true, t("ArcinvingError.message"));
              }
            })
        }
      }

      const serRelease = () => {
        if (workspaceItem.id != undefined && batchRefresh) {
          setIsSet(true);
          SetRelease(workspaceItem.id)
            .then((message) => {
              setIsReleaze(true, indexOfArray, isUpload);
              setTemporalityInternal(undefined, false)
              batchRefresh!()
            })
            .catch((error) => {
              if (error.response && error.response.data && typeof error.response.data === 'string') {
                setPopupError(true, error.response.data);
              }
              else {
                setPopupError(true, t("ReleaseError.message"));
              }
            })
            .finally(() => {
              setIsSet(false);
            });
        }
      };

      const onClickUndoDraft = () => {
        if (workspaceItem.id != undefined) {
          setIsSet(true);
          toggleHideUndoDrafDialog()
          UndoDraft(workspaceItem.id)
            .then((message) => {
              setPopupOk(true, "Draft desfeito")
            })
            .catch((error) => {
              if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setPopupError(true, error.response.data);
              }
              else
              {
                setPopupError(true, "Falha ao desfazer Draft");
              }
            })
            .finally(() => {
              setIsSet(false);
            });
        }
      };

      const undoDraft = () => {
        toggleHideUndoDrafDialog()
      }

      const setShared = () => {
        if (workspaceItem.id != undefined) {
          let shareFileConfig: ShareFileConfig = {
            itemId: workspaceItem.id,
          };
          setShareFileConfig(shareFileConfig);
        }
      };

      const excluir = () => {
        if (deleteItem && workspaceItem.id) {
          deleteItem(workspaceItem.id);
        }
      };

      const revision = () => {
        if (onclickRevision && workspaceItem.id) {
          onclickRevision(workspaceItem.id);
        }
      };

      const moveOpen = () => {
        if (onclikMoveOpen && workspaceItem.id) {
          onclikMoveOpen(workspaceItem.id);
        }
      };

      const ExibirItemD4SignTrue = () => {
        setIsOPenModalD4Sign(true);
      };

      const preventDefaultBehaviour = (
        event: React.DragEvent<HTMLDivElement>
      ) => {
        event.stopPropagation();
        event.preventDefault();
      };

      const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
        preventDefaultBehaviour(event);
        setisHover(true);
      };

      const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        preventDefaultBehaviour(event);
        setisHover(false);
      };

      function leave() {
        setisHover(false);
      }

      function returnByteWithNOtation(totslByte: number | undefined) {
        if (totslByte == undefined) {
          return "--";
        } else if (totslByte != undefined) {
          let repetion: number = 0;
          let bytes: number = totslByte;

          while (bytes >= 1024 && repetion < 4) {
            bytes = bytes / 1024;
            repetion = repetion + 1;
          }

          if (repetion == 0) {
            return (
              Math.round(bytes).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + " bytes"
            );
          } else if (repetion == 1) {
            return (
              Math.round(bytes).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + " Kb"
            );
          } else if (repetion == 2) {
            return (
              Math.round(bytes).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + " Mb"
            );
          } else if (repetion == 3) {
            return (
              Math.round(bytes).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + " Gb"
            );
          } else if (repetion == 4) {
            return (
              Math.round(bytes).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + " Tb"
            );
          }
        }
      }
      
      const refRef = React.useRef<any | null>(null);
      const nameContainerRef = React.useRef<any | null>(null);

      const ExibirItemD4Sign = (exibir: boolean) => {
        setIsOPenModalD4Sign(exibir);
      };


      const onClickExibirViewer = () => {
        if (editFolderOpen && exibirViewer) {
          const menuConfig = returnMenuConfig()
  
          exibirViewer(workspaceItem.id!, workspaceItem.name!, workspaceItem.extension!, menuConfig)
        }
      };

  const openCopyFolderStructureDrawer = () => {
    if (setCopyFolderStructureDrawerParametersExternal && workspaceItem.id && workspaceItem.name) {
      const copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters = {
        id: workspaceItem.id,
        name: workspaceItem.name,
      }
      setCopyFolderStructureDrawerParametersExternal(copyFolderStructureDrawerParameters);
    }
  }


  const openFileInWindows = () => {
    CreateOpenFile(workspaceItem.id!)
      .then(response => {
        window.open(`keydrive://?OpenFileId=${response.openFileId}&ServerIp=${response.serverIp}&ServerPort=${response.serverPort}`, "_self")
        setTimeout(() => {
          CheckHaveTheAppInstall(response.openFileId)
            .then(res => {
              if (!res.haveTheAppInstall) {
                if (openDownloadAppModal) openDownloadAppModal(response.agentDownloadPath);
              }
            }).catch(error => {
              setPopupOk(true, error.response.data);
            }).finally(() => {
              if (disableLoader) disableLoader();
            });
        }, 5000)
      }).catch(error => {
        setPopupOk(true, error.response.data);
      });
  }

  const returnMenuConfig = (): MenuConfig => {
      const menuConfig: MenuConfig = {
        ExibirItemD4SignTrue:ExibirItemD4SignTrue,
        downloadFile:downloadFile,
        excluir:excluir,
        revision:revision,
        serRelease:serRelease,
        setShared: setShared,
        setDraft:verifyTemporalityToDraft,
        currentUserDraft:workspaceItem.currentUserDraft!,
        extension:workspaceItem.extension,
        isAdmin:isAdmin,
        isD4SignSignedConst:workspaceItem.isD4SignSigned,
        isReleaseConst:isReleaseConst,
        itemType:workspaceItem.itemType,
        layoutPendencies:layoutPendencies,
        ownerId:workspaceItem.ownerId,
        permissionType:permissionType,
        validatioRuleConst: workspaceItem.validationRule,
        idItem:workspaceItem.id!,
        nameItem: workspaceItem.name!,
        openViwer:false,
        nameOrg:nameOrg,
        idWorkspace:idWorkspace,
        nameWorkspace:nameWorkspace,
        searshName:"",
        deleteItem:deleteItem,
        onClickRevision:onclickRevision,
        moveOpen:moveOpen,
        editFolderOpen:editFolderOpen!,
        setEditFolderOpen:editFolder,
        setPopupOkMessage:setPopupOk,
        setPopupErroMessage:setPopupError,
        setArchiving:UpdateArchivingExt,
        archiving:workspaceItem?.archiving,
        rename: renameExternal,
        temporalityDocument:validateDocumentExternal,
        boxLayout: boxLayout,
        undoDraftIsOpen: workspaceItem.unduDraftIsOpen,
        undoDraft:undoDraft,
        existStorageArchive:workspaceItem.existStorageArchive,
        openCopyFolderStructureDrawer: openCopyFolderStructureDrawer,
        currentFolder: currentFolder,
        openFileInWindows: openFileInWindows,
      }
      
      return menuConfig;
    }

    const reset = () =>
    {
        ResetTemporality(workspaceItem.id!)
        .then(() =>{
          setTemporalityInternal(undefined, false)
          setPopupOk(true, t("Reset.message"));
          setIsD4SignSignedConst(undefined, false, indexOfArray, isUpload);
          setIsReleaze(false, indexOfArray, isUpload);
          setValidationRule(undefined, indexOfArray, isUpload);
          closeModalTemporality()
          setDraft();
        })
        .catch((error) => {
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
              setPopupError(true, error.response.data);
            }
            else
            {
              setPopupError(true, t("ErroAoSalvar.message"));
            }
        })
        .finally(() => {
        })
    }

    const closeModalTemporality = () => {
      hideModalTemporality();
    };  

    useEffect(() => {
      if (isCurrentViewerItem === true) {
        if (setMenuConfigConst) {
          setMenuConfigConst(returnMenuConfig())
        }
      }
    }, [isCurrentViewerItem])
    
    useEffect(() => {
      let arrayContainsFilesId: string[] | undefined = selectedCheckbox?.map(item => item.id);
      if (arrayContainsFilesId !== undefined && arrayContainsFilesId.includes(workspaceItem.id!) && currentBatchStatus !== undefined) {
        if (currentBatchStatus === "DRAFT") {
          setIsReleaze(false, indexOfArray, isUpload);
          setValidationRule(undefined, indexOfArray, isUpload);
        }

        if (currentBatchStatus === 'RELEASE') {
          setIsReleaze(true, indexOfArray, isUpload);
        }

        if (currentBatchStatus === undefined) {
          setIsReleaze(workspaceItem.isRelease == true, indexOfArray, isUpload);
        }
      } 
    }, [currentBatchStatus])

    useEffect(() => {
      const gridLocalStorage = JSON.parse(
        localStorage.getItem("gridColumn") ?? "[]"
      );

      if (JSON.stringify(gridLocalStorage) !== JSON.stringify([])) {
        setGridBody(gridLocalStorage);
      }

    }, []);

    useEffect(() => {
      if (gridBody !== switchColumns) {
        setGridBody(switchColumns);
      }
    }, [gridBody, switchColumns]);
    
    useEffect(() => {
      const fileId = searchParams.get("fileId")
      if(fileId) {
        if (workspaceItem.id === fileId){
          setIsDetailOpen(true);
          searchParams.delete("fileId");
          setSearchParams("");
        }
      }
    }, [])

  return (
    <>
      <li
        className={`${workspaceItem?.archiving ? stylesList.disabledList : null}`}
        key={idWorkspace}
        ref={objectReference}
        style={{
          background: workspaceItem.ownerId == profile.id && !layoutPendencies ?
            "linear-gradient(90deg, #ffff 153px, #F4F5F7 0%)" : "",
          position: 'relative'
        }}
      >
        <div
          className={returnStyles(layoutPendencies, switchColumns).gridBody}
          draggable={!unvalidType && layoutPendencies == false}
          onDragStart={!unvalidType ? handleMultiDragInternal : undefined}
          ref={refViwer}
        >
          {!layoutPendencies && (
            <div className={`${workspaceItem.ownerId == profile.id || isAdmin && workspaceItem?.archiving
              ? stylesList.disabledTrue
              : null}`}
              style={{
                gridArea: menu
              }}
            >
              {editFolderOpen != undefined && (
                <MenuCont
                  menuConfig={returnMenuConfig()} />
              )}
            </div>
          )}

          {handleChange != undefined && !layoutPendencies && (
            <div style={{
              gridArea: checkbox,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <input
                className={`${styles.checkboxGrid} ${styles.checkboxContainer}`}
                type="checkbox"
                onChange={handleChangeInternal}
                value={workspaceItem.name!}
                id={workspaceItem.id!}
                src={workspaceItem.extension! ? workspaceItem.extension! : "folder"}
                checked={isChecked}
              />
            </div>
          )}
          <div
            style={{
              gridArea: status,
              pointerEvents: 'auto'
            }}
          >
            <Status
              release={isReleaseConst!}
              type={workspaceItem.itemType!}
              validationObject={workspaceItem.validationRule}
              onClick={() => !workspaceItem?.archiving ? onClickFlow({ idMongo: workspaceItem.id!, nameFile: workspaceItem.name!, extensionFile: workspaceItem.extension! }) : null}
              batchWaiter={batchWaiter}
              archiving={workspaceItem?.archiving}
              expired={workspaceItem.expired}
              d4Signed={workspaceItem.isD4SignSigned}
            />
          </div>

          {!layoutPendencies && (
            <div
              style={{
                gridArea: file,
                margin: 'auto'
              }}
            >
              <ReplaceFile
                currentUserDraft={workspaceItem.currentUserDraft}
                idFile={idFile}
                isReleaseConst={isReleaseConst}
                itemType={workspaceItem.itemType!}
                permissionType={permissionType!}
                profile={profile}
                validationRuleConst={workspaceItem.validationRule}
                idMongo={workspaceItem.id!}
                isUpload={isUpload}
                workspaceItem={workspaceItem}
              />
            </div>
          )}

        {!layoutPendencies && workspaceItem.itemType !== 'folder' && (
            <Loupe
              downloadFile={downloadFile}
              onClickExibirViewer={onClickExibirViewer}
              extension={workspaceItem.extension}
              itemType={workspaceItem.itemType}
            />
          )}

          {!layoutPendencies && <Divider component={'hr'} orientation="vertical" sx={{
            position: 'absolute', left: '0', marginLeft: '153px',
            border: `${workspaceItem.ownerId == profile.id ? "1.7px solid #114D88" : ''}`
          }} />
          }

          <div
            style={{
              gridArea: icon,
              marginLeft: '4px'
            }}
          >
            {isEditeName == false && (
              <WorkSpaceItemListFileComponentImage extension={workspaceItem.extension} isFolder={workspaceItem.itemType === "folder" ? true : false} />
            )}
          </div>

          <NameField
            onDrop={onDrop}
            extension={workspaceItem.extension!}
            id={workspaceItem.id}
            isHover={isHover}
            isValidateReadViwer={isValidateReadViwer}
            isValidateViwer={isValidateViwer}
            itemType={workspaceItem.itemType!}
            layoutPendencies={layoutPendencies}
            leave={leave}
            nameFile={workspaceItem.name}
            onDragEnterAndOver={onDragEnterAndOver}
            onDragLeave={onDragLeave}
            progress={progress}
            setFolderExternal={setFolderExternal}
            unvalidType={unvalidType}
            boxLayout={boxLayout}
            updateNameDescriptionByIndex={updateNameDescriptionByIndex}
            indexOfArray={indexOfArray}
            setPopupError={setPopupError}
            setPopupOk={setPopupOk}
            description={workspaceItem.description}
            setIsEditedName={setIsEditedName}
            setWorkspace={setWorkspaceInternal}
            openDetailModal={openDetailModal}
            setIsDetailOpen={setIsDetailOpen}
            isUpload={isUpload}
          />

        {CanShow(switchColumns, description) && (
          <DescriptionField 
            indexOfArray={indexOfArray}
            id={workspaceItem.id}
            name={workspaceItem.name}
            descriptionFile={workspaceItem.description}
            itemType={workspaceItem.itemType}
            layoutPendencies={layoutPendencies} 
            setPopupError={setPopupError}
            setPopupOk={setPopupOk}
            isDetailPage={false}
            updateNameDescriptionByIndex={updateNameDescriptionByIndex}
            isUpload={isUpload}
            boxLayout={boxLayout}
          />
        )}

          {CanShow(switchColumns, validationRule) && (
            <ValidationRuleButton
              indexOfArray={indexOfArray}
              isValidateReadViwer={isValidateReadViwer}
              nameOrg={nameOrg}
              workspaceItem={workspaceItem}
              setPopupError={setPopupError}
              setWorkspace={setWorkspace}
              isUpload={isUpload}
              setPopupOk={setPopupOk}
              idWorkspace={idWorkspace}
              unvalidType={unvalidType}
              respValidationRule={respValidaionRule}
              setValidationRule={setValidationRule}
            />
          )}

          {CanShow(switchColumns, createdAt) && (
            <div
              style={{
                textAlign: "center",
                font: "normal normal 600 18px/27px Segoe UI",
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 1,
                gridArea: createdAt
              }}
              className={styles.DateList}
            >
              {workspaceItem.lastWriteDate == undefined || workspaceItem.lastWriteDate == "" ? "---" : moment(workspaceItem.lastWriteDate).format("DD/MM/YYYY")}
            </div>
          )}

          {CanShow(switchColumns, tags) && !layoutPendencies && (
            <div style={{
              gridArea: tags,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <TagsField
                boxLayout={boxLayout}
                itemType={workspaceItem.itemType!}
                onClickTag={onClickTag}
                tagInternal={tagInternal}
              />

              <Tags
                idMongo={workspaceItem.id!}
                idWorkspace={idWorkspace}
                setPopupError={setPopupError}
                setPopupOk={setPopupOk}
                tags={tagInternal!}
                dismissPanel={dismissPanelTag}
                isOpen={isOpenTag}
                name={workspaceItem.name!}
                descricao={workspaceItem.description!}
                updateTagName={updateTagName!}
                indexOfArray={indexOfArray}
                setTagInternal={setTagInternal}
                isUpload={isUpload}
              />
            </div>
          )}
          {CanShow(switchColumns, size) && (
            <div style={{
              gridArea: size,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
              {workspaceItem.itemType == "file" &&
                returnByteWithNOtation(workspaceItem.size)}
            </div>
          )}

          {isSet && <LoaderComp />}

          {CanShow(switchColumns, term) && (
            <ClockField
              isReleaseConst={isReleaseConst}
              timeDeadLine={workspaceItem.validationRule?.timeDeadline}
              validatioRule={workspaceItem.validationRule}
              itemTemporality={workspaceItem.itemTemporality}
            />
          )}

          <DocumentDetail
            isOpen={isDetailOpen}
            isOpenTag={isOpenTag}
            openPanelTag={openPanelTag}
            closeDetail={closeDetail}
            workItem={workspaceItem}
            returnMenuConfig={returnMenuConfig}
            status={currentStatus}
            setPopupError={setPopupError}
            setPopupOk={setPopupOk}
            setTagInternal={setTagInternal}
            tagInternal={tagInternal}
            isValidateReadViwer={isValidateReadViwer}
            itemTypeInternal={workspaceItem.itemType!}
            respValidationRule={respValidaionRule}
            unvalidType={unvalidType}
            boxLayout={boxLayout}
            onClickTag={onClickTag}
            isHover={isHover}
            isValidateViwer={isValidateViwer}
            leave={leave}
            onDragEnterAndOver={onDragEnterAndOver}
            onDragLeave={onDragLeave}
            progress={progress}
            setFolderExternal={setFolderExternal}
            itemType={workspaceItem.itemType!}
            updateTagName={updateTagName!}
            indexOfArray={indexOfArray}
            parameter={undefined}
            updateNameDescriptionByIndex={updateNameDescriptionByIndex}
            isUpload={isUpload}
            nameOrg={nameOrg}
            setWorkspace={setWorkspace}
            setValidationRule={setValidationRule}
            idWorkspace={idWorkspace}
            currentFolder={currentFolder}
          />

          <D4SignFilewView idItem={workspaceItem?.id!} isOpen={isOPenModalD4Sign!} setIsOpen={setIsOPenModalD4Sign} getLink={GetD4SignStatus} />

          <ModalTemporalityComponent
            closeModalTemporality={closeModalTemporality}
            isModalTemporalityOpen={isModalTemporalityOpen}
            reset={reset}
          />

          <DialogUnduDraft
            hidden={hideUndoDraftDialog}
            onDismiss={toggleHideUndoDrafDialog}
            title={t("Item.undoDraft")}
            subText={t("ConfirmTheDiscardOfTheDraft.message") + ` - ${workspaceItem.name}.${workspaceItem.extension}`}
            primaryButton={{ onClick: onClickUndoDraft, text: t("Undo.message") }}
            secondaryButton={{ onClick: toggleHideUndoDrafDialog, text: t("Cancelar.message") }}
          />
        </div>
      </li>
    </>
  );
})

export default WorkSpaceItemListFileComponent;
