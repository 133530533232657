import React, { useEffect, useState } from 'react';
import { ProSidebar } from 'react-pro-sidebar';
import "react-pro-sidebar/dist/css/styles.css";
import { useNavigate,useParams } from "react-router-dom";
import { TranslatorS } from '../../components/I18n';
import { useTranslation } from "react-i18next";
import {GetIdWorkSpaceLocalStorage,GetInternalNameOrg} from "../../pages/ProtectedLayout/WorkSpaceMenager"
import { ContentbarProps } from '../Contentbar/ContentSide/ContentSideComp';
import ContentbarList from '../Contentbar/ContentSide/ContentSideList';
import { IconSideComponent } from './components/IconSideComponent';
import {
  doubleArrowIcon
} from '../../assets/icons';
import { DocumentType } from '../../assets/icons/documentType';
import { Feed } from '../../assets/icons/feed';
import { Group } from '../../assets/icons/group';
import { Pendencie } from '../../assets/icons/pendencie';
import { Settings } from '../../assets/icons/settings';
import { Users } from '../../assets/icons/users';
import { ValidationRule } from '../../assets/icons/validationRule';
import { Workspace } from '../../assets/icons/workspace';
import {GetWorkspaceLocalStorage } from "../../pages/PublicLayout/RedirectManager";
import { Reports } from '../../assets/icons/reports';
import Analystic from '../../assets/icons/Analytic';
import { RecicleBin } from '../../assets/icons/recicleBin';

export type isValidUserPendencies = 'true' | 'false' | 'waiting' | 'invisible';

interface SidebarProps {
  onChange?: (event: React.MouseEvent, isChecked?: boolean) => void;
  isCheckedInitial: boolean;
  isOrganizationVisible:boolean;
  idOrganization?:string;
  isWorkspaceVisible:boolean;
  isUsersVisible:boolean;
  isGroupspaceVisible:boolean;
  isDocuemntTypeVisible:boolean;
  isValidationRuleVisible:boolean;
  isFileVisible:boolean;
  idFeedTrackerVisible:boolean;
  isUserPendencies?: isValidUserPendencies;
  internalName?:string;
  isHambNavbar?:boolean;
  catchContentbar: (contentForBar: ContentbarProps[]) => void
  hasBeenUpdated: boolean,
  idSelectd: number| undefined;
  setIdSelectd: (newIndex: number| undefined) => void
  isOnlyRead?: boolean;
}
 
export function Sidebar({
  onChange, 
  idOrganization,
  internalName,
  isCheckedInitial,
  isOrganizationVisible,
  isWorkspaceVisible,
  isUsersVisible,
  isGroupspaceVisible,
  isDocuemntTypeVisible,
  isValidationRuleVisible,
  isFileVisible,
  isUserPendencies,
  idFeedTrackerVisible,
  isHambNavbar,
  catchContentbar,
  hasBeenUpdated,
  idSelectd,
  setIdSelectd,
  isOnlyRead,
  ...rest 
}: SidebarProps) {
  const [isChecked, setCheckedState] = useState(isCheckedInitial);
  const navegate = useNavigate();
  const { nameOrg,idWorkspace} = useParams();
  const { t, i18n } = useTranslation();
  const [changeIcon, setChangeIcon] = useState<boolean>(isCheckedInitial);
  const workspaceLocalStorage = GetWorkspaceLocalStorage()

  const fillColorSVG = () => {
    if(isUserPendencies == "false"){
      return "#2dc254"
    } else if(isUserPendencies == "true"){
      return "#dc3055"
    } else if (isUserPendencies == "waiting"){
      return "yellow"
    }

    return "white"
  }

  function handleChange(event: React.MouseEvent){
    setCheckedState(!isChecked);
    setChangeIcon(!isChecked)
    onChange && onChange(event, isChecked);
  };

  function onclickDocumentType(){
   var idWorkSpaceLocalStorage = GetIdWorkSpaceLocalStorage("idWorkspace")
   var internalNameOrg = GetInternalNameOrg("internalNameOrg");

   setIdSelectd(4);

   if(idWorkSpaceLocalStorage != "" && idWorkSpaceLocalStorage != null && internalNameOrg == nameOrg)
      navegate("/" +internalNameOrg+"/WorkSpace/" + idWorkSpaceLocalStorage +"/documentTypes")
    else
      navegate("/" + nameOrg + "/Workspace/");
  }

  function onclickValdiationRules(){
    var idWorkSpaceLocalStorage = GetIdWorkSpaceLocalStorage("idWorkspace")
    var internalNameOrg = GetInternalNameOrg("internalNameOrg");

    setIdSelectd(5);

    if(idWorkSpaceLocalStorage != "" && idWorkSpaceLocalStorage != null && internalNameOrg == nameOrg)
       navegate("/" +internalNameOrg+"/WorkSpace/" + idWorkSpaceLocalStorage +"/validationRule")
     else
       navegate("/" + nameOrg + "/Workspace?redirect=vr");
   }

  const contentBar: ContentbarProps[] = [
    {
      children: "Workspace",
      type: "sidebar",
      title: "Workspace",
      conditional: isWorkspaceVisible,
      icon: 
        <Workspace
          color={idSelectd == 1?"#2D2D2D":"#cecece"}
        />,
      onClick: ()=>{
        setIdSelectd(1);
        navegate("/" +internalName+"/WorkSpace")
      },
      isSelect:idSelectd == 1
    },
    {
      children: t("Usuarios.message"),
      type: "sidebar",
      title: t("Usuarios.message").toString(),
      conditional: isUsersVisible,
      icon:
        <Users
          color={idSelectd == 2?"#2D2D2D":"#cecece"}
        />
      ,
      onClick: ()=>{
        setIdSelectd(2);
        navegate("/" +internalName+"/users")
      },
      isSelect:idSelectd == 2
    },
    {
      children: t("Grupos.message"),
      type: "sidebar",
      title: t("Grupos.message").toString(),
      conditional: isGroupspaceVisible,
      icon:
        <Group
          color={idSelectd == 3?"#2D2D2D":"#cecece"}
        />
      ,
      onClick: ()=>{
        setIdSelectd(3);
        navegate("/" +internalName+"/groups")
      },
      isSelect:idSelectd == 3
    },
    {
      children: t("TiposDeDocumento.message"),
      type: "sidebar",
      title: t("TiposDeDocumento.message").toString(),
      conditional: isDocuemntTypeVisible,
      icon:
        <DocumentType
          color={idSelectd == 4?"#2D2D2D":"#cecece"}
        />
      ,
      onClick: onclickDocumentType,
      isSelect:idSelectd == 4
    },
    {
      children: t("ValidationRule.menu"),
      type: "sidebar",
      title: t("ValidationRule.menu").toString(),
      conditional: isValidationRuleVisible,
      icon:
        <ValidationRule
          color={idSelectd == 5?"#2D2D2D":"#cecece"}
        />
      ,
      onClick: onclickValdiationRules,
      isSelect:idSelectd == 5
    },
    {
      children: "Feed",
      type: "sidebar",
      title: "Feed",
      conditional: idFeedTrackerVisible,
      icon:
        <Feed
          color={idSelectd == 6?"#2D2D2D":"#cecece"}
        />
      ,
      onClick: ()=>{
        setIdSelectd(6);
        navegate("/" +internalName+"/Item/tracker")
      },
      isSelect:idSelectd == 6
    },
    {
      children: t("Pendencias.message"),
      type: "sidebar",
      title: t("Pendencias.message").toString(),
      conditional: isOrganizationVisible,
      icon: 
        <Pendencie
          color={idSelectd == 7?"#2D2D2D":"#cecece"}
          colorCircle={fillColorSVG()}
        />,
      onClick: () => {
        navegate("/" + internalName +"/ValidationRules/UserPendencies");
        setIdSelectd(7);
      },
      isSelect:idSelectd == 7
    },
    {
      children: t("Temporality.message"),
      type: "sidebar",
      title: t("Temporality.message"),
      conditional: isOrganizationVisible,
      icon: 
        <Reports 
          color={idSelectd == 9 ? "#2D2D2D" : "#cecece"}
        />,
      onClick: () => {
        navegate("/" + internalName +"/temporality/");
        setIdSelectd(9);
      },
      isSelect:idSelectd == 9
    },
    {
      children: t("RecicleBin.title"),
      type: "sidebar",
      title: t("RecicleBin.title"),
      conditional: idFeedTrackerVisible && !isOnlyRead,
      icon: 
        <RecicleBin
          color={idSelectd == 11 ? "#2D2D2D" : "#cecece"}
        />,
      onClick: () => {
        navegate("/" + internalName +"/recicleBin/");
        setIdSelectd(11);
      },
      isSelect:idSelectd == 11
    },
    {
      children: "Dashboard",
      type: "sidebar",
      title: "Dashboard",
      conditional: isOrganizationVisible,
      icon: 
        <Analystic
          color={idSelectd == 10?"#2D2D2D":"#cecece"}
        />,
      onClick: () => {
        setIdSelectd(10);
        navegate(internalName + "/dashboard")
      },
      isSelect:idSelectd == 10
    },
    {
      children: t("Configuracoes.message"),
      type: "sidebar",
      title: t("Configuracoes.message").toString(),
      conditional: isOrganizationVisible,
      footer: true,
      icon:
        <Settings
          color={idSelectd == 8?"#2D2D2D":"#cecece"}
        />
      ,
      onClick: ()=>{
        setIdSelectd(8);
        navegate("/" +internalName+"/config");
      },
      isSelect:idSelectd == 8
    },
    {
      children: TranslatorS({path:"Diminuir.message"}),
      type: "actionIcon",
      title: t("Expandir.message").toString(),
      conditional: isChecked,
      footer: true,
      icon: changeIcon ? <IconSideComponent icon={doubleArrowIcon} /> : <IconSideComponent icon={doubleArrowIcon} isInvert />,
      handleChange: (event: React.MouseEvent)=>{handleChange(event)},
      isSelect:false
    },
  ]


  useEffect(() => {
    if(contentBar != undefined)
      catchContentbar(contentBar)
  },[hasBeenUpdated])

  return (
      <ProSidebar collapsed={isChecked}>
          {contentBar != undefined &&(
            <ContentbarList contentList={contentBar}/>
          )}
      </ProSidebar>
  );
}

export default Sidebar;