import React, { useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import recycleBinCheckHeader from "../../../../assets/icons/recycleBinCheckHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { DeletedItem } from '../../../../model/ItemModel';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '../../../../assets/icons/icons.tsx/ArrowRightIcon';
import RestoreResult from './components/RestoreResult';

interface RestoreSuccessProps {
  nameOrg: string;
  item: DeletedItem;
  isConfirmed: boolean;
  hideModal: () => void;
}

const RestoreSuccess: React.FC<RestoreSuccessProps> = ({nameOrg, item, isConfirmed, hideModal}) => {
  const { t, i18n } = useTranslation();
  const navegate = useNavigate();

  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);

  function goToFile(){
    hideModal()
    navegate("/" + nameOrg + "/wsallowed/" + item.workspaceId + (item.folderId ? "/" + item.folderId : ""));
  }

  return (
    <div>
      <CustomModal 
        isModalOpen={isConfirmed}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinCheckHeader} alt='check'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.headerRestoreSuccessTitle")}
          </div>
        </div>
        <div style={{padding:"10px 0px 10px 0px", display:"flex", justifyContent: "center", width:"100%"}}>
          <div style={{font:"normal normal normal 16px/24px Segoe UI"}}>
            <div style={{display:"flex", whiteSpace: "nowrap"}}>
              <div>{t("RecicleBin.headerRestoreSuccessPartA")}</div>
              <div style={{fontWeight: 600, marginRight: "7px", marginLeft: "7px"}}>
                "{item.name + (item.extencion ? "." + item.extencion : "")}"
              </div> 
              <div>{t("RecicleBin.headerRestoreSuccessPartB")}</div>   
            </div>
            <div style={{textAlign:"center"}}>{t("RecicleBin.headerRestoreSuccessPartC")}</div> 
          </div>
        </div>

        {item &&
          <RestoreResult
            fatherFolder={item.fatherFolder}
            extencion={item.extencion}
            itemType={item.itemType}
            name={item.name}
            workspaceName={item.workspaceName}
          />
        }

        <div style={{ width: '100%', display: 'flex', gap:"20px" }}>
          <CustomButton 
            icon={<ArrowRightIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={goToFile} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.goToFileFolder")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={hideModal}  
            text={t("RecicleBin.finishAndClose")}
            backgrondColor='#22638D'
            color='#FFFFFF'
            hoveredBackgrondColor='#0d476d'
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default RestoreSuccess;