import React, { useEffect, useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import duplicate from "../../../../assets/icons/duplicate.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LoaderComp from '../../../../components/Loader/LoaderComp';

interface CheckItemsSameNameProps {
  showModal: boolean;
  hideModal: () => void
  itemListQuantity?: number;
  restoreItem: (restoreType: number) => void;
}

const CheckItemsSameName: React.FC<CheckItemsSameNameProps> = ({itemListQuantity, restoreItem, showModal, hideModal}) => {
  const { t, i18n } = useTranslation();
  const fileAction = itemListQuantity ? "Files" : "File";

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);
  const [isOption, setIsOption] = useState<string>(t(`RecicleBin.replace${fileAction}`));
  const [load, setLoad] = useState<boolean>(false)

  function confirmRestore(){
    if(isOption == t(`RecicleBin.replace${fileAction}`)){
      restoreItem(1)
    }else if(isOption == t(`RecicleBin.ignore${fileAction}`)){
      restoreItem(2)
    }else{
      restoreItem(3)
    }

    setLoad(true)
  }

  useEffect(()=>{
    if(showModal)
      setLoad(false)
  },[showModal])

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleOption(value: string){
    setIsOption(value)
    handleClose()
  }

  return (
    <div>
      <CustomModal 
        isModalOpen={showModal}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={duplicate} alt='Duplicate'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.CheckItemsSameNameTitle")}
          </div>
        </div>
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ font: "normal normal normal 16px/24px Segoe UI" }}>
            <div style={{ textAlign:"center" }}>
              <div style={{ display: "flex" }}>
                {t("RecicleBin.headerCheckItemsSameNamePartA")}
                <div style={{fontWeight:600, marginLeft:"7px", marginRight:"7px"}}>
                  "{itemListQuantity ? itemListQuantity : 1}{t("RecicleBin.headerCheckItemsSameNamePartB")}"
                </div>
                {t("RecicleBin.headerCheckItemsSameNamePartC")}
              </div>
              {t("RecicleBin.headerCheckItemsSameNamePartD")}
            </div>
          </div>
        </div>

        <div style={{width:"100%", display:'flex', alignItems:"end", marginBottom:"-18px"}}>
          {load ? (
            <div style={{width:"100%", height:"36px", display:"flex", justifyContent:"center", alignItems:"end", marginBottom:"-10px", marginTop:"14px"}}>
              <LoaderComp/>
            </div>
            ):(
              <div style={{width:"100%", display:"flex", alignItems:"end"}}>
                <CustomButton 
                  icon={<ConfirmIcon/>} 
                  onclick={confirmRestore}  
                  text={isOption}
                  backgrondColor='#22638D'
                  color='#FFFFFF !important'
                  hoveredBackgrondColor='#0d476d'
                  menuRight
                />
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    height:"36px", 
                    minWidth:"36px", 
                    background:"#22638D", 
                    padding:"0px", 
                    borderRadius:"0px 4px 4px 0px",
                    border:"1px solid",
                    borderColor:"#22638D",
                    borderLeft:"1px solid rgb(255, 255, 255, 0.2) !important",
                    '&:hover': {
                      backgroundColor: "#0D476D",
                      borderColor: "#0D476D"
                    },
                  }}
                >
                {open ? <ArrowDropUpIcon style={{ color: '#FFFFFF', fontSize: 30 }} /> : <ArrowDropDownIcon style={{ color: '#FFFFFF', fontSize: 30 }} />}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}  
                  PaperProps={{
                    sx:{ 
                      border: "1px solid #2D2D2D1A", 
                      transform: 'translateY(-8px) !important'
                    }
                  }}
                >
                  <MenuItem sx={{ borderBottom: "1px solid #2D2D2D1A" }} onClick={() => handleOption(t(`RecicleBin.replace${fileAction}`))}>
                    {t(`RecicleBin.replace${fileAction}`)}
                  </MenuItem>
                  <MenuItem sx={{ borderBottom: "1px solid #2D2D2D1A" }} onClick={() => handleOption(t(`RecicleBin.ignore${fileAction}`))}>
                    {t(`RecicleBin.ignore${fileAction}`)}
                  </MenuItem>
                  <MenuItem onClick={() => handleOption(t(`RecicleBin.compare${fileAction}`))}>
                    {t(`RecicleBin.compare${fileAction}`)}
                  </MenuItem>
                </Menu>
              </div>
          )}
        </div>
        
        <CustomButton 
          icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
          onclick={hideModal} 
          setIsHovered={setIsHoveredButtonCancel} 
          text={t("RecicleBin.cancel")}
          backgrondColor='#FCFCFC'
          color='#22638D'
          hoveredBackgrondColor='#0d476d'
          hoveredColor='#FFFFFF'
        />

      </CustomModal>
    </div>
  );
};

export default CheckItemsSameName;