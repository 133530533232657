import React from 'react';
import WorkSpaceItemListFileComponentImage from '../../../../WorkSpaceItem/WorkSpaceItemListFileComponentImage';
import { useTranslation } from 'react-i18next';
import workspaceIcon from "../../../../../assets/icons/workspace.svg";

interface RestoreResultProps {
    workspaceName?: string;
    fatherFolder?: string;
    name?: string;
    extencion?: string;
    itemType?: number;
}

const RestoreResult: React.FC<RestoreResultProps> = ({    
    workspaceName,
    fatherFolder,
    name,
    extencion,
    itemType
    }) => {

  const { t, i18n } = useTranslation();

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ border: "1px solid rgb(45, 45, 45, 0.2)", marginBottom: "16px", borderRadius: "4px" }}>
        <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid rgb(45, 45, 45, 0.2)", width: "100%", height: "44px" }}>
          <div style={{ display: "flex", alignItems: "center", padding: "10px", height: "100%", borderRight: "1px solid rgb(45, 45, 45, 0.2)" }}>
            <img src={workspaceIcon} alt="Workspace" />
          </div>
          <div style={{ marginLeft: "10px", display: "flex" }}>
            <div style={{ color: "#2D2D2D", fontWeight: 600, paddingRight: "7px" }}>
              Workspace:
            </div>
            <div>
              {workspaceName}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid rgb(45, 45, 45, 0.2)", width: "100%", height: "44px" }}>
          <div style={{ display: "flex", alignItems: "center", padding: "12px", height: "100%", borderRight: "1px solid rgb(45, 45, 45, 0.2)" }}>
            <WorkSpaceItemListFileComponentImage isFolder={true} />
          </div>
          <div style={{ marginLeft: "10px", display: "flex", whiteSpace: "nowrap" }}>
            <div style={{ color: "#2D2D2D", fontWeight: 600, paddingRight: "7px" }}>
              {t("RecicleBin.folder")}:
            </div>
            <div>
              {fatherFolder}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "100%", height: "44px" }}>
          <div style={{ display: "flex", alignItems: "center", padding: "12px", height: "100%", borderRight: "1px solid rgb(45, 45, 45, 0.2)" }}>
            <WorkSpaceItemListFileComponentImage extension={extencion} isFolder={itemType === 1 ? true : false} />
          </div>
          <div style={{ marginLeft: "10px", display: "flex", whiteSpace: "nowrap" }}>
            <div style={{ color: "#2D2D2D", fontWeight: 600, paddingRight: "7px" }}>
              {t("RecicleBin.fileRestored")}:
            </div>
            <div>
              {name}{extencion ? "." + extencion : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestoreResult;
