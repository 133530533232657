import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetUniqueLinkSearchParams, LoginRequest, LoginRequestMS } from "../../../context/AuthProvider/util";
import { IUser } from "../../../context/AuthProvider/types";
import { RedirectNavigate } from "../../PublicLayout/RedirectNavigate";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import { Grid, Box, Typography, Link, Divider, TextField } from '@mui/material';
import FormErrors from "../../PublicLayout/components/Handle/Form/Root/components/FormErrors";
import msalConfig from "../../../config/office-auth.config.json";
import { useMsal } from "@azure/msal-react";
import microsoftIcon from '../../../assets/icons/Microsoft_logo_(2012).svg';
import { LoaderIcon } from "../../../assets/icons/loaderIcon";
import { PasswordTextFieldWithRevealMui } from "../../PublicLayout/components/Handle/Form/Components/PasswordTextFieldWithRevealMui";
import CustomButton from "./CustomButton";
import { CheckIcon } from "../../../assets/icons/icons.tsx/CheckIcon";

const LoginComp = (props: any) => {
  const [isChecked, setCheckedState] = useState<boolean>(false);
  const { t } = useTranslation();
  const navegate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const [load, setLoad] = useState<boolean>(false);
  const [loadOffice, setLoadOffice] = useState<boolean>(false);
  const [lg, setLanguage] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { instance } = useMsal();
  const uniqueLink = GetUniqueLinkSearchParams();

  useEffect(() => {
    // verifica se existe token, se sim, usuário redirecionado para o ultimo workspace visitado.
    if (localStorage.getItem("w3kSistem") != null) {
      let organizationAlreadyInLocalstorage = localStorage.getItem("internalNameOrg");
      let workspaceAlreadyInLocalstorage = localStorage.getItem("idWorkspace");
  
      if (organizationAlreadyInLocalstorage != undefined && workspaceAlreadyInLocalstorage != undefined) {
        navegate(`/${organizationAlreadyInLocalstorage}/wsallowed/${workspaceAlreadyInLocalstorage}`);
      } else if (organizationAlreadyInLocalstorage == undefined && workspaceAlreadyInLocalstorage == undefined) {
        navegate(`/`);
      } else if (organizationAlreadyInLocalstorage != undefined && workspaceAlreadyInLocalstorage == undefined) {
        navegate(`/${organizationAlreadyInLocalstorage}`);
      }
    }
  }, []);

  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value != undefined) {
      setEmail(event.target.value);
    } else {
      setEmail("");
    }
  };


  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value != undefined) {
      setPassword(event.target.value);
    } else {
      setPassword("");
    }
  };

  function loginOffice() {

    setCheckedState(false);
    setLoadOffice(true);

    instance.loginPopup({
      scopes: [msalConfig.scopes["User.Read"]],
      authority: msalConfig.auth.authority,
      redirectUri: window.location.origin
    })
    .then(response => {
      if(response.idToken != undefined)
      {
        LoginRequestMS(response.idToken)
        .then(message => {
          setProfile(message.data.access_token);
        })
        .catch((error) => {
          erroManagement(error)
        });
      }
      else
      {
        SetErrorLogin();
      }
    })
    .catch((error) => {
      erroManagement(error)
      setLoadOffice(false);
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoad(true);
    LoginRequest(email, password)
      .then((message) => {
          setProfile(message.data.access_token) // Usuário retornado com 200, manda direto para aplicação.
      })
      .catch((error) => {
        erroManagement(error)
      });
  };

  function setProfile(token:string)
  {
    profile
      .create(token)
      .then(() => {
        setLoad(false);
        let user: IUser = {
          token:token
        };
        auth.setUserExternal(user);
        if (uniqueLink != undefined){
          RedirectNavigate(navegate, uniqueLink)
        }
        else {
          RedirectNavigate(navegate)
        }
      })
      .catch((error) => {
        auth.logout();
        setCheckedState(true);
        setLoad(false);
      });
  }

  function erroManagement(error: any)
  {
    if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.error == "invalid_client"
    ) {
      SetErrorLogin();
    }
    else if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.error == "unauthorized_client"
    ) {
      // Ambos erros passados para cliente não autorizado e não gerando token (retorno de 400 bad request).
      if (error.response.data.user.Key != undefined) { // Verificação caso gerado nova chave mfa no backend.
        navegate(`/login/qrcode/${error.response.data.user.Login}/${error.response.data.user.Key}${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`); // Se sim, deve enviar para ler qrcode novamente.
      } else {
        navegate(`/login/otp/${error.response.data.user.Login}${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`); // Se não, manda para usuário colocar senha OTP gerado nop autenticador.
      }
    } else {
      SetErrorLogin();
    }
  }

  function SetErrorLogin()
  {
      setLoad(false);
      setCheckedState(true);
  }

  

  useEffect(() => {
    var lgn = localStorage.getItem("i18nextLng");
    if (lgn == "pt-BR") {
      setLanguage(true);
    } else {
      setLanguage(false);
    }
  });


  return (
    <LeftForm
      formRootInstance={{
        title:t("Login.accessAcount"),
        subTitle:t("Login.helloAgain"),
      }}
    >
      <Grid container sx={{
        gap: 1,
      }}>
        <Grid item xs={12} >
          <Box marginBottom={'4px'}>
            <Typography
              component='label'
              sx={{
                textAlign: "left",
                font: 'normal normal normal 12px/18px Segoe UI',
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 1,
              }}
            >
              E-mail
            </Typography>
          </Box>
          <Box
            sx={{
              position:"relative"
            }}
          >
            <TextField 
              variant="standard"
              onChange={onChangeEmail}
              value={email}
              placeholder={t("Login.typeYourEmail")}
              sx={{
                width:"100%",
                '&:hover .MuiInputBase-root::before': {
                    borderBottom: '1px solid rgb(45, 45, 45, 0.2)',
                },
                '& .MuiInputBase-root::after': {
                    borderBottom: '1px solid rgb(45, 45, 45, 0.2)',
                },
              }}
              inputProps={{ 
                style: { 
                  font: "normal normal normal 14px/21px Segoe UI"  
                } 
              }}
              onFocus={() => {
                if (isChecked)
                { 
                  setCheckedState(false);
                }
              }}
            />
            <Box
              sx={{
                  position: 'absolute',
                  right: 0,
                  top: 5,
              }}
            >
              <Link
                sx={{
                  textAlign: "left",
                  font: 'normal normal 400 12px/18px Segoe UI',
                  letterSpacing: "0px",
                  color: "#005A9E",
                  opacity: 1,
                  textDecoration: 'none',
                  
                  "&:hover":{
                    textDecoration: "underline"
                  }
                }}
                href="/resetPassword"
              >
                {t("Login.forgotPass")}
              </Link>
            </Box>                       
          </Box>

          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginY: '4px',
            }}>
            <Typography
                component='label'
                sx={{
                  textAlign: "left",
                  font: 'normal normal normal 12px/18px Segoe UI',
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                }}
              >
              {t("Login.password")}
            </Typography>

            </Box>
            <PasswordTextFieldWithRevealMui 
              top={8}
              right={10} 
              isChecked={isChecked} 
              onChange={onChangePassword} 
              value={password} 
              placeholder={t("Login.typeYourPassword")}
              setCheckedState={setCheckedState}
            />
            </Grid>
          <FormErrors 
            messagePass={t("UsuarioOuSenhaIncorretors.message")}
            isChecked={isChecked}
          />
          <Box
            sx={{
              marginTop:"20px",
              width:"100%"
            }}
          >
            <CustomButton 
              heigth="36px"
              bqColor="#22638D"
              bqColorHover="rgb(34, 99, 141, 0.8)"
              bqColorDisabled="rgb(34, 99, 141, 0.2)"
              borderColorDisabled="rgb(34, 99, 141, 0.0)"
              color="#FFFFFF" 
              onclick={(e: any) => handleSubmit(e)} 
              isDisabled={email.length > 0 && password.length > 0 ? false : true}
              borderColor="#22638D"
            >
              {load == true ? <LoaderIcon color=""/> : <CheckIcon/>}
              <Box
                sx={{
                  marginLeft:"10px"
                }}
              >
                {t("Entrar.message")}
              </Box>
            </CustomButton>
          </Box>
          <Divider orientation="horizontal" sx={{ height: '2px', width: '100%', color: '#9A9A9A', display: 'flex', alignItems: 'center', marginY: '8px ', margin: "20px 0px 20px 0px" }}>{t("Divider.message")}</Divider>
          <CustomButton 
            heigth="40px"
            color="#22638D" 
            bqColorHover="rgb(34, 99, 141, 0.2)"
            borderColorHover="rgb(34, 99, 141, 0.0)"
            borderColor="#22638D"
            onclick={loginOffice} 
          >
            {loadOffice == true && <LoaderIcon color=""/> }{t("Login.microsoft")}
            <Box
              sx={{
                marginLeft:"8px",
                height:"100%",
                display:"flex",
                alignItems:"center"
              }}
            >
              <img src={microsoftIcon} alt="microsoftIcon"></img>
            </Box>
          </CustomButton>
      </Grid>
    </LeftForm>
  );
};

export default LoginComp;
