export const MaxWorkspaceIcon= () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="178" height="77.696" viewBox="0 0 178 77.696">
      <g id="Grupo_1305" data-name="Grupo 1305" transform="translate(-666 -305.227)">
        <g id="Grupo_1303" data-name="Grupo 1303" transform="translate(666 305.227)">
          <path id="Caminho_414" data-name="Caminho 414" d="M28.841,19.79A74.805,74.805,0,0,0,0,78.9H32.58a42.412,42.412,0,0,1,19.6-35.767Z" transform="translate(0 -3.901)" fill="#22638d" fill-rule="evenodd" opacity="0.3" />
          <path id="Caminho_415" data-name="Caminho 415" d="M174.012,78.9A74.877,74.877,0,0,0,145.17,19.79L121.8,43.134A42.356,42.356,0,0,1,141.409,78.9Z" transform="translate(-24.012 -3.901)" fill="#22638d" fill-rule="evenodd" />
          <path id="Caminho_416" data-name="Caminho 416" d="M126.292,13.93a75.117,75.117,0,0,0-87.15,0L62.761,37.575a42.515,42.515,0,0,1,39.888,0Z" transform="translate(-7.716)" fill="#22638d" fill-rule="evenodd" opacity="0.5" />
          <path id="Caminho_417" data-name="Caminho 417" d="M93.188,88.769a5.451,5.451,0,0,0,7.557,1.5l24.246-21.836L94.671,81.214a5.453,5.453,0,0,0-1.483,7.555" transform="translate(-18.189 -13.492)" fill="#2d2d2d" fill-rule="evenodd" />
        </g>
        <circle id="Elipse_114" data-name="Elipse 114" cx="27" cy="27" r="27" transform="translate(790 313)" fill="#fff" />
        <circle id="Elipse_115" data-name="Elipse 115" cx="23" cy="23" r="23" transform="translate(794 316.922)" fill="#22638d" opacity="0.2" />
        <path id="workspaces_FILL1_wght400_GRAD0_opsz24" d="M85.2-816.6a5.007,5.007,0,0,1-3.673-1.527A5.007,5.007,0,0,1,80-821.8a5.008,5.008,0,0,1,1.527-3.673A5.007,5.007,0,0,1,85.2-827a5.007,5.007,0,0,1,3.672,1.527A5.007,5.007,0,0,1,90.4-821.8a5.007,5.007,0,0,1-1.528,3.672A5.007,5.007,0,0,1,85.2-816.6Zm15.6,0a5.007,5.007,0,0,1-3.673-1.527A5.007,5.007,0,0,1,95.6-821.8a5.007,5.007,0,0,1,1.528-3.673A5.007,5.007,0,0,1,100.8-827a5.007,5.007,0,0,1,3.672,1.527A5.007,5.007,0,0,1,106-821.8a5.007,5.007,0,0,1-1.527,3.672A5.007,5.007,0,0,1,100.8-816.6Zm-7.8-13a5.007,5.007,0,0,1-3.673-1.527A5.007,5.007,0,0,1,87.8-834.8a5.007,5.007,0,0,1,1.527-3.672A5.007,5.007,0,0,1,93-840a5.007,5.007,0,0,1,3.673,1.528A5.007,5.007,0,0,1,98.2-834.8a5.007,5.007,0,0,1-1.527,3.672A5.007,5.007,0,0,1,93-829.6Z" transform="translate(724 1167.922)" fill="#22638d" />
      </g>
    </svg>

  )
}