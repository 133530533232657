import { Stack } from "@fluentui/react/lib/Stack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import LoaderComp from '../../components/Loader/LoaderComp';
import { DeletedItem } from "../../model/ItemModel";
import { GetAllDeletedItems } from "../../services/Item/ItemServicesGet";
import stylesList from "../../../src/pages/Style/List.module.css";
import styles from "./RecycleBinList.module.css";
import GridTitle from './GridArchive/GridTitle';
import GridArchive from './GridArchive/GridArchive';
import { CheckItemSameName, ChecksItemsSameName, DeletePermanentItem, DeletePermanentListItems, RestoreItem, RestoreListItems } from '../../services/Item/ItemServicesCRUD';
import { TextField } from "@fluentui/react";
import { EmptyTrashCanIcon } from "../../assets/icons/icons.tsx/EmptyTrashCanIcon";
import FilterCallout from "./components/FilterCallout";
import { GetOrgWorkSpaceAdmTrim } from "../../services/WorkSpaceServices";
import DeleteConfirmList from "./GridArchive/GridDelete/DeleteConfirmList";
import DeletedSuccessList from "./GridArchive/GridDelete/DeletedSuccess";
import RestoreLastConfirmation from "./GridArchive/GridRestore/RestoreLastConfirmation";
import RestoreSuccess from "./GridArchive/GridRestore/RestoreSuccess";
import RestoreList, { WorkspaceProps } from "./GridArchive/GridRestore/RestoreList";
import CheckItemsSameName from "./GridArchive/GridRestore/CheckItemsSameName";
import RestoreSuccessList from "./GridArchive/GridRestore/RestoreSuccessList";
import CompareFiles from "./GridArchive/GridRestore/CompareFiles";
import { FatherfolderProps } from "./GridArchive/GridRestore/RestoreConfirm";
import CompareFilesList from "./GridArchive/GridRestore/CompareFilesList";
import { Snackbar } from "@mui/material";
import MuiAlert, {AlertColor} from '@mui/material/Alert';

export interface ItemDeletedIdWithType{
  deleteditemId?: string;
  matchedItemId?: string;
  restoreType: number;
}

export interface ItemDeleted extends ItemDeletedIdWithType{
  deletedName?: string;
}

const RecycleBinList: React.FC<any> = () => {

  const myRefs = useRef<any[]>([]);

  const [limit, setLimit] = useState<number>(50);
  let [skip, setSkip] = useState<number>(0);
  const [deletedItens, setDeletedItens] = useState<DeletedItem[]>([]);
  let [deletedMax, setDeletedMax] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<DeletedItem[]>([])

  const [nameSearch, setNameSearch] = useState<string>("")
  const [isFilterMyFiles, setIsFilterMyFiles] = useState<boolean>(true)
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const [isDeletedConfirmed, setIsDeletedConfirmed] = useState<boolean>(false)
  const [isRestoreConfirm, setIsRestoreConfirm] = useState<boolean>(false)
  const [isRestoredConfirmed, setIsRestoredConfirmed] = useState<boolean>(false)
  const [isRestoredConfirmedList, setIsRestoredConfirmedList] = useState<boolean>(false)

  const [workspaceOptions, setWorkspaceOptions] = useState<{ key: string | number; text: string }[]>([]);
  const [workspaceIdSelectedCallout, setWorkspaceIdSelectedCallout] = useState<string>("")
  const [workspaceIdfiltered, setWorkspaceIdfiltered] = useState<string>("")

  const [temporaryWorkspaceId, setTemporaryWorkspaceId] = useState<string>("")
  const [temporaryFatherId, setTemporaryFatherId] = useState<string>()
  const [temporaryWorkspaceName, setTemporaryWorkspaceName] = useState<string>("")
  const [temporaryFatherName, setTemporaryFatherName] = useState<string>()

  const [isModalOpenRestoreList, setIsModalOpenRestoreList] = useState<boolean>(false);

  const [restoreType, setRestoreType] = useState<number>()

  const [isCompareFilesList, setIsCompareFilesList] = useState<boolean>(false)
  const [isCompareFiles, setIsCompareFiles] = useState<boolean>(false)

  const [itemDeleted, setItemDeleted] = useState<ItemDeleted>()
  const [temporaryItem, setTemporaryItem] = useState<DeletedItem>()
  const [temporaryList, setTemporaryList] = useState<DeletedItem[]>([])

  const [itemsSameNameList, setItemsSameNameList] = useState <DeletedItem[]>([])
  const [itemsSameName, setItemsSameName] = useState <DeletedItem>()

  const [firstSelectedIndex, setFirstSelectedIndex] = useState<number | null>(null);

  const [snackBarMessage, setSnackBarMessage] = useState<string|undefined>(undefined);
  const [alertColor, setAlertColor] = useState<AlertColor>("error");

  const { t, i18n } = useTranslation();

  const { nameOrg } = useParams();

  function getAllDeletedItems(filterMyFiles: boolean, workspaceId: string, searchParams: string){
    setReload(true)
    if(nameOrg != undefined)
    {
      GetAllDeletedItems(nameOrg, filterMyFiles, workspaceId, searchParams.replace("#","%23"), 0, limit)
      .then((deletedItems) => {
        if (deletedItems.length < limit) {
        setDeletedMax(false);
        }
        setSkip(deletedItems.length);
        setDeletedItens(deletedItems);
        setWorkspaceIdfiltered(workspaceId)
        setSelectedItems([])
      })
      .finally(() => {
        setReload(false)
      });
    }
  }
  
  useEffect(() => {
    getAllDeletedItems(isFilterMyFiles, workspaceIdSelectedCallout, nameSearch)
  }, [nameOrg]);

  function restore(item: ItemDeleted){
    setReload(true)

    RestoreItem(item, temporaryItem!.workspaceId, temporaryItem!.folderId)
    .then((deletedItems) => {
      getAllDeletedItems(isFilterMyFiles, workspaceIdSelectedCallout, nameSearch)
      setIsRestoredConfirmed(true)
    }).catch(err => {
      if (err.response != undefined) {
          setSnackBarMessage(err.response?.data);
      }
    }).finally(()=>{
      setReload(false)
    })
  }

  function newTemporaryItem(item: DeletedItem){
    item.workspaceName = workspaceOptions.find(work => work.key === item.workspaceId)?.text
    setTemporaryItem(item)

    const deletedItem =  {
      deleteditemId: item?.itemId, 
      restoreType: 0, 
      deletedName: item?.name
    }

    setItemDeleted(deletedItem)

    checkItemSameName(item!, {workspaceId: item!.workspaceId, workspaceName: item!.workspaceName!}, {id: item?.folderId!, name: item?.fatherFolder!})
  }

  function checkItemSameName(item: DeletedItem, workspace: WorkspaceProps, father?: FatherfolderProps){
    setTemporaryWorkspaceId(workspace.workspaceId)
    setTemporaryWorkspaceName(workspace.workspaceName)
    setTemporaryFatherId(father?.id)
    setTemporaryFatherName(father?.name)

    CheckItemSameName(item.itemId, workspace.workspaceId, father?.id)
    .then((itemsSameName) => {
      if(!itemsSameName){
        setIsRestoreConfirm(true)
      }else{
        setItemsSameName(itemsSameName)
      }
    }).catch((e)=>{
      console.log(e)
    })
  }

  function hasBeenConfirmed(){
    if(itemDeleted){
      restore(itemDeleted)
      setIsRestoreConfirm(false)
    }
  }

  function handleRestoreItem(restoreType?: number){
    if(restoreType == 1){
      const mappedItem =  {
        deletedItemId: temporaryItem?.itemId,
        matchedItemId: itemsSameName?.itemId,
        restoreType: 1
      }
      setItemDeleted(mappedItem);

      setIsCompareFiles(false)
      setIsRestoreConfirm(true)  
    }else if(restoreType == 3){
      setIsCompareFiles(true)
    }else{
      hideModal();
    }  
  }

  function restoreItemCompared(itemCompared: ItemDeleted){
    itemCompared.deletedName = temporaryItem?.name
    if(itemCompared){
      setItemDeleted(itemCompared);
      setIsCompareFiles(false)
      setIsRestoreConfirm(true)
      if(itemCompared.restoreType == 2){
       hideModal()
      }
    }else{
      hideModal();
    } 
  }

  function deletePermanentItem (itemId: string){
    setReload(true)
    DeletePermanentItem(itemId)
    .then((deletedItems) => {
      getAllDeletedItems(isFilterMyFiles, workspaceIdSelectedCallout, nameSearch)
      setSelectedItems([])
    })
    .catch((e)=>{
      console.log(e)
    })
  }

  function checksItemsSameName(listItems: DeletedItem[], workspace: WorkspaceProps, father?: FatherfolderProps){
    setTemporaryWorkspaceId(workspace.workspaceId)
    setTemporaryWorkspaceName(workspace.workspaceName)
    setTemporaryFatherId(father?.id)
    setTemporaryFatherName(father?.name)

    const listItemsId = listItems.map(item => item.itemId)

    ChecksItemsSameName(listItemsId, workspace.workspaceId, father?.id)
    .then((itemsSameName) => {
      setIsModalOpenRestoreList(false)
      if(itemsSameName.length === 0){
        setTemporaryList(listItems)
        const listItemsDeleted = listItems.map(itemSelected => ({deleteditemId: itemSelected.itemId, restoreType: 0, deletedName: itemSelected.name}))
        restoreListItems(updateRestoreTypeForDuplicates(listItemsDeleted), workspace.workspaceId, father?.id)
      }else{
        setItemsSameNameList(itemsSameName)
      }
    }).catch((e)=>{
      console.log(e)
    })
  }

  const updateRestoreTypeForDuplicates = (arr: ItemDeleted[]) => {
    const itemCounts = arr.reduce((acc, item) => {
      const key = item.deletedName || '';
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  
    const duplicatedNames = Object.keys(itemCounts).filter(name => itemCounts[name] > 1);
  
    const seen: Record<string, boolean> = {};
  
    return arr.map(item => {
      const nameKey = item.deletedName || '';
      if (duplicatedNames.includes(nameKey)) {
        if (!seen[nameKey] && item.restoreType === 1) {
          seen[nameKey] = true;
          return item;
        } else {
          return {
            ...item,
            restoreType: 3,
          };
        }
      }
      return item;
    });
  }; 

  function restoreItemsList(restoreType?: number){
    setRestoreType(restoreType)
    const noDuplicateItems = selectedItems.filter(itemSelected => !itemsSameNameList.some(item => item.name === itemSelected.name));

    if(restoreType == 1){
      const duplicateItems = selectedItems.filter(itemSelected => itemsSameNameList.some(item => item.name === itemSelected.name));

      if (duplicateItems.length > 0) {
        const mappedItems = [...duplicateItems.map(duplicateItem => {
          const matchedItem = itemsSameNameList?.find(item => item.name === duplicateItem.name);
          return {
            deletedItemId: duplicateItem.itemId,
            matchedItemId: matchedItem?.itemId,
            restoreType: 1
          };
        }), ...noDuplicateItems.map(noDuplicateItem => ({
          deletedItemId: noDuplicateItem.itemId,
          matchedItemId: undefined,
          restoreType: 0
        }))]
        setTemporaryList(selectedItems)
        restoreListItems(updateRestoreTypeForDuplicates(mappedItems), temporaryWorkspaceId, temporaryFatherId);
      } else {
        hideModal();
      }

    }else if(restoreType == 3){
      setIsCompareFilesList(true)
    }else{
      const mappedItems = noDuplicateItems.map(noDuplicateItem => ({
        deletedItemId: noDuplicateItem.itemId,
        matchedItemId: undefined,
        restoreType: 0
      }));

      if(mappedItems.length > 0){
        setTemporaryList(noDuplicateItems)
        restoreListItems(mappedItems, temporaryWorkspaceId, temporaryFatherId);
      }else{
        hideModal();
      }
    }
  }

  function restoreItemsComparedList(listItemsCompared: ItemDeleted[]){
    const selectedItemsNoCompared = selectedItems.filter(itemSelected => !listItemsCompared.some(item => itemSelected.itemId === item.deleteditemId))
    const listItemsNoCompared = selectedItemsNoCompared.map(itemSelected => ({deleteditemId: itemSelected.itemId, restoreType: 0, deletedName: itemSelected.name}))
    const listItems = [...listItemsNoCompared, ...listItemsCompared]
    
    const filterListItems = listItems.filter(item => item.restoreType !== 2)
    const itemsRestored = selectedItems.filter(itemSelected => filterListItems.some(item => itemSelected.itemId === item.deleteditemId))

    const listItemsIgnoreFiles = listItems.filter(item => item.restoreType == 2)
    if(listItemsIgnoreFiles.length == listItemsCompared.length){
      return hideModal();
    }
    if(listItemsCompared.length > 0){
      setTemporaryList(itemsRestored)
      restoreListItems(updateRestoreTypeForDuplicates(filterListItems), temporaryWorkspaceId, temporaryFatherId);
    }else{
      hideModal();
    } 
  }

  function restoreListItems(listItems: ItemDeleted[], workspaceId: string, fatherId?: string){
    setReload(true)

    RestoreListItems(listItems, workspaceId, fatherId)
    .then(() => {
      getAllDeletedItems(isFilterMyFiles, workspaceIdSelectedCallout, nameSearch)

      setIsRestoredConfirmedList(true)
      setSelectedItems([])
      setItemsSameNameList([])
    }).catch(err => {
      if (err.response != undefined) {
          setSnackBarMessage(err.response?.data);
      }
    }).finally(()=>{
      setReload(false)
    })
  }

  function deletePermanentListItem (listItem: DeletedItem[]){
    setReload(true)
    setTemporaryList(listItem)
    const listItemId = listItem.map(item => item.itemId!)
    
    DeletePermanentListItems(listItemId)
    .then((deletedItems) => {
      getAllDeletedItems(isFilterMyFiles, workspaceIdSelectedCallout, nameSearch)
      setItemsSameNameList([])
      setIsDeletedConfirmed(true)
    })
    .catch((e)=>{
      console.log(e)
    })
  }

  function hideModal(){
    setIsDeletedConfirmed(false)
    setIsRestoreConfirm(false)
    setIsRestoredConfirmedList(false)
    setTemporaryList([])
    setItemsSameNameList([])
    setItemsSameName(undefined)
    setIsCompareFiles(false)
    setIsCompareFilesList(false)
    setIsModalOpenRestoreList(false)
  }

  function hideModalSuccess(){
    setIsRestoredConfirmed(false)
    setTemporaryItem(undefined)
  }

  const handleChangeCheckBox = (isChecked: boolean, item: DeletedItem) => {
    if (isChecked) {
      if(!selectedItems.includes(item)){
        setSelectedItems(prevItems => [...prevItems, item]);
      }
    } else {
      setSelectedItems(prevItems => prevItems.filter(id => id.itemId !== item.itemId));
    }
  };

  const changeAllCheckbox = (set:boolean) => {	
    setFirstSelectedIndex(null)

    let i:number = 0;

    for(i = 0;i<myRefs.current.length;i++)
    {
      myRefs.current[i].setIsCheckedExternal(set);
    }  
  }

  function handleFilter(checkbox: boolean, workspaceId: string){
    getAllDeletedItems(checkbox, workspaceId, nameSearch)
    setIsFilterMyFiles(checkbox)
  }

  const onChangeNameSearch =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setNameSearch(newValue);
    }
    setIsTyping(true);
  };

  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
        handleInputChange();
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [nameSearch]);

  const handleInputChange = () => {
    setIsTyping(false);
    getAllDeletedItems(isFilterMyFiles, workspaceIdSelectedCallout, nameSearch)
  };

  useEffect(()=>{
    if(nameOrg){
      GetOrgWorkSpaceAdmTrim(nameOrg, skip, limit, false).then((newWork) => {
        setSkip(newWork.length);
        
        const newOptions = newWork.map(workspace => ({key: workspace.id as string, text: workspace.name as string}));

        setWorkspaceOptions(newOptions);
      });  
    }
  },[])

  const handleCheckBoxWithShift = (event: React.MouseEvent<HTMLDivElement>, index: number, id: string) => {
    if (event.shiftKey) {
      if(firstSelectedIndex !== null && firstSelectedIndex !== index){

        if(firstSelectedIndex < index){
          for(let i = firstSelectedIndex;i<index;i++)
          {
            myRefs.current[i].setIsCheckedExternal(true);
            if(i < index){
              setFirstSelectedIndex(null)
            }
          }  
        }else{
          for(let i = firstSelectedIndex;i>index;i--)
          {
            myRefs.current[i].setIsCheckedExternal(true);
            if(i > index){
              setFirstSelectedIndex(null)
            }
          }  
        }

      }else{
        setFirstSelectedIndex(index);
      }
    } else {
      setFirstSelectedIndex(null)
    }
  };

  function handleClose (event:any,) {
    setSnackBarMessage(undefined);
  }

  return(
    <div style={{height:"100%"}}>
      <ul style={{height:"100%", display: "flex", flexDirection: "column"}}>
        <Stack  className={stylesList.hPage}>  
          <Stack.Item>
            <h2>{t("RecicleBin.title")}</h2> 
          </Stack.Item>
        
          <div style={{borderBottom:"1px solid rgb(0, 0, 0, 0.08)", marginTop:"34.5px",}}></div>
          
        </Stack>

        <div style={{display:"flex", justifyContent: "space-between"}}>
          <div style={{display: "flex", marginBottom: "20px"}}>
            <DeleteConfirmList
              deleteItems={deletePermanentListItem}
              listItem={selectedItems}
            />
            
            <RestoreList
              listItem={selectedItems}
              RestoreItems={checksItemsSameName}
              workspaceOptions={workspaceOptions}
              hideModal={hideModal}
              isModalOpen={isModalOpenRestoreList}
              showModal={setIsModalOpenRestoreList}
            />

            {itemsSameNameList.length > 0 &&
              <CheckItemsSameName
                itemListQuantity={itemsSameNameList.length}
                restoreItem={restoreItemsList}
                showModal={itemsSameNameList.length > 0}
                hideModal={hideModal}
              />
            }

            {itemsSameName &&
              <CompareFiles 
                isOpenModal={isCompareFiles} 
                hideModal={hideModal} 
                restoreItem={restoreItemCompared} 
                item={itemsSameName} 
                itemDeleted={temporaryItem!}
                destiny={temporaryFatherName? temporaryFatherName : temporaryWorkspaceName}
              />
            }

            {itemsSameNameList.length > 0 &&
              <CompareFilesList 
                isOpenModal={isCompareFilesList} 
                hideModal={hideModal} 
                restoreListItems={restoreItemsComparedList} 
                listItems={itemsSameNameList} 
                listItemsDeleted={selectedItems}
                destiny={temporaryFatherName? temporaryFatherName : temporaryWorkspaceName}
              />
            }

            <RestoreSuccessList
              listItem={temporaryList}
              isConfirmed={isRestoredConfirmedList}
              hideModal={hideModal}
              restoreType={restoreType}
            />
          
            {isDeletedConfirmed &&
              <DeletedSuccessList
                listItem={temporaryList}
                isConfirmed={isDeletedConfirmed}
                hideModal={hideModal}
              />
            }
            {temporaryItem &&
              <>
                <CheckItemsSameName
                  restoreItem={handleRestoreItem}
                  showModal={!!itemsSameName}
                  hideModal={hideModal}
                />
                <RestoreLastConfirmation 
                  item={temporaryItem}
                  isConfirmed={isRestoreConfirm}
                  hideModal={hideModal}
                  hasBeenConfirmed={hasBeenConfirmed}
                />
                <RestoreSuccess
                  nameOrg={nameOrg ? nameOrg : ""}
                  item={temporaryItem}
                  isConfirmed={isRestoredConfirmed}
                  hideModal={hideModalSuccess}    
                />
              </>
            }
          </div>

          <div style={{display:"flex"}}>
            <TextField
              iconProps={{ iconName: "Search" }}
              placeholder={t("RecicleBin.search")}
              value={nameSearch}
              onChange={onChangeNameSearch}
              styles={{
                fieldGroup:{
                  marginLeft:"20px",
                  borderRadius:"6px",
                  borderColor:"#2D2D2D33", 
                  justifyContent:"center", 
                  width:"300px", 
                  height:"44px", 
                  marginBottom:"4px",
                  '&::after': {
                    borderRadius:"6px",
                  },
                },
                field: {
                  marginLeft:"25px",
                  fontSize:"18px",
                  selectors: {
                      '::placeholder': {
                          color: 'rgb(45, 45, 45, 0.3)',
                      },
                  },
                },
                icon: {
                    top:"14px",
                    left: '8px',
                    width: "14px",
                    height: "14px",
                    color:"#989898"
                },       
              }}
            />
            <FilterCallout 
              workspaceIdFilter={workspaceIdfiltered} 
              filterMyFiles={handleFilter} 
              isAdmOrg={deletedItens[0] ? deletedItens[0].isAdmOrg : undefined}
              workspaceOptions={workspaceOptions}
              setWorkspaceIdSelectedCallout={setWorkspaceIdSelectedCallout}
              workspaceIdSelectedCallout={workspaceIdSelectedCallout}
            />
          </div>
        </div>
        
        {reload ? 
          (<div style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <LoaderComp big={true} />
          </div>
          ):(
          deletedItens.length != 0 &&
          <div style={{display:"flex", flexDirection:"column",height:"100%"}}>
            <GridTitle changeAllCheckbox={changeAllCheckbox} isCheckedCheckbox={deletedItens.length == selectedItems.length}/>
            {deletedItens.map((item, index) => (
              <div
                key={item.itemId}
                onClick={(event) => handleCheckBoxWithShift(event, index, item.itemId!)}
              >
                <GridArchive 
                  deletedItem={item}
                  restore={newTemporaryItem}
                  ref={el => myRefs.current[index] = el}
                  lastLine={index == deletedItens.length - 1}
                  checkBox={handleChangeCheckBox}
                  deleteItem={deletePermanentItem}
                  workspaceOptions={workspaceOptions}
                />
              </div>
            ))}  
          </div>
          )
        }

        {reload == false && deletedItens.length == 0 && 
          <div className={styles.noRegistry} style={{backgroundColor: deletedItens.length == 0 ? "#F5F5F5" : ""}}>
            <div style={{
              display:"flex", 
              justifyContent:"center", 
              fontSize:"22px", 
              fontWeight: 600,
              margin:"10px 0px 10px 0px",
              }}>
                <div style={{textAlign:"center", color:"#2D2D2D"}}>
                  <EmptyTrashCanIcon/>
                  <div style={{font:"normal normal bold 20px/42px Segoe UI"}}>
                    {t("RecicleBin.emptyRecycleBinPartA")}
                  </div>
                  <div style={{font:"normal normal normal 16px/24px Segoe UI"}}>
                    {t("RecicleBin.emptyRecycleBinPartB")}
                  </div>
                  <div style={{font:"normal normal 600 16px/24px Segoe UI",}}>
                    {t("RecicleBin.emptyRecycleBinPartC")}
                  </div>
                </div>
            </div>
          </div>   
        }  
        
        <Snackbar
            open={snackBarMessage != undefined}
            autoHideDuration={6000}
            message="Archived"
            onClose={handleClose}
            anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
            sx={{ bottom: { xs: 50, sm: 20 } }}
        >
            <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClose}>
                {snackBarMessage}
            </MuiAlert>
        </Snackbar>           
      </ul>
    </div>
  );
}

export default RecycleBinList;