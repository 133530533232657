import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserIcon } from "../../assets/icons/UserIcon";
import { MailIcon } from "../../assets/icons/MailIcon";

interface IPaywallModal {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isStorage?: boolean;
    isStorageAdmin?: boolean;
    adminUserName?: string;
    adminUserEmail?: string;
    subtitleStart: string;
    subtitleBold: string;
    subtitleEnd:string;
    icon: JSX.Element;
}

export const PaywallModal: React.FC<IPaywallModal> = ({
    open,
    setOpen,
    adminUserName,
    adminUserEmail,
    icon,
    subtitleStart, 
    subtitleBold,
    subtitleEnd,
    isStorageAdmin,
    isStorage
}) => {
    const { t } = useTranslation();

    const paywallconfig = require("../../services/paywallconfig.json");

    return (
        <Dialog open={open}>
            <Box width="480px" sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <IconButton sx={{ textAlign: "right" }} onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </Box>

                <Box sx={{ margin: "auto", marginBottom: "20px" }}>
                    {icon}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", gap: "8px" }}>
                    <Typography
                        sx={{
                            font: "normal normal 600 20px/ 30px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D"
                        }}
                    >
                        {t('Paywall.title')}
                    </Typography>

                    <Typography
                        sx={{
                            font: "normal normal normal 14px/21px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: "0.6"
                        }}
                    >
                        {subtitleStart}
                        <Typography component={'b'} sx={{
                            font: "normal normal 600  14px/21px Segoe UI",
                        }} >
                            {subtitleBold}    
                        </Typography>
                        {subtitleEnd}
                    </Typography>
                </Box>

                {isStorage && !isStorageAdmin ? (
                    <>
                        <Box
                            sx={{
                                border: "1px solid #DEDEDE",
                                borderRadius: "4px",
                                marginTop: "20px"
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderBottom: "1px solid #DEDEDE"
                                }}
                            >
                                <Box sx={{ borderRight: "1px solid #DEDEDE", padding: "10px", marginRight: "8px" }}>
                                    <UserIcon />
                                </Box>
                                <Typography>{adminUserName}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Box sx={{ borderRight: "1px solid #DEDEDE", padding: "10px", marginRight: "8px" }}>
                                    <MailIcon />
                                </Box>
                                <Typography>{adminUserEmail}</Typography>
                            </Box>
                        </Box>

                        <Box
                            component="button"
                            onClick={() => setOpen(false)}
                            sx={{
                                background: "#22638D",
                                color: "white",
                                padding: "10px 20px",
                                borderRadius: "4px",
                                textTransform: "none",
                                cursor: "pointer",
                                textAlign: "center",
                                border: "none",
                                width: "100%",
                                marginTop: "30px",

                                "&:hover": {
                                    background: "#2f88c3"
                                }
                            }}
                        >
                            {t("Paywall.gotIt")}
                        </Box>
                    </>
                ) : (
                    <a
                        href={paywallconfig.url}
                        target="_blank"
                        style={{ textDecoration: "none", marginTop: "30px" }}
                        rel="noreferrer"
                    >
                        <Box
                            component="button"
                            sx={{
                                background: "#22638D",
                                color: "white",
                                padding: "10px 20px",
                                borderRadius: "4px",
                                textTransform: "none",
                                cursor: "pointer",
                                textAlign: "center",
                                border: "none",
                                width: "100%",

                                "&:hover": {
                                    background: "#2f88c3"
                                }
                            }}
                        >
                            {t("Paywall.upgrade")}
                        </Box>
                    </a>
                )}
            </Box>
        </Dialog>
    );
};