import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Box, Button, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { GetUniqueLinkSearchParams, LoginRequestMFA } from '../../../context/AuthProvider/util';
import copy from "../../../assets//icons/copy.svg"
import { CheckIcon } from '../../../assets/icons/icons.tsx/CheckIcon';
import LoaderComp from '../../../components/Loader/LoaderComp';
import BoxMfa from '../MfaOTP/components/BoxMfa';
import OTPInput from '../MfaOTP/components/OTPInput';
import { RedirectNavigate } from '../../PublicLayout/RedirectNavigate';
import { useAuth } from '../../../context/AuthProvider/useAuth';
import { useProfile } from '../../../context/ProfileProvider/useProfile';
import { IUser } from '../../../context/AuthProvider/types';

const MfaQRCode = () => {
    const auth = useAuth();
    const profile = useProfile();
    const { email, key } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const uniqueLink = GetUniqueLinkSearchParams();

    const [otpCode, setOtpCode] = useState<string>("")
    const [isDisabled, setIsDisabled] = useState<boolean>();
    const [error, setError] = useState<boolean>(false)
    const [load, setLoad] = useState<boolean>(false);

    useEffect(()=>{
        setIsDisabled(otpCode.length < 6);
        setError(false)
    },[otpCode])
  
    function setMfaUrl() {
        let issuer = "w3k";
        return `otpauth://totp/${issuer}:${email}?secret=${key}&issuer=${issuer}`;
    }

    const handleCopyText = () => {
        if(key){
            navigator.clipboard.writeText(key)
        }
    };

    function setProfile(token:string)
    {
      profile
        .create(token)
        .then(() => {
          setLoad(false);
          let user: IUser = {
            token:token
          };
          auth.setUserExternal(user);
          if (uniqueLink != undefined){
            RedirectNavigate(navigate, uniqueLink)
          }
          else {
            RedirectNavigate(navigate)
          }
        })
        .catch((error) => {
          auth.logout();
          handleNavigateLogin()
          setLoad(false);
        });
    }
  
    const handleNavigateLogin = () => {
        navigate(`/login${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`);
    }
  
    function verifyOtp(otpCode: string) {
        if (email != undefined) {
            setLoad(true)
            LoginRequestMFA(otpCode, email)
            .then(data => {
                setProfile(data.data.access_token)
            })
            .catch(() => { 
                setError(true)
                setLoad(false)
            })
        }
    } 

    return (
        <BoxMfa 
            header={t("MfaQRCode.header")}
            width="780px"
        >
            <Box
                sx={{
                    display:"flex",
                    border:"1px solid rgb(34, 99, 141, 0.2)",
                    borderRadius:"4px",
                    boxShadow: 'none',
                    width: "780px"
                }}
            >
                <Box
                    sx={{
                        width: "50%",
                        margin:"30px 0px 30px 30px",
                        paddingRight:"30px",
                        borderRight:"1px solid rgb(34, 99, 141, 0.2)"
                    }}
                >
                    <Box 
                        sx={{
                            width:"100%",
                            display:"flex",
                            justifyContent:"center"
                        }}
                    >
                        <Box
                            sx={{
                                border:"1px solid rgb(34, 99, 141, 0.2)",
                                padding:"5px 5px 2px 5px",
                                borderRadius:"4px"
                            }}
                        >
                            <QRCodeSVG value={setMfaUrl()} size={152} fgColor='rgb(34, 99, 141)'/>
                        </Box>
                    </Box>

                    <Box 
                        sx={{
                            height:"36px",
                            margin: "20px 0px 20px 0px",
                            borderRadius:"4px",
                            border:"1px solid rgb(34, 99, 141, 0.5)",
                            padding:"7px",
                            display: "flex",
                            justifyContent:"space-between",
                            font:"normal normal normal 14px Roboto",
                            color:"#2D2D2D",
                            alignItems:"center"
                        }}
                    >
                        {key}
                        <Tooltip
                            arrow
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: "#24618E",
                                        color: "#FFFFFF",
                                        font: "normal normal normal 12px/18px Roboto",
                                        '& .MuiTooltip-arrow': {
                                            color: "#24618E",
                                        }
                                    }
                                },
                            }}
                            title={t("MfaQRCode.tooltip")}
                        >
                            <Box 
                                onClick={handleCopyText}
                                sx={{
                                    height:"26px", 
                                    width:"26px", 
                                    borderRadius:"50%",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",   
                                    cursor:"pointer",   
                                    marginLeft:"7px",
                                    '&:hover': {
                                        backgroundColor: 'rgb(36, 97, 142, 0.1)',
                                    }
                                }}
                            >
                                <img src={copy} alt='copyIcon'/>
                            </Box>
                        </Tooltip>                                                  
                    </Box>
                    <Typography sx={{color:"rgb(45, 45, 45, 0.5)", textAlign:"center"}}>
                        <span style={{font:"normal normal 600 12px/18px Segoe UI"}}>
                            {t("MfaQRCode.tipPartA")}
                        </span>
                        <span style={{font:"normal normal normal 12px/18px Segoe UI",}}>
                            {t("MfaQRCode.tipPartB")}
                        </span>    
                    </Typography>
                </Box>
                <Box 
                    sx={{
                        width: "50%",
                        margin:"30px",
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            marginBottom:"40px"
                        }}
                    >
                        <Box
                            sx={{
                                height:"30px", 
                                minWidth:"30px", 
                                borderRadius:"50%", 
                                backgroundColor:"#22638D", 
                                color:"#FFFFFF",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                font:"normal normal 600 18px/18px Segoe UI"
                            }}
                        >
                        1  
                        </Box>
                        <Box 
                            sx={{
                                paddingLeft: "20px", 
                            }}
                        >
                            <span
                                style={{
                                    textAlign: "left",
                                    font:"normal normal 600 14px/21px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#2D2D2D",
                                    opacity: 1
                                }}
                            >
                                {t("MfaQRCode.tipOnePartA")}
                            </span>
                            <span
                                style={{
                                    textAlign: "left",
                                    font:"normal normal normal 14px/21px Segoe UI",
                                    letterSpacing: "0px",
                                    color: "#2D2D2D",
                                    opacity: 1,               
                                }}
                            >
                                {t("MfaQRCode.tipOnePartB")}
                            </span>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            marginBottom:"44px"
                        }}
                    >
                        <Box
                            sx={{
                                height:"30px", 
                                minWidth:"30px", 
                                borderRadius:"50%", 
                                backgroundColor:"#22638D", 
                                color:"#FFFFFF",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center",
                                font:"normal normal 600 18px/18px Segoe UI"
                            }}
                        >
                        2  
                        </Box>
                        
                        <Typography
                            sx={{
                                textAlign: "left",
                                font:"normal normal normal 14px/21px Segoe UI",
                                letterSpacing: "0px",
                                color: "#2D2D2D",
                                opacity: 1,
                                paddingLeft: "20px"
                            }}
                        >
                            {t("MfaQRCode.tipTwo")}
                        </Typography>
                    </Box>

                    <OTPInput onComplete={setOtpCode} error={error} pageQrCode/>

                    { load ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems:"center",
                            marginTop:"20px"
                        }}>
                            <LoaderComp />
                        </Box>
                    ) : (
                        <Button 
                            onClick={()=>verifyOtp(otpCode)}
                            disabled={isDisabled}
                            sx={{
                                '&.Mui-disabled': {
                                    backgroundColor: "rgb(34, 99, 141, 0.2)",
                                    color: "#FFFFFF",
                                },                               
                                '&:hover': {
                                    backgroundColor: '#1E4A6F',
                                    color: '#FFFFFF',
                                },
                                width:"100%",
                                height:"36px",
                                backgroundColor:"#22638D", 
                                color:"#FFFFFF",
                                textTransform:"none"
                            }}
                        >
                            <CheckIcon/>  
                            <Typography 
                                sx={{
                                    font:"normal normal normal 16px/21px Segoe UI",
                                    marginLeft:"10px"
                                }}
                            >
                                {t("Continuar.message")} 
                            </Typography>
                        </Button>
                    )}
                </Box>
            </Box>
        </BoxMfa>
    );
}

export default MfaQRCode;
