import React, { useEffect, useState, useRef, useCallback  } from "react";
import { ObjectPermissionUser } from "../../../model/PermissionModel";
import { 
  AddPermissionUser, RemovePermissionUser
} from "../../../services/WorkSpaceServices";
import { ContextualMenu, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import stylesList from "../../Style/List.module.css";
import { IIconProps, initializeIcons } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import IconButtonMaterial from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@fluentui/react/lib/Button';
import { useTranslation } from "react-i18next";
import I18n, { TranslatorS } from '../../../components/I18n';
import {
  Dialog,
  DialogType,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Btn from "../../../components/Button/Btn";
import { useBoolean } from "@fluentui/react-hooks";
import LoaderComp from '../../../components/Loader/LoaderComp';

interface GroupPermissionItemConfig {
    idUser: string;
    nameUser:string;
    typeUser:number;
    isAdminOrganization:boolean;
    isReaderOrganization:boolean;
    idWorkspace: string;
    ref?: React.Ref<HTMLLIElement>;
  }

    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    
    const read: IIconProps = { iconName: 'ReadingMode' , style: { color: 'green' }};
    const write: IIconProps = { iconName: 'Personalize' , style: { color: 'green' }};
    const none: IIconProps = { iconName: 'ErrorBadge' , style: { color: 'red' }};
    const organize: IIconProps = { iconName: 'ClipboardList' , style: { color: 'green' }};
    const viwer: IIconProps = { iconName: 'View' , style: { color: 'green' }};

const UserPermissionItem: React.ForwardRefExoticComponent<GroupPermissionItemConfig> =
    React.forwardRef(({ idUser, nameUser, isAdminOrganization, isReaderOrganization, idWorkspace, typeUser }, ref: React.Ref<HTMLLIElement>) => {
        const tooltipId = useId('tooltip');
        const linkRef = React.useRef(null);
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const [showContextualMenu, setShowContextualMenu] = React.useState(false);
        const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
          ev.preventDefault();
          if(loader == false)
          {
            setShowContextualMenu(true);
          }
        }, []);
        const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
        const { t, i18n } = useTranslation();

        const [isReader, setIsReader] = React.useState<boolean>(false);
        const [isEditer, setIsEditer] = React.useState<boolean>(false);
        const [isNone, setIsNone] = React.useState<boolean>(false);
        const [isOrganize, setIsOrganize] = React.useState<boolean>(false);
        const [loader, setLoader] = useState<boolean>(false);
        const [isViwer, setIsViwer] = React.useState<boolean>(false);
        const [typeInternal, setTypeInternal] = useState<number>(typeUser);

        const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

        useEffect(() => {
          setIsReader(false);
          setIsEditer(false);
          setIsNone(false);
          setIsViwer(false);
          setIsOrganize(false);
          if(typeInternal == 0)
          {
            setIsReader(true);
          }
          else if(typeInternal == 1)
          {
            setIsEditer(true);
          }
          else if(typeInternal == 2)
          {
            setIsViwer(true);
          }
          else if(typeInternal == 3){
            setIsOrganize(true);
          }
          else if(typeInternal == 4)
          {
            setIsNone(true);
          }
        }, [typeInternal]);


        const setGroupReader = () => {
          setLoader(true);
          let readerPermission :ObjectPermissionUser ={
            idUser: idUser,
            idObject: idWorkspace,
            permissionType:0
          }
          AddPermissionUser(readerPermission)
          .then(()=> {
            setTypeInternal(0);
          })
          .finally(() => {
            setLoader(false);
          });
        };

        const setGroupWriter = () => {
          setLoader(true);
          let writerPermission :ObjectPermissionUser ={
            idUser: idUser,
            idObject: idWorkspace,
            permissionType:1
          }
          AddPermissionUser(writerPermission)
          .then(()=> {
            setTypeInternal(1);
          })
          .finally(() => {
            setLoader(false);
          });
        };
        
        const setGroupViwer = () => {
          setLoader(true);
          let viwerPermission :ObjectPermissionUser ={
            idUser: idUser,
            idObject: idWorkspace,
            permissionType:2
          }
          AddPermissionUser(viwerPermission)
          .then(()=> {
            setTypeInternal(2);
          })
          .finally(() => {
            setLoader(false);
          });
        };

        const setUserOrganize = () => {
          setLoader(true);
          let OrganizePermission :ObjectPermissionUser ={
            idUser: idUser,
            idObject: idWorkspace,
            permissionType:3
          }
          AddPermissionUser(OrganizePermission)
          .then(()=> {
            setTypeInternal(3);
          })
          .finally(() => {
            setLoader(false);
          });
        };


        const setGroupNone = () => {
          setLoader(true);
          if(typeInternal == 4)
          {
            setLoader(false);
            return;
          }

          let writerPermission :ObjectPermissionUser ={
            idUser: idUser,
            idObject: idWorkspace,
            permissionType:4
          }
          if(typeInternal)
          {
            writerPermission.permissionType = 1;
          }
          RemovePermissionUser(writerPermission)
          .then(()=> {
            setTypeInternal(4);
          })
          .finally(() => {
            setLoader(false);
          });
          toggleHideDialog();
        };

        const menuItems: IContextualMenuItem[] = [
          {
            key: 'Organizador',
            text: t("Organizador.message"),
            onClick: setUserOrganize,
            disabled:isOrganize || isReaderOrganization,
            iconProps: isOrganize ? { iconName: 'CheckMark', style: { color: 'green' } } :undefined
          },
          {
            key: 'Editor',
            text: t("Workspace.writer"),
            onClick: setGroupWriter,
            disabled:isEditer  || isReaderOrganization,
            iconProps: isEditer ? { iconName: 'CheckMark', style: { color: 'green' } } :undefined
          },  
          {
            key: 'Leitor',
            text: t("Workspace.reader"),
            onClick: setGroupReader,
            disabled:isReader,
            iconProps: isReader ? { iconName: 'CheckMark', style: { color: 'green' } } :undefined
          },        
          {
            key: 'Visualizador',
            text: t("Visualizador.message"),
            onClick: setGroupViwer,
            disabled:isViwer,
            iconProps: isViwer ? { iconName: 'CheckMark', style: { color: 'green' } } :undefined
          },
          {
            key: 'Remover',
            text: t("Workspace.none"),
            onClick:toggleHideDialog,
            disabled:isNone,
            iconProps: isNone ? { iconName: 'CheckMark', style: { color: 'green' } } :undefined
          }
        ];

        function content18n(): string 
        {
          if (i18n.language == "en-US")
          {
            return isReader?"Reader":isEditer?"Writer":isViwer?"Viewer":isOrganize?"Organizer":"None"
          }

          return isReader?"Leitor":isEditer?"Editor":isViwer?"Visualizador":isOrganize?"Organizador":"Desativado"
        }

        return (
            <li className={`${stylesList.itemContent} ${stylesList.row}`} key={idUser} ref={ref}>
                <div className={stylesList.nameField}>
                  {nameUser}

                  {isAdminOrganization &&(
                    <TooltipHost
                      content={t("AdmOrg.message")}
                      id={tooltipId}
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                      <IconButton 
                        iconProps={{ iconName: 'Info' , style: { color: 'blue' }}}
                        aria-label={"Admin"}
                      />
                    </TooltipHost>
                  )}

                  {isReaderOrganization &&(
                    <TooltipHost
                    content={t("OnlyRead.message")}
                      id={tooltipId}
                      calloutProps={calloutProps}
                      styles={hostStyles}
                    >
                    <IconButton 
                      iconProps={{ iconName: 'Info' , style: { color: 'blue' }}}
                      aria-label={"OnlyRead"}
                    />
                    </TooltipHost>
                  )}

                  <TooltipHost
                    content={content18n()}
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                    setAriaDescribedBy={false}
                  >
                    <IconButton 
                      iconProps={isReader?read:isEditer?write:isViwer?viwer:isOrganize?organize:none}
                      aria-label={isReader?"Reader":isEditer?"Writer":isViwer?"Viewer":isOrganize?"Organizer":"none"}
                    />
                  </TooltipHost>
                </div>
                {loader ? (
                  <LoaderComp/>
                ) : (
                  <IconButtonMaterial
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={onShowContextualMenu}
                    ref={linkRef}
                    >
                    <MoreVertIcon color={loader?"disabled":"action"} />
                  </IconButtonMaterial>
                )}
                <ContextualMenu
                    items={menuItems}
                    hidden={!showContextualMenu}
                    target={linkRef.current}
                    styles={{
                      root:{
                        paddingTop:"8px",
                        paddingBottom:"8px",
                        boxShadow:"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
                      }
                    }}
                    onItemClick={onHideContextualMenu}
                    onDismiss={onHideContextualMenu}
                />
                <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
                  <DialogContent title={t("Excluir.message")}>
                    <h3>{t("CertezaExcluirPermission.message")}</h3>
                  </DialogContent>
                  <DialogFooter>
                    <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
                    <Btn onClick={setGroupNone} text={t("Excluir.message")}/>
                  </DialogFooter>
                </Dialog>
            </li>
            
        );
    });

    export default UserPermissionItem;