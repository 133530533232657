import React, { useImperativeHandle, useState } from "react";
import { DeletedItem } from "../../../model/ItemModel";
import moment from "moment";
import styles from '../RecycleBinList.module.css'
import WorkSpaceItemListFileComponentImage from "../../WorkSpaceItem/WorkSpaceItemListFileComponentImage";
import { Checkbox, IDropdownOption } from "@fluentui/react";
import DeleteConfirm from "./GridDelete/DeleteConfirm";
import RestoreConfirm from "./GridRestore/RestoreConfirm";


  interface DeleteListCompProps {
    deletedItem: DeletedItem,
    restore: (item: DeletedItem) => void,
    ref?: React.Ref<DeleteListItemRef>,
    lastLine: boolean,
    checkBox: (value: boolean, item: DeletedItem) => void,
    deleteItem: (itemId: string) => void,
    workspaceOptions: IDropdownOption[]
  }

  export interface DeleteListItemRef {
    setIsCheckedExternal(set: boolean): void;
  }

  const GridArchive: React.ForwardRefExoticComponent<DeleteListCompProps> =
  React.forwardRef(({ deletedItem, restore, lastLine, checkBox, deleteItem, workspaceOptions }, ref) => {

    var dateDeleted = moment(deletedItem.dateDeletion).format('DD/MM/YYYY');
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useImperativeHandle(
      ref,
      () => ({
        setIsCheckedExternal(set: boolean) {
          setIsChecked(set);
          handleChangeInternal(undefined, set)
        }
      }),
    )

    const handleChangeInternal = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      if (checked !== undefined) {
        checkBox(checked, deletedItem!);
        setIsChecked(checked)
      }
    }

    return (
        <li style={{ borderBottomLeftRadius: lastLine ? "8px" : "", borderBottomRightRadius: lastLine ? "8px" : "", marginBottom: lastLine ? "25px": ""}} className={styles.gridRecycleBinBodyLi} key={deletedItem.workspaceId} ref={ref as React.Ref<HTMLLIElement>}>
          <div className={styles.gridRecycleBinIconsAndCheckbox}>
            <Checkbox styles={{checkbox:{height:"20px", width:"20px", margin:"0", background: isChecked ? "#22638D" : "", border: isChecked ? "1px solid #22638D" : "1px solid rgb(45, 45, 45, 0.5)"}}} checked={isChecked}  onChange={handleChangeInternal} />

            <DeleteConfirm item={deletedItem} deleteItem={deleteItem}/>

            <div style={{marginRight:"5px"}}>
              <RestoreConfirm item={deletedItem} RestoreItem={restore} workspaceOptions={workspaceOptions}/>
            </div>
          </div>
          <div className={styles.gridRecycleBinBodyDiv}>       
            <WorkSpaceItemListFileComponentImage extension={deletedItem.extencion} isFolder={deletedItem.itemType === 1 ? true : false} />
            <span className={styles.gridRecycleBinSpan} style={{marginLeft:"10px"}}>
              {deletedItem.name + (deletedItem.extencion ? "." + deletedItem.extencion : "")}
            </span>
          </div>

          <div className={styles.gridRecycleBinBodyDiv}>       
            <span className={styles.gridRecycleBinSpan} style={{marginRight:"7px"}}>{deletedItem.ownerName}</span>
            <span className={styles.gridRecycleBinSpan} style={{color: "rgb(45, 45, 45, 0.5)"}}>{"(" + deletedItem.ownerLogin + ")"}</span>
          </div>

          <div className={styles.gridRecycleBinBodyDiv}>       
            <span className={styles.gridRecycleBinSpan}>
              {deletedItem.workspaceName}
            </span>
          </div>

          <div className={styles.gridRecycleBinBodyDiv}>
            <span className={styles.gridRecycleBinSpan} style={{marginRight:"7px"}}>{deletedItem.whoExcludedId}</span>
            <span className={styles.gridRecycleBinSpan} style={{color: "rgb(45, 45, 45, 0.5)"}}>{"(" + deletedItem.whoExcludedLogin + ")"}</span>
          </div>

          <div style={{ borderRight:"0px"}} className={styles.gridRecycleBinBodyDiv}>
            <span className={styles.gridRecycleBinSpan}>
              {dateDeleted}
            </span>
          </div>
        </li>
    );   
  });
  
  export default GridArchive;