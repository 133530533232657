import { useState, useEffect, useRef, useCallback } from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import React from "react";
import { useBoolean } from '@fluentui/react-hooks';
import PreviewWorkspaceItem from "./PreviewWorkspaceItem";
import { WorkSpaceUserName } from "../../../../model/ItemModel";
import WorkSpaceItemListFileComponentImage from "../../WorkSpaceItemListFileComponentImage";
import LoaderComp from "../../../../components/Loader/LoaderComp";
import { previewItemStyles } from "./PreviewStyles";
import {ChoicerViwerItem} from "../ChoicerViwerModal"

function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }

      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });

      if (node) intersectionObserver.current.observe(node);
    }, deps);
}

export interface PreviewWorkspaceListProps {
    workMax: boolean;
    workspaceItemList: ChoicerViwerItem[];
    changeModalContent: (id: string, name: string, extension: string) => void;
    getWorkspaceWhenVisible: () => void;
    ref?: React.Ref<HTMLDivElement>;
}

export interface PreviewWorkspaceListPropsItem extends PreviewWorkspaceListProps{
    idItem: string

}

const PreviewWorkspaceList: React.FC<PreviewWorkspaceListPropsItem> = ({
    idItem,
    workMax,
    workspaceItemList,
    changeModalContent,
    getWorkspaceWhenVisible,
}) => {
    const [hasScrollBar, setHasScrollBar] = useState(false);
    const [arrowLeft, setArrowLeft] = useState(false);
    const [arrowRight, setArrowRight] = useState(false);
    const [waitWorkItemModal, setWaitWorkItemModal] = useState<boolean>(false);
    const [update, { toggle: toggleHideDialog }] = useBoolean(false);

    const refListContainer = useRef<HTMLDivElement>(null);
    const currentItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        toggleHideDialog()
    }, [])

    useEffect(() => {
        if(currentItemRef.current){
            currentItemRef.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' })
        }
    }, [update])

    function handleScroll() {
        if (refListContainer.current){
            if (refListContainer.current.scrollLeft > 0) {
                setArrowLeft(true)
            } 
            else {
                setArrowLeft(false)
            }
            if (refListContainer.current.scrollLeft + refListContainer.current.clientWidth > refListContainer.current.scrollWidth - 1.5) {
                setArrowRight(true)       
            } else {
                setArrowRight(false)
            }
        }
    }

    useEffect(() => {
        setWaitWorkItemModal(false)
        function updateState() {
            const el = refListContainer.current;
            el && setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width);
        }

        updateState();

        window.addEventListener('resize', updateState);
        return () => window.removeEventListener('resize', updateState);
    }, [workspaceItemList]);

    const scroll = (scrollOffset: number) => {
        if (refListContainer.current) {
            const currentScrollPosition = refListContainer.current.scrollLeft;
            const newScrollPosition = currentScrollPosition + scrollOffset;

            refListContainer.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if(waitWorkItemModal === true){
            getWorkspaceWhenVisible() 
        }
    }, [waitWorkItemModal])

    const lastBook = useVisibility(
        (visible) => {
            if (visible) {
            setWaitWorkItemModal(true)
            }
        },[idItem, workspaceItemList]
    );
    
    return(
        <div className={previewItemStyles.showListContainer} ref={refListContainer} onScroll={handleScroll}>
            <div style={{ left: "0px" }} className={previewItemStyles.arrow}>
                <div style={{ 
                    fontSize: 24,
                    color: arrowLeft ? "rgb(17, 77, 136)" : "#808080" 
                }}>
                    {hasScrollBar && 
                        <KeyboardDoubleArrowLeftIcon 
                            fontSize="large" 
                            className={arrowLeft ? previewItemStyles.svgArrow : ""}
                            onClick={() => scroll(-240)}
                        />
                    }
                </div>
            </div>
            {workspaceItemList?.filter((item)=> item?.itemType != "folder" ).map((item) => {
                return (
                    <PreviewWorkspaceItem
                        key={item.id}
                        item={item}
                        isCurrentItem={item.id == idItem}
                        icon={<WorkSpaceItemListFileComponentImage extension={item.extension!} isFolder={item?.itemType! === "folder" ? true : false} />}
                        changeModalContent={changeModalContent}
                        currentItemRef={item.id == idItem ? currentItemRef : null}
                        ref={workspaceItemList[workspaceItemList.length - 1].id === item.id && workMax ? lastBook : null}
                    />
                )
            })}
            <div style={{ right: "-1px" }} className={previewItemStyles.arrow}>
                {hasScrollBar && (
                    waitWorkItemModal ? 
                    <div style={{ 
                        fontSize: "0px",
                    }}>
                        <LoaderComp lessWidth/>
                    </div> :
                    <div style={{ 
                        fontSize: 24,
                        color: arrowRight ? "#808080" : "rgb(17, 77, 136)"
                    }}>
                        <KeyboardDoubleArrowRightIcon
                            fontSize="large"
                            className={arrowRight ? "" : previewItemStyles.svgArrow}
                            onClick={() => scroll(240)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}


export default PreviewWorkspaceList;