import React, { useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import { useBoolean } from '@fluentui/react-hooks';
import { DeletePermanentRedIcon } from '../../../../assets/icons/icons.tsx/DeletePermanentRedIcon';
import recycleBinDeleteHeader from "../../../../assets/icons/recycleBinDeleteHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import CustomTooltip from '../../components/CustomTooltip';

interface DeleteConfirmProps {
  item: DeletedItem,
  deleteItem: (itemId: string) => void,
}

const DeleteConfirm: React.FC<DeleteConfirmProps> = ({item, deleteItem}) => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);

  
  function confirmDelete(){
    if(item){
      hideModal()
      deleteItem(item.itemId!)
    }
  }
  return (
    <div>
      <CustomTooltip
        text={t("RecicleBin.deleteConfirmTooltipText")}
        backgroundColor="#B20C00"
        marginLeft="134px !important"
      >
        <button 
          style={{cursor:"pointer", border:"none", backgroundColor:"transparent", display: "flex", alignItems:"center"}} 
          onClick={showModal}
        >
          <DeletePermanentRedIcon/>
        </button>
      </CustomTooltip>

      <CustomModal 
        isModalOpen={isModalOpen}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinDeleteHeader} alt='Lixeira'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.deleteModalTitle")}
          </div>
        </div>
        <div style={{padding:"10px 0px 10px 0px", font:"normal normal normal 16px/24px Segoe UI", display:"flex", justifyContent: "center", width:"100%"}}>
          <div style={{font:"normal normal normal 16px/24px Segoe UI", textAlign:"center"}}>
            {t("RecicleBin.deleteConfirm")}
            <div style={{ display: "flex", whiteSpace: "nowrap", textOverflow: "ellipsis", justifyContent:"center" }}>
              <div style={{ fontWeight: 600, marginRight: "7px", marginLeft: "7px" }}>
                "{item.name + (item.extencion ? "." + item.extencion : "")}".
              </div>
              {t("RecicleBin.deleteConfirmTwo")}
            </div>
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', gap:"20px" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={confirmDelete}  
            text={t("RecicleBin.confirm")}
            backgrondColor='#22638D'
            color='#FFFFFF'
            hoveredBackgrondColor='#0d476d'
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default DeleteConfirm;