import { HaveTheAppInstallVO, OpenFileAgentParamsVO } from "../model/OpenFileModel";
import { Api } from "./api";

export async function CreateOpenFile(itemId: string) {
  const request = await Api.post<OpenFileAgentParamsVO>("openFile", { id: itemId } );

    return request.data;
}

export async function CheckHaveTheAppInstall(openFileId: string){
  const request = await Api.get<HaveTheAppInstallVO>(
    "openFile/CheckHaveTheAppInstall/" + openFileId 
  );
  return request.data; 
}
