interface RestoreIconProps {
  color?: string;
  opacity?: string;
}

export const RestoreIcon: React.FC<RestoreIconProps> = ({ color, opacity }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.778" height="20" viewBox="0 0 17.778 20">
    <path id="restore_from_trash_24dp_FILL1_wght400_GRAD0_opsz24" 
      d="M167.778-825.556H170v-4.611l1.778,1.722L173.333-830l-4.444-4.444L164.444-830,166-828.444l1.778-1.722ZM163.333-820a2.14,2.14,0,0,1-1.569-.653,2.14,2.14,0,0,1-.653-1.569v-14.444H160v-2.222h5.556V-840h6.667v1.111h5.556v2.222h-1.111v14.444a2.14,2.14,0,0,1-.653,1.569,2.14,2.14,0,0,1-1.569.653Z" 
      transform="translate(-160 840)" 
      fill={color ? color : "#2d2d2d"}
      opacity={opacity ? opacity : "0.2"}
    />
  </svg>
);