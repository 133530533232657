import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface FormHeaderProps {
    title: string;
    subTitle?: string | undefined;
    titleSize?: number;
}

export default function FormHeader({ title, subTitle, titleSize }: FormHeaderProps) {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Typography
                fontSize={titleSize !== undefined ? titleSize : 53}
                fontWeight={600}
                letterSpacing={0}
                color="#2D2D2D"
                marginBottom={'10px'}
                sx={{
                    font:"normal normal 600 32px/53px Segoe UI"
                }}
            >
                { title }
            </Typography>

            {subTitle != undefined && (
                <Typography
                    fontSize={'16px'}
                    fontWeight="normal"
                    letterSpacing={0}
                    sx={{
                        color:"#2D2D2D",
                        opacity : '0.6',
                        font:"normal normal normal 18px/27px Segoe UI"
                    }}
                >
                    { subTitle }
                </Typography>
            )}
        </>
    )
}