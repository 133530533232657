import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { DocumentTypeCreate } from "../../model/DocumentType";
import { 
  GetDocumentTypes
 } from "../../services/WorkSpaceServices";
import DocumentTypeList from "./DocumentTypeList";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./DocumentType.module.css";
import stylesList from "../Style/List.module.css";
import { useBoolean } from "@fluentui/react-hooks";
import { IconButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react';
import { useTranslation } from "react-i18next";
import LongMenu from  "../../components/MenuContexto/MenuContexto";


export interface DocumentTypeCompConfig {
  skip: number;
  id: string | undefined;
}

export interface DropdownMenuContextoConfig{
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
}

function useVisibility(
  cb: (isVisible: boolean) => void,
  deps: React.DependencyList
): (node: any) => void {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  return useCallback((node) => {
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    });

    if (node) intersectionObserver.current.observe(node);
  }, deps);
}

interface DocumentTypeProps {
    id?: string;
    name: string;
    description: string;
    workSpaceId: string;
    ref?: React.Ref<HTMLLIElement>;
}


interface ListDocumentTypeId {
    workSpaceId: string;
    name: string;
    nameOrg?: string;
    onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
}

const DocumentTypeListComp: React.FC<ListDocumentTypeId> = ({workSpaceId,name,nameOrg, onClickExcluir }) => {
  const [DocumentType, setDocumentType] = useState<DocumentTypeCreate[]>([]);
  const navegate = useNavigate();
  const [limit, setLimit] = useState<number>(50);
  const [isMessageError, setMessageError] = useState<boolean>(false);
  const [isMessageOk, setMessageOk] = useState<boolean>(false);

  let [orderDesc, setOrderDesc] = useState<boolean>(false);
  let [skip, setSkip] = useState<number>(0);
  let [DocumentTypeMax, setDocumentTypeMax] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  

  
  const DocumentTypes: React.ForwardRefExoticComponent<DocumentTypeProps> =
  React.forwardRef(({ id, description, name, workSpaceId}, ref: React.Ref<HTMLLIElement>) => {
    const navegate = useNavigate();
    <DocumentTypeList id={id} name={name}/>   
    
    const onClickEdit = (
      event: React.FormEvent<HTMLElement>
    ) => {
      const { myValue } = event.currentTarget.dataset;
      navegate("/" + nameOrg + "/Workspace/" + workSpaceId + "/documetTypes/"  + id);
    };
    
    return (
      <div>    
        <li className={`${styles.itemContentDocument} ${stylesList.row} ${styles.ListDocumetTypes}`} key={id} ref={ref}>
          <div className={styles.nameList}>{name}{" "}</div>
          <div className={styles.descriptionList}>{description}</div>  
          <LongMenu onClickEditar={onClickEdit} onClickExcluir={onClickExcluir} idMenu={id} />   
        </li>
      </div>
    );
  });

  const lastBook = useVisibility(
    (visible) => {
      if (visible) {
        if (name == "" ) {
            GetDocumentTypes(workSpaceId,skip, limit, name).then((newDocumentType) => {
            if (newDocumentType.length < limit) {
                setDocumentTypeMax(false);
            }
            setSkip(skip + newDocumentType.length);
            setDocumentType([...DocumentType, ...newDocumentType]);
          });
        }
      }
    },
    [workSpaceId,skip, DocumentType]
  );


  useEffect(() => {
    if(name == "")
    {
        GetDocumentTypes(workSpaceId,skip, limit, name).then((newDocumentType) => {
            if (newDocumentType.length < limit) {
                setDocumentTypeMax(false);
            }
            setSkip(newDocumentType.length);
            setDocumentType(newDocumentType);
          });
    }
  }, [workSpaceId,name]);

  function resetMessage() {
    setMessageOk(false);
    setMessageError(false);
  }

  const asc: IIconProps = {
    iconName: 'Down',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const desc: IIconProps = {
    iconName: 'Up',
    styles: {
      root: {
        color: 'black',
        fontWeight:'bold'
      }
    }
  };

  const ascClick = () => {
    setOrderDesc(true)
  };

  const descClick = () => {
    setOrderDesc(false)
  };

return (
  <div>
    <ul className={stylesList.ulContent}>
      <li className={`${stylesList.itemContent} ${stylesList.title}`}>
        <div className={styles.nameTitle}>{t("Nome.message")}
          {orderDesc?
            (<IconButton iconProps={desc} title="Descendente" ariaLabel="Descendente" onClick={descClick}/>):
            (<IconButton iconProps={asc} title="Ascendente" ariaLabel="Ascendente" onClick={ascClick}/>)}
        </div>
        <div className={styles.descriptionTitle}>{t("Description.message")}</div>
      </li>
      {DocumentType.map((documentType) => (
        <DocumentTypes
          key={documentType.id}
          id={documentType.id}
          workSpaceId={documentType.workSpaceId}
          name={documentType.name}
          ref={DocumentType[DocumentType.length - 1].id === documentType.id && DocumentTypeMax ? lastBook : null}
          description={documentType.description}
        />
      ))}
    </ul>
    <div>
      {DocumentTypeMax === false ? 
      null
      : null}
      </div>

  </div>
);
}
export default DocumentTypeListComp;
