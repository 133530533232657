import { Box, Tooltip } from "@mui/material";
import { SearchIcon } from '../../../../../assets/icons/SearchIcon';
import {DownloadIcon} from '../../../../../assets/icons/downloadIcon';
import {haveDisplay} from "../../HaveDisplayFunction"
import { useTranslation } from "react-i18next";

interface ILoupe {
    extension: string | undefined;
    onClickExibirViewer () : void;
    downloadFile () : void;
    itemType: string | undefined;
}

export default function Loupe({ 
    extension,
    onClickExibirViewer,
    downloadFile,
    itemType
}: ILoupe) {

    const { t } = useTranslation();

    const fileHasDisplay = haveDisplay(extension);

    return (
        <>
            {itemType === 'folder' ? (
                <Box
                    sx={{
                        opacity: .5
                    }}
                >
                    <SearchIcon/>
                </Box>
            ) : (
                    <Tooltip title={fileHasDisplay  ? t('Visualizador.message') : 'Download'}>
                <Box
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={fileHasDisplay  ? onClickExibirViewer : downloadFile}
                >
                    {fileHasDisplay ? (
                        <SearchIcon/>
                    ) :
                        <DownloadIcon/>
                    }
                </Box>
                </Tooltip>
            )}
        </>
    )
}