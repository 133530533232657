import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  DeleteGroup,
} from "../../services/GroupsServices";
import Btn from "../../components/Button/Btn";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Stack } from "@fluentui/react/lib/Stack";
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import GroupListComp from "./GroupsListComp";
import styles from "../Style/List.module.css";
import { useParams  } from "react-router-dom";
import GroupsConfig from "./GroupsConfig";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useUpload } from "../../context/UploadProvider/useUpload";

const GruopList  = (props: any) => {
  const [groupEdit, setGroupEdit] = useState<string|undefined|number>(0)
  const [groupDeleted, setGroupDeleted] = useState<string|undefined>(undefined);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [searchName, setSearchName] = useState<string>("");
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [groupDeletedName, setGroupDeletedName] = useState<string>("");
  const [reaload, setReaload] = useState<boolean>(false);
  const { nameOrg } = useParams();
  const [message, setMessage] = useState<string>("");
  const iconProps = { iconName: "Filter" };
  const { t, i18n } = useTranslation();
  const upload = useUpload();

  const updatePanel = () => {
    setReaload(true);
    dismissPanel();
  };

  useEffect(() => {
    if(reaload == true ){
      setReaload(false)
    }
  }, [reaload]);

  const onChangeSearchName =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  }; 

  const onClickNew = () => {
    toggleEdit(undefined);
  };

  const onClickEdit = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleEdit(myValue);
  };

  const onClickDelete = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleDelete(myValue);
  };

  useEffect(() => {
    if(typeof groupEdit != "number")
    {
      openPanel();
    }
  }, [groupEdit]);

  useEffect(() => {
    if(groupDeleted != undefined)
    {
      toggleHideDialog();
    }
  }, [groupDeleted]);

  const toggleEdit = (
    value:string|undefined
  ) => {
    if(value ==  groupEdit)
    {
      openPanel();
    }
    else
    {
      setGroupEdit(value);
    }
  };

  const toggleDelete = (
    value:string|undefined
  ) => {
    if(value ==  groupDeleted)
    {
      if(groupDeleted != undefined)
      {
        toggleHideDialog();
      }
    }
    else
    {
      setGroupDeleted(value);
    }
  };

  function deleteGroupPopup() {
    if (groupDeleted != undefined) {

      DeleteGroup(groupDeleted).then(() => {
        setMessage(t("GrupoExcluido.message"));
        setPopupOk(true);
        setReaload(true);
      });
    }
    toggleHideDialog();
  }

  function closePopUpOk() {
    setReaload(true);
    setPopupOk(false);
  }

  const handleClose = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  const updatePanelSave = () => {
    updatePanel();
    setPopupOk(true);
    setMessage(t("GrupoSalvo.message"));
    upload.setShowEx(false);
  };

  return (
    <div>
      <Stack  horizontal className={styles.hPage}>
        <Stack.Item grow>
          <h3 className={styles.hList}>{t("Grupos.message")}</h3>
        </Stack.Item>
        <Stack className={styles.stackSide}>
          <Stack.Item className={styles.BtnNew}>
            <Btn text={t("Novo.message")} onClick={onClickNew} />
          </Stack.Item>
          <Stack.Item className={styles.InputSearch}>
            <TextFieldComp iconProps={{ iconName: "Filter" }}  value={searchName} onChange={onChangeSearchName}/>
          </Stack.Item>
        </Stack>
      </Stack>
      {reaload?(<div></div>):(
        nameOrg != undefined?(<GroupListComp nameOrg={nameOrg} name={searchName} onClickEditar={onClickEdit} onClickExcluir={onClickDelete}/>):(<li className={styles.itemContent}></li>))}
      <Panel
        isOpen={isOpen}
        closeButtonAriaLabel={t("Fechar.message")}
        onDismiss={updatePanel}
        headerText={t("SalvarGrupo.message")}
        isFooterAtBottom={true}
      >
        {nameOrg!= undefined && typeof groupEdit != "number" && (
          <GroupsConfig
            nameOrg={nameOrg}
            idGroup={groupEdit}
            saveOkMetod={updatePanelSave}
          />
        )}
      </Panel>
      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
          <DialogContent title={t("Excluir.message")}>
            <h3>{t("CertezaExcluirGrupo.message")}</h3>
          </DialogContent>
          <DialogFooter>
            <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
            <Btn onClick={deleteGroupPopup} text={t("Excluir.message")} />
          </DialogFooter>
        </Dialog>

        <Snackbar
          open={popupOk}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="success" onClose={handleClosePO}>
            {message}
          </MuiAlert>
        </Snackbar>
    </div>
  );
};

export default GruopList;

