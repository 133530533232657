import React, { ReactNode } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface DropdownCompProps {
  options: { key: string | number; text: string }[];
  onChange: (event: SelectChangeEvent<string | number>, child: ReactNode) => void;
  placeholder: string;
  label: string;
  selectedKey: string | number;
  iconSelected: React.ReactElement;
  stateDefault?: string;
  suffix?: string;
  currentSuffix?: string;
}

const DropdownComp: React.FC<DropdownCompProps> = ({ options, onChange, placeholder, label, selectedKey, iconSelected, stateDefault, suffix, currentSuffix }) => {

  return (
    <FormControl variant="standard" fullWidth>
    <InputLabel shrink={true} id="custom-select-label" sx={{color:"#2D2D2D !important", font:"18px Segoe UI", fontWeight:600}}>{label}</InputLabel>
    <Select
      labelId="custom-select-label"
      id="custom-select"
      value={selectedKey || ""}
      onChange={onChange}
      MenuProps={{
        PaperProps: {
          style: {
            zIndex: 9999999,
            marginTop:"8px",
            border:"1px solid #2D2D2D1A"
          },
        },
        MenuListProps:{
          style:{paddingTop:"0px", paddingBottom:"0px"}
        },
      }}
      SelectDisplayProps={{style:{justifyContent:"space-between", display:"flex"}}}
      displayEmpty
      renderValue={
        selectedKey !== "" ? undefined : () => <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{placeholder}</span>
      }
    >
      <MenuItem disabled value="">
        <em>{placeholder}</em>
      </MenuItem>
      {options.map(option => (
        <MenuItem sx={{justifyContent: "space-between", borderTop:"1px solid #2D2D2D1A"}} key={option.key} value={option.key}>
          <div style={{display:"flex"}}>
            {option.text}
            {currentSuffix != undefined && stateDefault == option.key &&
              <div style={{marginLeft:"7px", color:"rgb(45, 45, 45, 0.3)"}}>{currentSuffix}</div>
            }
            {stateDefault == option.key &&
              <div style={{marginLeft:"7px", color:"rgb(45, 45, 45, 0.3)"}}>{suffix}</div>
            }
          </div>
          {selectedKey === option.key && (
            <span style={{ marginLeft: '8px' }}>{iconSelected}</span>
          )}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  );
};

export default DropdownComp;
