export const MaxStorageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="178" height="77.696" viewBox="0 0 178 77.696">
      <g id="Grupo_1305" data-name="Grupo 1305" transform="translate(-666 -305.227)">
        <g id="Grupo_1303" data-name="Grupo 1303" transform="translate(666 305.227)">
          <path id="Caminho_414" data-name="Caminho 414" d="M28.841,19.79A74.805,74.805,0,0,0,0,78.9H32.58a42.412,42.412,0,0,1,19.6-35.767Z" transform="translate(0 -3.901)" fill="#22638d" fill-rule="evenodd" opacity="0.3" />
          <path id="Caminho_415" data-name="Caminho 415" d="M174.012,78.9A74.877,74.877,0,0,0,145.17,19.79L121.8,43.134A42.356,42.356,0,0,1,141.409,78.9Z" transform="translate(-24.012 -3.901)" fill="#22638d" fill-rule="evenodd" />
          <path id="Caminho_416" data-name="Caminho 416" d="M126.292,13.93a75.117,75.117,0,0,0-87.15,0L62.761,37.575a42.515,42.515,0,0,1,39.888,0Z" transform="translate(-7.716)" fill="#22638d" fill-rule="evenodd" opacity="0.5" />
          <path id="Caminho_417" data-name="Caminho 417" d="M93.188,88.769a5.451,5.451,0,0,0,7.557,1.5l24.246-21.836L94.671,81.214a5.453,5.453,0,0,0-1.483,7.555" transform="translate(-18.189 -13.492)" fill="#2d2d2d" fill-rule="evenodd" />
        </g>
        <circle id="Elipse_114" data-name="Elipse 114" cx="27" cy="27" r="27" transform="translate(790 313)" fill="#fff" />
        <circle id="Elipse_115" data-name="Elipse 115" cx="23" cy="23" r="23" transform="translate(794 316.922)" fill="#22638d" opacity="0.2" />
        <path id="data_usage_24dp_FILL1_wght400_GRAD0_opsz24" d="M93-852a12.632,12.632,0,0,1-5.07-1.026,12.991,12.991,0,0,1-4.128-2.8,13.363,13.363,0,0,1-2.779-4.154A12.637,12.637,0,0,1,80-865.033a12.56,12.56,0,0,1,3.38-8.8A12.631,12.631,0,0,1,91.7-878v3.91a8.725,8.725,0,0,0-5.574,3.014,8.908,8.908,0,0,0-2.226,6.044,8.8,8.8,0,0,0,2.665,6.451A8.757,8.757,0,0,0,93-855.91a9.028,9.028,0,0,0,4.014-.912,9.143,9.143,0,0,0,3.136-2.476l3.38,1.955a12.783,12.783,0,0,1-4.518,3.893A12.74,12.74,0,0,1,93-852Zm11.9-7.754-3.38-1.955a9.09,9.09,0,0,0,.439-1.613,9.85,9.85,0,0,0,.146-1.71,8.908,8.908,0,0,0-2.226-6.044A8.725,8.725,0,0,0,94.3-874.09V-878a12.631,12.631,0,0,1,8.32,4.17,12.56,12.56,0,0,1,3.38,8.8,14.462,14.462,0,0,1-.26,2.769A9.779,9.779,0,0,1,104.9-859.754Z" transform="translate(724 1204.922)" fill="#22638d" />
      </g>
    </svg>
  )
}