import React, { useEffect, useRef, useState } from "react";
import { WorkSpaceItemRecursive } from "../../model/ItemModel";
import { GetTreeByWorkspaceFolderIdAll } from "../../services/Item/ItemServicesGet";
import { GetOneWorkSpace } from "../../services/WorkSpaceServices";
import LoaderComp from '../../components/Loader/LoaderComp';
import TreeComponent from "./TreeComponent";
import WorkSpaceItemListFolderComponent from "./WorkSpaceItemListFolderComponent";

interface WorkSpaceItemListFolderTreeProps {
    idWorkspace: string;
    setPopupOkMessage: (ok: boolean, message: string) => void;
    setPopupErroMessage: (ok: boolean, message: string) => void;
    clickFunction: (idFolder: string | undefined, nameFolder: string | undefined) => void;
    clickFunctionWorkspace: (nameWorkspace: string | undefined) => void;
    onDrop?: (event: React.DragEvent<HTMLDivElement>, key: string | undefined, endFunction: () => void) => void;
    reloadFolders: () => void;
    targetId?: string;
}

const WorkSpaceItemListFolderTree: React.FC<WorkSpaceItemListFolderTreeProps> = ({
    idWorkspace,
    setPopupOkMessage,
    setPopupErroMessage,
    clickFunction,
    clickFunctionWorkspace,
    onDrop,
    targetId
}) => {
    const [reload, setReload] = useState<boolean>(false);
    const [displayNav, setDisplayNav] = useState<boolean>(false);
    const [nav, setNav] = useState<WorkSpaceItemRecursive[]>([]);
    const [nameWork, setNameWork] = useState<string>();
    const [isHover, setIsHover] = useState<boolean>(false);
    const [expandedLinks, setExpandedLinks] = useState<{ [key: string]: boolean }>({});
    const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(undefined);

    const [widthWork, setWidthWork] = useState<number>(0);

    useEffect(() => {
        const loadWorkspaceData = async () => {
            setReload(true);

            try {
                const workspaceItems = await GetTreeByWorkspaceFolderIdAll(idWorkspace);
                setNav(workspaceItems);

                const workspace = await GetOneWorkSpace(idWorkspace);
                if (workspace) {
                    setNameWork(workspace.name);
                }

                setDisplayNav(true);
            } catch (error) {
                console.error(error);
            } finally {
                setReload(false);
            }
        };

        loadWorkspaceData();
    }, [idWorkspace]);


    useEffect(() => {

        if (targetId) {
            expandToTarget(nav, targetId);
        }
        if(targetId === idWorkspace){
            setSelectedFolderId(idWorkspace)
        }

    }, [idWorkspace, targetId]);

    const expandToTarget = (items: WorkSpaceItemRecursive[], targetId: string): boolean => {
        for (const item of items) {
            if (item.id === targetId) {
                setExpandedLinks(prev => ({ ...prev, [item.id!]: true }));
                setSelectedFolderId(item.id);
                return true;
            }
            if (item.children) {
                const found = expandToTarget(item.children, targetId);
                if (found) {
                    setExpandedLinks(prev => ({ ...prev, [item.id!]: true }));
                    return true;
                }
            }
        }
        return false;
    };

    const toggleExpand = (key: string) => {
        setExpandedLinks(prev => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const preventDefaultBehaviour = (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
        preventDefaultBehaviour(event);
        setIsHover(true);
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        preventDefaultBehaviour(event);
        setIsHover(false);
    };

    const leave = () => {
        setIsHover(false);
    };

    const parentRef = useRef<HTMLDivElement>(null);

    function handleWidth(width: number){
        setWidthWork(width)
    }

    return (
        <>
            {reload ? (
                <div><LoaderComp big={true} /></div>
            ) : (
                <div ref={parentRef} style={{ overflowX: "auto", height:"100%" }}>
                    <div
                        className={isHover ? "hoverDrop name" : "name"}
                        onClick={() => {clickFunctionWorkspace(nameWork); setSelectedFolderId(idWorkspace)}}
                        onDragEnter={onDragEnterAndOver}
                        onDragLeave={onDragLeave}
                        onDragOver={onDragEnterAndOver}
                        onDrop={onDrop == undefined ? undefined : (e) => onDrop(e, undefined, leave)}
                        style={{
                            height: "48px", 
                            padding: "15px", 
                            cursor:"pointer", 
                            color: "black", 
                            fontWeight: 600, 
                            backgroundColor: selectedFolderId === idWorkspace ? "#dde4f2" : "transparent",
                            width: ((parentRef.current?.clientWidth != undefined ? parentRef.current?.clientWidth : 0) > widthWork) ? parentRef.current?.clientWidth! : widthWork
                        }}
                    >
                        {nameWork}
                    </div>

                    {displayNav &&                   
                        <TreeComponent
                            idWorkspace={idWorkspace}
                            setPopupError={setPopupErroMessage}
                            setPopupSuccess={setPopupOkMessage}
                            onDrop={onDrop}
                            clickFunction={clickFunction}
                            setSelectedFolderId={setSelectedFolderId}
                            expandedLinks={expandedLinks}
                            items={nav}
                            selectedFolderId={selectedFolderId}
                            toggleExpand={toggleExpand}
                            treeItemComponent={WorkSpaceItemListFolderComponent}
                            setWidth={handleWidth}
                        />
                    }
                </div>
            )}
        </>
    );
};

export default WorkSpaceItemListFolderTree;
