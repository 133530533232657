import React, { useEffect, useState } from "react";
import styles from '../RecycleBinList.module.css'
import { useTranslation } from "react-i18next";
import { Checkbox } from "@fluentui/react";

interface DeleteListCompProps {
    ref?: React.Ref<HTMLLIElement>
    changeAllCheckbox: (set: boolean) => void
    isCheckedCheckbox: boolean
  }

  const GridTitle: React.ForwardRefExoticComponent<DeleteListCompProps> =
  React.forwardRef(({ changeAllCheckbox, isCheckedCheckbox }, ref: React.Ref<HTMLLIElement>) => {
   
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const { t, i18n } = useTranslation();

    useEffect(()=>{
      setIsChecked(isCheckedCheckbox)
    },[isCheckedCheckbox])

    const handleChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
      if (checked !== undefined) {
        changeAllCheckbox(checked);
      }
    }

    return (
        <li className={styles.gridRecycleBinTitleLi}>
          <div className={styles.gridRecycleBinIconsAndCheckbox}>
            <span >
              <Checkbox 
                checked={isChecked} 
                styles={{
                  checkbox:{
                    height:"20px", 
                    width:"20px", 
                    margin:"0", 
                    background: isChecked ? "#22638D" : "", 
                    border: isChecked ? "1px solid #22638D" : "1px solid rgb(45, 45, 45, 0.5)"
                  }
                }} 
                onChange={handleChange} 
              />
            </span>
            <span style={{width:"20px"}}>             
            </span>
            <span style={{width:"20px"}}>            
            </span>
          </div>

          <div className={styles.gridRecycleBinTitleDiv}>
            <span className={styles.gridRecycleBinSpan}>
              {t("RecicleBin.fileName")}
            </span>
          </div>

          <div className={styles.gridRecycleBinTitleDiv}>
            <span className={styles.gridRecycleBinSpan}>
              {t("RecicleBin.userName")}
            </span>
          </div>

          <div className={styles.gridRecycleBinTitleDiv}>
            <span className={styles.gridRecycleBinSpan}>
              Workspace
            </span>
          </div>

          <div className={styles.gridRecycleBinTitleDiv}>
            <span className={styles.gridRecycleBinSpan}>
              {t("RecicleBin.removedBy")}
            </span>
          </div>

          <div style={{ borderRight:"0px"}} className={styles.gridRecycleBinTitleDiv}>
            <span className={styles.gridRecycleBinSpan}>
              {t("RecicleBin.date")}
            </span>
          </div>
        </li>
    );
    
  });
  

  export default GridTitle;