import { Button } from '@mui/material';
import React, { ReactNode } from 'react';

interface CustomButtonProps {
  isDisabled?: boolean;
  color: string;
  colorHover?: string;
  borderColor?: string;
  borderColorHover?: string;
  borderColorDisabled?: string;
  bqColor?:string;
  bqColorHover?: string;
  bqColorDisabled?: string;
  heigth?: string;
  children: ReactNode;
  onclick: (e: any) => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({isDisabled, color, colorHover, borderColor, borderColorHover, borderColorDisabled, bqColor, bqColorHover, bqColorDisabled, heigth, children, onclick }) => {
  return (
    <Button
      disabled={isDisabled}
      onClick={onclick}
      sx={{
        height: heigth,
        padding:"0px",
        font: "normal normal normal 16px/21px Segoe UI",
        textTransform:"none",
        backgroundColor: bqColor,
        borderRadius:"6px",
        width:"100%",
        color: color,
        border: "1px solid",
        borderColor: borderColor,
        '&.Mui-disabled': {
          borderColor: borderColorDisabled,
          backgroundColor: bqColorDisabled,
          color: "#FFFFFF",
        },                               
        '&:hover': {
          borderColor: borderColorHover,
          backgroundColor: bqColorHover,
          color: colorHover,
        },
      }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
