import { AlertColor, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoaderComp from "../../../../components/Loader/LoaderComp";
import { trackerItemVO } from "../../../../model/ItemModel";
import { GetAllRecentTracker } from "../../../../services/Item/ItemServicesGet";
import { OrgInfoTrackerListingHeader } from "../OrgInfoTrackerListingHeader/OrgInfoTrackerListingHeader";
import { OrgInfoTrackerListItem } from "./components/OrgInfoTrackerListItem";
import { NoTrackerView } from "./NoTrackerView";

interface IOrgInfoTrackerListing {
    setUserError: (set: boolean) => void;
    setSeverity: (set: AlertColor) => void;
    setPopupMessage: (set: string | undefined) => void;
}

export const OrgInfoTrackerListing: React.FunctionComponent<IOrgInfoTrackerListing> = ({
    setUserError,
    setPopupMessage,
    setSeverity
}) => {

    const { nameOrg } = useParams();
    const [trackersLoading, setTrackersLoading] = useState<boolean>(false);
    const [trackers, setTrackers] = useState<trackerItemVO[]>([]);


    useEffect(() => {
        setUserError(false);
        if (nameOrg != undefined) {
            setTrackersLoading(true);
            GetAllRecentTracker(nameOrg)
                .then(data => {
                    if (Array.isArray(data)) {
                        setTrackers(data);
                    } else {
                        setTrackers([]);
                    }
                })
                .catch(err => {
                    setPopupMessage(err.response?.data);
                    setSeverity("error");
                    setTrackers([]);
                })
                .finally(() => {
                    setTrackersLoading(false);
                });
        }
    }, [nameOrg]);

    return (
        <>
            { trackers.length > 0 && (
                <OrgInfoTrackerListingHeader />
            ) }
            { trackersLoading ? (
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50%"
                }}>
                    <LoaderComp />
                </Box>
            ) : (
                <>
                    { trackers.length > 0 ? (
                        trackers.map(({
                            dateCreateTracker,
                            emailUser,
                            user,
                            message,
                            id,
                            itemExtension,
                            nameItem
                        }) => (
                            <OrgInfoTrackerListItem 
                                dateCreateTracker={dateCreateTracker!}
                                emailUser={emailUser!}
                                itemExtension={itemExtension!}
                                message={message!}
                                nameItem={nameItem!}
                                user={user!}
                                key={id!}
                            />
                        ))
                    ) : (
                        <NoTrackerView />
                    ) }
                </>
            ) }
        </>
    )
}