import * as React from 'react';
import { FilterButtonIcon } from '../../../assets/icons/icons.tsx/FilterButtonIcon';
import { useState } from 'react';
import CustomDropdown from '../../../components/Dropdown/DropdownComp';
import { useTranslation } from 'react-i18next';
import { ConfirmIcon } from '../../../assets/icons/icons.tsx/ConfirmIcon';
import { Button, Popover } from '@mui/material';

interface FilterCalloutProps {
  workspaceIdFilter?: string;
  workspaceOptions: { key: string | number; text: string }[];
  setWorkspaceIdSelectedCallout: (value: string) => void;
  workspaceIdSelectedCallout: string;
  filterMyFiles:(checkbox: boolean, workspaceId: string) => void;
  isAdmOrg?: boolean;
}

const FilterCallout: React.FunctionComponent<FilterCalloutProps> = ({workspaceIdFilter, filterMyFiles, setWorkspaceIdSelectedCallout, workspaceIdSelectedCallout, isAdmOrg, workspaceOptions}) => {

  const { t, i18n } = useTranslation();
  const buttonRef = React.useRef<HTMLDivElement>(null);
  const toggleCallout = () => setIsCalloutVisible(!isCalloutVisible);
   
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const [selectedKeyFilterFiles, setSelectedKeyFilterFiles] = useState<string>("01")
  const [currentSuffix, setCurrentSuffix] = useState<string>("")

  function applyFilter(){
    if(selectedKeyFilterFiles == "01"){
      filterMyFiles(true, workspaceIdSelectedCallout)
    }else{
      filterMyFiles(false, workspaceIdSelectedCallout)
    }
    setCurrentSuffix(t("RecicleBin.current"))
  }

  function reseteFilter(){
    setWorkspaceIdSelectedCallout("")
    if(selectedKeyFilterFiles != "01" ||  workspaceIdSelectedCallout != ""){
      filterMyFiles(false, "")
    }

    setSelectedKeyFilterFiles("01")
    setCurrentSuffix("")
  }


  const handleDropdownChange = (event: any): void => {
    const selectedKey = event.target.value as string;
    setWorkspaceIdSelectedCallout(selectedKey);

    if(workspaceIdFilter == selectedKey && currentSuffix != ""){
      setCurrentSuffix(t("RecicleBin.current"))
    }else{
      setCurrentSuffix("")
    }
  };

  const handleFilterFiles = (event: any): void => {
    const selectedKey = event.target.value as string;
    setSelectedKeyFilterFiles(selectedKey)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      {true && (<div>
        <div ref={buttonRef}>
          <Button aria-describedby={id} 
            sx={{
              height:"44px", 
              minWidth:"44px",
              borderRadius:"6px", 
              marginLeft:"10px", 
              border:"1px solid",
              borderColor: anchorEl ? "#22638D" : "rgb(45, 45, 45, 0.2)",
              display:"flex",
              alignItems:"center",
              justifyContent:"center",
              backgroundColor:"#FBFBFB",
              cursor:"pointer",
              padding:"0px",
              '&:hover': {
                borderColor:"#22638D"
              },
            }}
            onClick={handleClick}
          >
            <FilterButtonIcon color={anchorEl ? "#22638D" : undefined}/>
          </Button> 
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{sx:{borderRadius:"6px", marginTop:"10px", marginRight:"25px", border:"1px solid #22638D", width:"334px"}}}
        >
          <div>
            <div style={{font: "normal normal 600 18px/27px Segoe UI", borderBottom: "1px solid rgb(0, 0, 0, 0.08)", padding:"12px 15px 12px 15px"}}>{t("RecicleBin.filter")}</div>
            <div style={{ borderBottom: "1px solid rgb(0, 0, 0, 0.08)", padding:"12px 15px 15px 15px", margin:"0px"}}>
              <div style={{marginBottom:"15px"}}>
                <CustomDropdown
                  label={"Workspace"}
                  placeholder={t("RecicleBin.select")}
                  options={workspaceOptions}
                  onChange={handleDropdownChange}
                  selectedKey={workspaceIdSelectedCallout}
                  iconSelected={<ConfirmIcon color='#22638D'/>}
                  currentSuffix={currentSuffix}
                  stateDefault={workspaceIdFilter}
                />
              </div>
              {isAdmOrg && 
                <CustomDropdown
                  label={t("RecicleBin.files")}
                  placeholder={t("RecicleBin.select")}
                  options={[{key: "01", text: t("RecicleBin.viewMyFiles")}, {key: "02", text: t("RecicleBin.seeAllFiles")}]}
                  onChange={handleFilterFiles}
                  selectedKey={selectedKeyFilterFiles}
                  iconSelected={<ConfirmIcon color='#22638D'/>}
                  suffix={t("RecicleBin.defalt")}
                  stateDefault='01'
                />
              }
            </div>

            <div style={{display: "flex", justifyContent:"space-between", margin:"0px", padding:"15px"}}>
              <button style={{
                border: "1px solid rgb(45, 45, 45, 0.2)", 
                padding: "6px 10px 6px 10px",
                borderRadius:"6px",
                backgroundColor: "transparent",
                color:"rgb(45, 45, 45, 0.2)",
                cursor:"pointer"
                }} 
                onClick={() => { reseteFilter(); toggleCallout(); }}
              >
                {t("RecicleBin.reset")}
              </button>

              <button style={{
                border: "1px solid rgb(45, 45, 45, 0.2)", 
                padding: "6px 10px 6px 10px",
                borderRadius:"6px",
                backgroundColor: "#22638D",
                color:"#FFFFFF",
                cursor:"pointer"
                }} 
                onClick={() => { applyFilter(); toggleCallout(); }}
              >
                <div style={{display:"flex", alignItems:"center"}}>
                  <ConfirmIcon/>
                  <div style={{marginLeft:"10px"}}>
                    {t("RecicleBin.applyFilter")}
                  </div> 
                </div>
              </button>
            </div>
          </div>
        </Popover>
        </div>
      )}
    </div>
  );
};

export default FilterCallout;
