interface DeletePermanentIconProps {
  color?: string;
  opacity?: string;
}

export const DeletePermanentIcon: React.FC<DeletePermanentIconProps> = ({ color, opacity }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.778" height="20" viewBox="0 0 17.778 20">
    <path
      id="delete_forever_24dp_FILL1_wght400_GRAD0_opsz24"
      d="M166-825l2.889-2.889L171.778-825l1.556-1.556-2.889-2.889,2.889-2.889-1.556-1.556L168.889-831,166-833.889l-1.556,1.556,2.889,2.889-2.889,2.889Zm-2.667,5a2.14,2.14,0,0,1-1.569-.653,2.14,2.14,0,0,1-.653-1.569v-14.444H160v-2.222h5.556V-840h6.667v1.111h5.556v2.222h-1.111v14.444a2.14,2.14,0,0,1-.653,1.569,2.14,2.14,0,0,1-1.569.653Z"
      transform="translate(-160 840)"
      fill={color ? color : "#2d2d2d"}
      opacity={opacity ? opacity : "0.2"}
    />
  </svg>
);