import { Color } from "./interface/Color"

export const RecicleBin: React.FC<Color> = ({
    color
  }) => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="17.778" height="20" viewBox="0 0 17.778 20">
        <path id="delete_24dp_FILL1_wght400_GRAD0_opsz24_3_" data-name="delete_24dp_FILL1_wght400_GRAD0_opsz24 (3)" d="M163.333-820a2.14,2.14,0,0,1-1.569-.653,2.14,2.14,0,0,1-.653-1.569v-14.444H160v-2.222h5.556V-840h6.667v1.111h5.556v2.222h-1.111v14.444a2.14,2.14,0,0,1-.653,1.569,2.14,2.14,0,0,1-1.569.653Zm2.222-4.444h2.222v-10h-2.222Zm4.444,0h2.222v-10H170Z" transform="translate(-160 840)" fill="#cecece"/>
      </svg>
    )
}