import React from 'react';
import WorkSpaceDeleteListFileComponentImage from '../../WorkSpaceItem/WorkSpaceItemListFileComponentImage';
import { DeletedItem } from '../../../model/ItemModel';
import { Button } from '@mui/material';
import openInAnotherTab from "../../../../src/assets/icons/openInAnotherTab.svg";
import CustomTooltip from './CustomTooltip';
import { useTranslation } from 'react-i18next';

interface SelectedListProps {
  listItem: DeletedItem[];
  onclick?: (itemId: string) => void;
}

const SelectedList: React.FC<SelectedListProps> = ({ listItem, onclick}) => {
  const { t, i18n } = useTranslation();
  
  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ border: "1px solid rgb(45, 45, 45, 0.2)", width: "100%", height: "250px", borderRadius: "4px", overflowY: 'auto', scrollbarWidth: "thin" }}>
        {listItem && listItem.map((item, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: listItem.length - 1 === index && index > 4 ? "" : "1px solid rgb(45, 45, 45, 0.2)",
              width: "100%",
              height: "44px"
            }}
            key={item.itemId}
          >
            <div style={{ display: "flex", alignItems: "center", padding: "12px", borderRight: "1px solid rgb(45, 45, 45, 0.2)", height: "100%" }}>
              <WorkSpaceDeleteListFileComponentImage extension={item.extencion} isFolder={item.itemType === 1 ? true : false} />
            </div>
            <div style={{ paddingLeft: "10px", display: "flex", justifyContent:"space-between", width:"100%", height:"100%", alignItems:"center"}}>
              {item.name}
              {onclick &&
                <CustomTooltip
                  text={t("RecicleBin.selectedListTooltipText")}
                  backgroundColor="#22638D"
                  marginLeft="88px !important"
                >
                  <Button 
                    sx={{
                      marginRight:"4px", 
                      height:"28px", 
                      width:"28px", 
                      minWidth:"0px",
                      borderRadius:"50%",
                      '&:hover':{
                        backgroundColor:"rgb(34, 99, 141, 0.1)"
                      }
                    }} 
                    onClick={()=>onclick(item.itemId)}
                  >
                    <img src={openInAnotherTab} alt='openInAnotherTab'/>
                  </Button>
                </CustomTooltip>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedList;
