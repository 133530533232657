import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OTPInputProps {
  pageQrCode?: boolean;
  onComplete: (otp: string) => void;
  error?: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({ pageQrCode, onComplete, error }) => {
    const { t } = useTranslation();
    
  const length = 6;
  const [values, setValues] = useState<string[]>(Array(length).fill(''));
  
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(length).fill(null));

  const onChangeOtpCode = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;

    setValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = newValue;
      return updatedValues;
    });

    if (newValue.length === 1 && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key === 'Backspace' && values[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    onComplete(values.join(''));
  }, [values, onComplete]);

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom:"20px"
      }}
    >
        <Box 
        sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
        }}
        >
        {values.map((value, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <TextField
                placeholder={"0"}
                variant="standard"
                value={value}
                onChange={(e) => onChangeOtpCode(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e as React.KeyboardEvent<HTMLInputElement>)}
                inputProps={{ 
                    maxLength: 1, 
                    style: { 
                        textAlign: 'center',  
                        font: "normal normal normal 14px/21px Segoe UI"  
                    } 
                }}
                inputRef={(el) => (inputRefs.current[index] = el)}
                sx={{ 
                    caretColor: "#22638D",
                    marginRight: values.length -1 === index ? '' : pageQrCode ? '10px' : '22px',
                    width: pageQrCode ? "40px" : "54px",
                    boxSizing:"border-box",
                    '& .MuiInputBase-root::before': {
                        borderBottom: '1px solid',
                        borderColor: error ? "#D11422" : "rgb(34, 99, 141, 0.5)"
                    },
                    '&:hover .MuiInputBase-root::before': {
                        borderBottom: '1px solid rgb(34, 99, 141, 0.5)',
                    },
                    '& .MuiInputBase-root::after': {
                        borderBottom: '1px solid',
                        borderColor: error ? "#D11422": "#22638D"
                    },
                }}
            />
            {index === 2 && <div style={{ width: pageQrCode ? "10px" : "15px", margin: pageQrCode ? "0px 13px 0px 3px" : "0px 25px 0px 3px"  , borderTop: "1px solid rgb(34, 99, 141, 0.2)" }}></div>}
            </div>                         
        ))}
        </Box>
        {error &&
            <Typography 
                sx={{
                    font:"normal normal normal 12px/18px Segoe UI",
                    width:"100%",
                    marginTop:"4px",
                    color:"#D11422"
                }}
            >
                {t("OTPInput.message")}
            </Typography>
        }
    </Box>
  );
};

export default OTPInput;
