import React, { useEffect } from 'react'

export const useResize = (ref: any) => {
  ref = ref || {}
  const [coords, setCoords] = React.useState({ x: Infinity, y: Infinity })
  const [dims, setDims] = React.useState({ width: Infinity, height: Infinity })
  const [size, setSize] = React.useState({ width: Infinity, height: Infinity })

  const initResize = (event:any) => {
    if (!ref.current) return;
    setCoords({ x: event.clientX, y: event.clientY })
    const { width, height } = window.getComputedStyle(ref.current)
    setDims({ width: parseInt(width, 10), height: parseInt(height, 10) })
  }

  useEffect(() => {
    // Round the size based to `props.step`.
    const getValue = (input:number) => Math.ceil(input / 1) * 1;

    const doDrag = (event:any) => {
      if (!ref.current) return;

      // Calculate the box size.
      const width = getValue(dims.width + event.clientX - coords.x)
      const height = getValue(dims.height + event.clientY - coords.y)

      if(Number.isNaN(width)) return;
      if(Number.isNaN(height)) return;

      // Set the box size.
      ref.current.style.width = width.toString()  + 'px';
      localStorage.setItem("width",width.toString() + "px");
      setSize({ width, height })
    }

    const stopDrag = () => {
      document.removeEventListener('mousemove', doDrag, false)
      document.removeEventListener('mouseup', stopDrag, false)
    }


    document.addEventListener('mousemove', doDrag, false)
    document.addEventListener('mouseup', stopDrag, false)
  }, [dims, coords, ref])

  return { initResize, size }
}