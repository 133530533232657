import React, { useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import { useBoolean } from '@fluentui/react-hooks';
import recycleBinDeleteHeader from "../../../../assets/icons/recycleBinDeleteHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import ButtonDeleteAllOrRestoreAll from '../../components/ButtonDeleteAllOrRestoreAll';
import { DeletePermanentIcon } from '../../../../assets/icons/icons.tsx/DeletePermanentIcon';
import SelectedList from '../../components/SelectedList';

interface DeleteConfirmListProps {
  listItem: DeletedItem[],
  deleteItems: (listItems: DeletedItem[]) => void
}

const DeleteConfirmList: React.FC<DeleteConfirmListProps> = ({listItem, deleteItems}) => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function confirmDelete(){
    hideModal()
    deleteItems(listItem)  
  }

  return (
    <div>
      <ButtonDeleteAllOrRestoreAll 
        icon={<DeletePermanentIcon/>} 
        handleHovered={setIsHovered}
        isHovered={isHovered}
        isSelectedCheckBox={!(listItem.length > 0)}
        onClick={showModal}
        text={t("RecicleBin.deleteSelected")}
      />
      <CustomModal 
        isModalOpen={isModalOpen}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinDeleteHeader} alt='Lixeira'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.deleteTitleList")}
          </div>
        </div>
        <div style={{width: "100%", display:"flex", justifyContent: "center"}}>
          <div style={{width:"540px", textAlign:"center"}}>
            {t("RecicleBin.deleteConfirmList")}         
          </div>
        </div>

        <SelectedList listItem={listItem}/>

        <div style={{ width: '100%', display: 'flex', gap:"20px" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={confirmDelete}  
            text={t("RecicleBin.confirm")}
            backgrondColor='#22638D'
            color='#FFFFFF'
            hoveredBackgrondColor='#0d476d'
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default DeleteConfirmList;