import styles from "./ValidationRuleModalDelete.module.css";
import { Modal } from "@fluentui/react"
import { Box, Snackbar } from "@mui/material"
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import Btn from "../../../components/Button/Btn"
import { DisableValidationRule } from "../../../services/ValidationRuleService";
import { DeleteVr } from "../../../services/ValidationRuleService";
import { useEffect, useState } from "react";
import { useId, useBoolean } from '@fluentui/react-hooks'
import { ItemValidationRulesBool } from "../../../services/Item/ItemServicesGet";
import LoaderComp from "../../../components/Loader/LoaderComp";
import { t } from "i18next";
import {Diseble} from '../../../model/ValidationRuleModel'

    interface IValidationRuleModalDeleteComp {
        onClose: () => void
        show: boolean
        idVrDelete?: string | number
    }
  
    export const ValidationRuleModalDeleteComp: React.FC<IValidationRuleModalDeleteComp> = ({onClose, show, idVrDelete}) => {
      const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(show);
      const [vrHaveItem, setVrHaveItem] = useState<boolean>(false);
      const [waiting, setWaiting] = useState<boolean>(false);
      const [errorMessage, setErrorMessage] = useState<string>(t("ErroAoSalvar.message"));
      const [alertColor, setAlertColor] = useState<AlertColor>("success");
      const [popupOk, setPopupOk] = useState<boolean>(false);

      const titleId = useId('title');

      useEffect(() => {
        if(show){
          setWaiting(true)
          showModal();
          fetchModalData()
        }
      }, [show])

      const fetchModalData = () => {  
        if(idVrDelete && typeof idVrDelete !== "number"){
          ItemValidationRulesBool(idVrDelete)
            .then(data => {
              if(data.is){
                setVrHaveItem(true);
              } else {
                setVrHaveItem(false);
              }
              setWaiting(false)
            })
        }
      } 

      function removeValidationRule() {
        if(idVrDelete != undefined && typeof idVrDelete != "number")
        {
          var validation: Diseble ={
            id:idVrDelete
          };
          DisableValidationRule(validation)
          .then(() => {
            setAlertColor("success");
            setErrorMessage(t("ValidationRules.RemovedOK"));
            setPopupOk(true);
            onCloseModal();
          })
          .catch((error) =>{
            if( error.response && error.response.data && typeof error.response.data  === 'string')
            {
              setErrorMessage(error.response.data);
            }
            else
            {
              setErrorMessage(t("ErroAoSalvar.message"));
            }
              setAlertColor("error");
              setPopupOk(true);
          });
        }
      }

      function deleteDocumentTypePopup() {
        if(idVrDelete != undefined && typeof idVrDelete != "number")
        {
          DeleteVr(idVrDelete)
          .then(() => {
            setAlertColor("success");
            setErrorMessage(t("ValidationRules.DeletedOk"));
            setPopupOk(true);            
            onCloseModal();
          })
          .catch((error) =>{
            if( error.response && error.response.data && typeof error.response.data  === 'string')
            {
              setErrorMessage(error.response.data);
            }
            else
            {
              setErrorMessage(t("ErroAoSalvar.message"));
            }
              setAlertColor("error");
              setPopupOk(true);
          });
        }
      }

      const onCloseModal = () => {        
        hideModal();
        onClose();
      }

      const handleClose = () => {
        setPopupOk(false);
      }

      const handleClosePO = () => {
        setPopupOk(false);
      }
      return (
        <>
            <Modal
              titleAriaId={titleId}
              isOpen={isModalOpen}
              onDismiss={onCloseModal}
              isBlocking={false}
              containerClassName={styles.container}
            > 
              {
                waiting ? 
                <Box className={styles.containerLoader}>
                  <LoaderComp big={true}/>
                </Box> :
                <Box className={styles.containerContent}>
                    <Box>
                      {vrHaveItem ? <h2>{t("Remove.message")}</h2> : <h2>{t("Delete.message")}</h2>}
                    </Box>
                    <Box className={styles.marginY}>
                      {vrHaveItem ? <h3 id={titleId}>{t("ValidationRule.doYouWantToRemoveTheValidationRuleUsedOnTheseFiles")}</h3> : <h3 id={titleId}>{t("ValidationRules.Deleted")}</h3>}
                    </Box>
                    <Box className={styles.btnAlignSelf}>
                      <Btn onClick={onCloseModal} text={t("Cancelar.message")} />
                      {vrHaveItem ? <Btn onClick={removeValidationRule} text={t("Continuar.message")}/> : <Btn onClick={deleteDocumentTypePopup} text={t("Excluir.message")}/>}
                    </Box>
                </Box>
              }
          </Modal>
          <Snackbar
              open={popupOk}
              autoHideDuration={6000}
              message="Archived"
              onClose={handleClose}
              anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
              sx={{ bottom: { xs: 50, sm: 20 } }}
          >
              <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                  {errorMessage}
              </MuiAlert>
          </Snackbar>
        </>
      )
    }