import React, { useEffect, useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import recycleBinRestoreHeader from "../../../../assets/icons/recycleBinRestoreHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import ButtonRestoreAllOrRestoreAll from '../../components/ButtonDeleteAllOrRestoreAll';
import { RestoreIcon } from '../../../../assets/icons/icons.tsx/RestoreIcon';
import { FatherfolderProps } from './RestoreConfirm';
import CustomDropdown from '../../../../components/Dropdown/DropdownComp';
import { FolderPath } from '../FolderPath';
import SelectedList from '../../components/SelectedList';
import LoaderComp from '../../../../components/Loader/LoaderComp';

export interface WorkspaceProps{
  workspaceId: string;
  workspaceName: string;
}

interface RestoreListProps {
  listItem: DeletedItem[];
  RestoreItems: (listItems: DeletedItem[], workspaceId: WorkspaceProps, fatherId?: FatherfolderProps) => void;
  workspaceOptions?: { key: string | number; text: string }[];
  isModalOpen: boolean;
  hideModal: () => void;
  showModal: (value: boolean) => void;
}

const RestoreList: React.FC<RestoreListProps> = ({listItem, RestoreItems, workspaceOptions, isModalOpen, hideModal, showModal}) => {
  const { t, i18n } = useTranslation();
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [fatherfolder, setFatherFolder] = useState<FatherfolderProps | undefined>();
  const [workspaceIdSelected, setWorkspaceIdSelected] = useState<WorkspaceProps>({workspaceId: "", workspaceName: ""})
  const [load, setLoad] = useState<boolean>(false)

  useEffect(()=>{
    setWorkspaceIdSelected({workspaceId: "", workspaceName: ""})
    setFatherFolder(undefined)
    setLoad(false)
  },[isModalOpen])

  function confirmRestore(){
    setLoad(true)
    if (workspaceIdSelected) {
      RestoreItems(listItem, workspaceIdSelected, fatherfolder);
    }
  }

  const handleWorkspace = (event: any): void => {
    const selectedKey = event.target.value as string;
    const selectedOption = workspaceOptions?.find(option => option.key === selectedKey);
    const selectedText = selectedOption ? selectedOption.text : '';

    setWorkspaceIdSelected({workspaceId: selectedKey, workspaceName: selectedText});
  };

  return (
    <div>
      <ButtonRestoreAllOrRestoreAll 
        icon={<RestoreIcon/>} 
        handleHovered={setIsHovered}
        isHovered={isHovered}
        isSelectedCheckBox={!(listItem.length > 0)}
        onClick={()=>showModal(true)}
        text={t("RecicleBin.restoreListButton")}
      />
      <CustomModal 
        isModalOpen={isModalOpen}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinRestoreHeader} alt='Lixeira'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.restoreTitleConfirmList")}
          </div>
        </div>
        <div style={{width: "100%", display:"flex", justifyContent: "center"}}>
          <div style={{width:"540px", textAlign:"center"}}>
            {t("RecicleBin.restoreConfirmList")}         
          </div>
        </div>

        <SelectedList listItem={listItem}/>

        <div style={{paddingTop:"20px"}}>
          {workspaceOptions &&
            <CustomDropdown
              label={"Workspace"}
              placeholder={t("RecicleBin.select")}
              options={workspaceOptions}
              onChange={handleWorkspace}
              selectedKey={workspaceIdSelected.workspaceId}
              iconSelected={<ConfirmIcon color='#22638D'/>}
              suffix={t("RecicleBin.defalt")}
            />
          }

          <FolderPath 
            idWorkspace={workspaceIdSelected.workspaceId != "" ? workspaceIdSelected.workspaceId : ""} 
            newFatherFolder={setFatherFolder}
          />
        </div>

        <div style={{ width: '100%', display: 'flex', gap:"20px", alignItems:"end" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          {load ? (
            <div style={{width:"100%", height:"36px", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <LoaderComp/>
            </div>
            ):(
              <CustomButton 
                icon={<ConfirmIcon/>} 
                onclick={confirmRestore}  
                text={t("RecicleBin.confirm")}
                backgrondColor='#22638D'
                color='#FFFFFF !important'
                hoveredBackgrondColor='#0d476d'
                isDisabled={workspaceIdSelected.workspaceId == ""}
              /> 
            )
          }
        </div>

      </CustomModal>
    </div>
  );
};

export default RestoreList;