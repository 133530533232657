import React, { useEffect, useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import { useBoolean } from '@fluentui/react-hooks';
import recycleBinRestoreHeader from "../../../../assets/icons/recycleBinRestoreHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import { RestoreBlueIcon } from '../../../../assets/icons/icons.tsx/restoreBlueIcon';
import CustomDropdown from '../../../../components/Dropdown/DropdownComp';
import WorkSpaceItemListFileComponentImage from '../../../WorkSpaceItem/WorkSpaceItemListFileComponentImage';
import { FolderPath } from '../FolderPath';
import CustomTooltip from '../../components/CustomTooltip';

export interface FatherfolderProps {
  id?: string,
  name?: string,
}

interface RestoreConfirmProps {
  item: DeletedItem;
  RestoreItem: (itemId: DeletedItem) => void;
  workspaceOptions?: { key: string | number; text: string }[];
}

const RestoreConfirm: React.FC<RestoreConfirmProps> = ({item, RestoreItem, workspaceOptions}) => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);

  const [fatherfolder, setFatherFolder] = useState<FatherfolderProps>();
  const [workspaceIdSelected, setWorkspaceIdSelected] = useState<string>("")

  const newWorkspaceId = workspaceIdSelected != "" ? workspaceIdSelected : item!.workspaceId
  
  function confirmRestore(){
    if(item){
      hideModal()

      if(fatherfolder){
        if (fatherfolder.id === item.folderId && newWorkspaceId !== item.workspaceId) {
          item.folderId = undefined;
          item.fatherFolder = undefined;
        } else {
          item.folderId = fatherfolder.id;
          item.fatherFolder = fatherfolder.name;
        }
      }else{
        if(item.fatherFolder === "deleted"){
          item.fatherFolder = undefined;
          item.folderId = undefined;
        }
      }
      
      item.workspaceId = newWorkspaceId

      RestoreItem(item)
    }
  }

  function setNewFatherfolder(fatherFolder: FatherfolderProps){
    setFatherFolder(fatherFolder)
  }

  useEffect(()=>{
    if(item)
      setWorkspaceIdSelected(item.workspaceId)
  },[isModalOpen])

  const handleWorkspace = (event: any): void => {
    const selectedKey = event.target.value as string;
    setWorkspaceIdSelected(selectedKey);
  };

  return (
    <div>
      <CustomTooltip
        text={t("RecicleBin.restoreConfirmTooltipText")}
        backgroundColor="#22638D"
        marginLeft="114px !important"
      >
        <button 
          style={{cursor:"pointer", border:"none", backgroundColor:"transparent", display: "flex", alignItems:"center"}} 
          onClick={showModal}
        >
          <RestoreBlueIcon/>
        </button>
      </CustomTooltip>

      <CustomModal 
        isModalOpen={isModalOpen}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinRestoreHeader} alt='Lixeira'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.restoreTitleConfirm")}
          </div>
        </div>
        
        <div style={{ padding: "10px 0px 10px 0px", display: "flex", justifyContent: "center" }}>
          <div style={{ font: "normal normal normal 16px/24px Segoe UI" }}>
            <div style={{ display: "flex", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              <div>{t("RecicleBin.headerRestorePartA")}</div>
              <div style={{ fontWeight: 600, marginRight: "7px", marginLeft: "7px" }}>
                "{item.name + (item.extencion ? "." + item.extencion : "")}"
              </div>
              <div>{t("RecicleBin.headerRestorePartB")}</div>
            </div>
            <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{t("RecicleBin.headerRestorePartC")}</div>
          </div>
        </div>

        <div style={{ marginTop:"10px" }}>
          <div style={{display:"flex", alignItems:"center", border: "1px solid rgb(45, 45, 45, 0.2)", width:"100%", height:"44px", borderRadius:"4px", marginBottom:"16px"}} key={item.itemId}>
            <div style={{display:"flex", alignItems:"center", padding:"12px", height:"100%"}}>
              <WorkSpaceItemListFileComponentImage extension={item.extencion} isFolder={item.itemType === 1 ? true : false} />
            </div>
            <div>
              {item.name}{item.extencion ? "." + item.extencion : "" }
            </div>
          </div>
          {workspaceOptions &&
            <CustomDropdown
              label={"Workspace"}
              placeholder={t("RecicleBin.select")}
              options={workspaceOptions}
              onChange={handleWorkspace}
              selectedKey={workspaceIdSelected}
              iconSelected={<ConfirmIcon color='#22638D'/>}
              suffix={t("RecicleBin.defalt")}
            />
          }
        </div>

        <FolderPath 
          idWorkspace={workspaceIdSelected != "" ? workspaceIdSelected : item.workspaceId} 
          fatherFolder={item.folderId} 
          newFatherFolder={setNewFatherfolder}
        />

        <div style={{ width: '100%', display: 'flex', gap:"20px" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={confirmRestore}  
            text={t("RecicleBin.confirm")}
            backgrondColor='#22638D'
            color='#FFFFFF !important'
            hoveredBackgrondColor='#0d476d'
            isDisabled={workspaceIdSelected == ""}
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default RestoreConfirm;