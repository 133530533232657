interface ConfirmIconProps {
  color?: string;
}

export const ConfirmIcon: React.FC<ConfirmIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11.804" viewBox="0 0 16 11.804">
    <path id="done_FILL1_wght400_GRAD0_opsz24_3_" 
    data-name="done_FILL1_wght400_GRAD0_opsz24 (3)" 
    d="M159.6-709.2l-5.6-5.6,1.4-1.4,4.2,4.2L168.6-721l1.4,1.4Z" 
    transform="translate(-154 721)" 
    fill={color ? color : "#fff"}
    />
  </svg>
);
