import * as React from 'react';
import {
  INavLinkGroup,
  INavLink,
  Nav,
  IRenderFunction,
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { GetTreeByWorkspaceFolderIdAll } from '../../../services/Item/ItemServicesGet';
import { WorkSpaceItemRecursive } from '../../../model/ItemModel';
import { folderIcon } from '../../../assets/icons';
import LoaderComp from '../../../components/Loader/LoaderComp';
import { useTranslation } from 'react-i18next';
import { ConfirmIcon } from '../../../assets/icons/icons.tsx/ConfirmIcon';
import { FatherfolderProps } from './GridRestore/RestoreConfirm';

interface FolderPathProps {
	idWorkspace: string;
  fatherFolder?: string;
  newFatherFolder: (fatherFolder: FatherfolderProps) => void;
}

export const FolderPath: React.FunctionComponent<FolderPathProps> = ({idWorkspace, fatherFolder, newFatherFolder}) => {

  const [reload, setReload] = useState<boolean>(false);
  const [fatherId, setFatherId] = useState<string | undefined>(undefined);
	const [displayNav, setDisplayNav] = useState<boolean>(false);

	const [nav, setNav] = useState<INavLinkGroup[]>([]);

  const { t, i18n } = useTranslation();

	const renderMenuList: IRenderFunction<INavLink> = (
		menuListProps: INavLink | undefined,

		defaultRender: IRenderFunction<INavLink> | undefined,
	) => {
		if (menuListProps != undefined && defaultRender != undefined) {
			return (
      <div
        style={{ display: "flex", width:"100%", height: "38px", paddingLeft: "5px", paddingRight: "5px",}}
      >
        <img width={20} src={folderIcon} draggable={false} style={{ marginRight: '20px' }} />
  
        <div
          style={{
            color: "black",
  
            fontWeight: 600,
          }}
        >
          {menuListProps.name}
        </div>
        {fatherId === menuListProps.key &&
          <div style={{height:"100%", display:"flex", alignItems:"center", justifyContent:"end", width:"100%"}}>
            <ConfirmIcon color='#22638D'/>
          </div>
        }       
      </div>
			);
		} else {
			return null;
		}
	};

  useEffect(() => {

    setFatherId(fatherFolder)
		const arrayNav: INavLinkGroup[] = [];
		const newNav: INavLinkGroup = {
			links: [],
		};

		setReload(true)

		GetTreeByWorkspaceFolderIdAll(idWorkspace)
		.then((WorkSpaceItem) => {
			WorkSpaceItem.forEach((element) => {
				newNav.links.push(creatLink(element));
			});

			setDisplayNav(true);

			arrayNav.push(newNav);

			setNav(arrayNav);
		})
		.catch(()=>{})
		.finally(()=>{setReload(false)})

	}, [idWorkspace]);

  function creatLink(element: WorkSpaceItemRecursive) {
		const link: INavLink = {
			key: element.id,
			name: element.name!,		
			url: "",

      onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.stopPropagation();
        setFatherId((prevFatherId) => {
          if (element.id === prevFatherId) {
            newFatherFolder({ id: undefined, name: undefined });
            return undefined;
          } else {
            if (element.id) {
              newFatherFolder({ id: element.id, name: element.name! });
            }
            return element.id;
          }
        });
      },

			links: [],
		};

		if (element.children != undefined) {
			element.children.forEach((element) => {
				link.links?.push(creatLink(element));
			});
		}

		return link;
	}

  return (
    <div style={{ marginTop:"10px" }}>
      <div style={{color:"#2D2D2D", fontWeight:600, fontSize:"14px", marginBottom:"8px"}}>
        {t("RecicleBin.folder")}
      </div>
      <div style={{border:"1px solid rgb(45, 45, 45, 0.2)", width:"100%", height:"250px", borderRadius:"4px", overflowY: 'auto', scrollbarWidth:"thin"}}>
        {reload?(
			  <LoaderComp big={true}/>) :
        (<div>
          {displayNav && (
            <Nav
              groups={nav}
              onRenderLink={renderMenuList}
              styles={{
                linkText: {
                  color: "black",

                  selectors: { "&:hover": { color: "black" } },
                },

                link: {},
              }}
            />
          )}
        </div>)}
      </div>
    </div>
  );
};
