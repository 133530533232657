import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import notFound from '../../assets/icons/notFound.svg'
import newHome from '../../assets/icons/newHome.svg'
import { useTranslation } from 'react-i18next';

const PageNotFound: React.FC = () => {
    const { t, i18n } = useTranslation();
    const navegate = useNavigate();

    function home(){
        navegate("/");
    }
    
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
        <div>
            <div style={{display:"flex", justifyContent:"center", marginBottom:"30px"}}>
                <img src={notFound} alt="notFoundIcon" />
            </div>
            <div style={{display:"flex", justifyContent:"center", marginBottom:"8px", font:"normal normal 600 20px/24px Segoe UI"}}>{t("NotFoundPage.header")}</div>
            <div style={{marginBottom:"30px", color:"rgb(45, 45, 4, 0.6)", font:"normal normal normal 16px/24px Segoe UI"}}>{t("NotFoundPage.body")}</div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <Button style={{font: "normal normal normal 16px/21px Segoe UI", backgroundColor:"#22638D", color:"#FFFFFF", textTransform:"none", padding:"7px 20px 7px 20px"}} onClick={home}>
                    <img src={newHome} alt="homeIcon" style={{marginRight:"10px"}} />
                    {t("NotFoundPage.button")}
                </Button>
            </div>
        </div>
    </div>
  );
};

export default PageNotFound;
