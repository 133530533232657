import React, { useEffect, useState } from "react";
import {
  GetOneOrganization
} from "../../../services/OrganizationServices";
import { Organization } from "../../../model/OrganizationModel";
import {  UserDelete } from "../../../model/UserModel";
import { useParams  } from "react-router-dom";
import styles from "../../Style/List.module.css";
import { Stack } from '@fluentui/react/lib/Stack';
import { Panel } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import Btn  from "../../../components/Button/Btn";
import TextFieldComp  from "../../../components/TextField/TextFieldComp";
import { CreateUser, EditUser, DeleteUser } from "../../../services/UserServices"
import ListUserComp  from "./ListUserComp"
import UserConfig  from "./UserConfig"
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogFooter,
  DialogContent,
} from "@fluentui/react/lib/Dialog";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import { useUpload } from "../../../context/UploadProvider/useUpload";
import { PaywallModal } from "../../../components/PaywallModal/PaywallModal";
import { MaxUsersIcon } from "../../../assets/icons/MaxUsersIcon";

const ListUserOrg = (props: any) => {
  const [userEdit, setUserEdit] = useState<string|undefined|number>(0);
  const [userDeleted, setUserDeleted] = useState<string|undefined>(undefined);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const [searchName, setSearchName] = useState<string>("");

  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const { t, i18n } = useTranslation();
  const [reaload, setReaload] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const upload = useUpload();
  const [alertColor, setAlertColor] = useState<AlertColor>("success");

  const [usersQuantity, setUsersQuantity] = useState<number | undefined>(undefined);
  const [openPaywallModal, setOpenPaywallModal] = useState<boolean>(false)

  const { nameOrg } = useParams();

  useEffect(() => {
    if(nameOrg != undefined)
    {
      GetOneOrganization(nameOrg)
      .then((organization) => {
        if (organization != null) {
          setOrganization(organization);
        }
      }).finally(()=>{dismissPanel();});
    }
  }, [nameOrg]);

  useEffect(() => {
    if(typeof userEdit != "number")
    {
      openPanel();
    }
  }, [userEdit]);

  useEffect(() => {
    if(userDeleted != undefined)
    {
      toggleHideDialog();
    }
  }, [userDeleted]);

  const iconProps = { iconName: 'Filter' };

  const onClickEdit = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleEdit(myValue);
  };

  const onClickDelete = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleDelete(myValue);
  };

  const createUser = (
  ) => {
    if (organization && organization.contractStatus == 3 && usersQuantity == organization?.contractUsersResourceQuantity){
      setOpenPaywallModal(true);
    }else{
      toggleEdit(undefined);
    }
  };

  const toggleEdit = (
    value:string|undefined
  ) => {
    if(value ==  userEdit)
    {
      openPanel();
    }
    else
    {
      setUserEdit(value);
    }
  };

  const toggleDelete = (
    value:string|undefined
  ) => {
    if(value ==  userDeleted)
    {
      if(userDeleted != undefined)
      {
        toggleHideDialog();
      }
    }
    else
    {
      setUserDeleted(value);
    }
  };

  const onChangeSearchName =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  }; 

  function deleteUserPopup() {
    if (userDeleted != null) {

      var userDelete:UserDelete ={
        id: userDeleted,
        organizationName: nameOrg
      };

      DeleteUser(userDelete)
      .then(() => {
        setAlertColor("success");
        setMessage(t("UserExcluido.message"));    
        setPopupOk(true);
        upload.setShowEx(false);
        setReaload(true);
      })
      .catch((error) => {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setMessage(error.response.data );           
        }
        else
        {
          setMessage(t("UserExcluido.error"));
        }
        setAlertColor("error");
        setPopupOk(true);
      });
    }
    toggleHideDialog();
  }

  function closePopUpOk() {
    setPopupOk(false);
  }

  const updatePanelSave = () => {
    updatePanel();
    setPopupOk(true);
    setMessage(t("UsuarioSalvo.message"));
    setAlertColor("success");
    upload.setShowEx(false);
  };

  const updatePanel = () => {
    setReaload(true);
    dismissPanel();
  };

  useEffect(() => {
    if(reaload == true ){
      setReaload(false)
    }
  }, [reaload]);

  const handleClose = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  return (
    <div>
      <Stack  horizontal className={styles.hPage}>
        <Stack.Item grow>
          <h3 className={styles.hList}>{t("Usuarios.message")}</h3>
        </Stack.Item>
        <Stack className={styles.stackSide}>
          <Stack.Item  className={styles.BtnNew}>
              <Btn text={t("Novo.message")} onClick={createUser}/>
          </Stack.Item>
          <Stack.Item className={styles.InputSearch}>
              <TextFieldComp iconProps={iconProps} value={searchName} onChange={onChangeSearchName}/>
          </Stack.Item>
        </Stack>
      </Stack>
      {reaload?(<div></div>):(
        nameOrg != undefined ? (
          <ListUserComp 
            nameOrg={nameOrg} 
            name={searchName} 
            onClickEditar={onClickEdit} 
            onClickExcluir={onClickDelete} 
            setUsersQuantity={setUsersQuantity} 
            setAlert={setPopupOk} 
            setMessage={setMessage} 
            setAlertColor={setAlertColor} 
          />
          ):(
          <li className={styles.itemContent}></li>
        ))}
       <Panel
        isOpen={isOpen}
        closeButtonAriaLabel={t("Fechar.message")}
        headerText={t("SalvarUsuario.message")}
        onDismiss={updatePanel}
        isFooterAtBottom={true}
      >
        {nameOrg!= undefined && typeof userEdit != "number"?
          (
            <UserConfig nameOrg={nameOrg} idUser={userEdit} saveOkMetod={updatePanelSave} setMessage={setMessage} setAlertColor={setAlertColor}/>
          ):(null)}
      </Panel>

      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
          <DialogContent title={t("Excluir.message")}>
            <h3>{t("ExluirUsuarioValid.message")}?</h3>
          </DialogContent>
          <DialogFooter>
            <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
            <Btn onClick={deleteUserPopup} text={t("Excluir.message")} />
          </DialogFooter>
        </Dialog>

      <Snackbar
          open={popupOk}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
            {message}
          </MuiAlert>
        </Snackbar>

      <PaywallModal open={openPaywallModal} setOpen={setOpenPaywallModal} icon={<MaxUsersIcon />} subtitleStart={t('Paywall.usersStart')} subtitleBold={t('Paywall.usersBold')} subtitleEnd={t('Paywall.usersEnd') } />
    </div>
  );
};

export default ListUserOrg;
