import {WorkSpaceItemRevision, WorkSpaceUserName } from "../../../../model/ItemModel";
import {ChoicerViwerItem } from "../ChoicerViwerModal";

export function ConvertWorkspaceToChoicerViwer(list:WorkSpaceUserName[])
{
    const arrayReturn: ChoicerViwerItem[] = [];
    for (let i = 0; i < list.length; i++) {
        arrayReturn.push({
            id: list[i]?.id!,
            name: list[i]?.name!,
            extension: list[i]?.extension!,
            itemType: list[i]?.itemType!,
            revisionId: ""
        });
    }

    return arrayReturn;
}

export function ConvertRevisionToChoicerViwer(list: WorkSpaceItemRevision[]) 
{
    const arrayReturn: ChoicerViwerItem[] = [];
    for (let i = 0; i < list.length; i++) {
        arrayReturn.push({
            id: list[i]?.itemId!,
            name: list[i]?.itemName!,
            extension: list[i]?.itemExtension!,
            itemType: "",
            revisionId: list[i]?.id!
        });
    }

    return arrayReturn;  
}