interface CancelIconProps {
  color?: string;
}

export const CancelIcon: React.FC<CancelIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path 
      id="close_FILL1_wght400_GRAD0_opsz24_7_" 
      data-name="close_FILL1_wght400_GRAD0_opsz24 (7)" 
      d="M201.2-748l-1.2-1.2,4.8-4.8-4.8-4.8,1.2-1.2,4.8,4.8,4.8-4.8,1.2,1.2-4.8,4.8,4.8,4.8-1.2,1.2-4.8-4.8Z" 
      transform="translate(-200 760)" 
      fill={color ? color : "#22638d"}
    />
  </svg>
);