import React from 'react';
import { CustomModal } from '../../components/CustomModal';
import recycleBinCheckHeader from "../../../../assets/icons/recycleBinCheckHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { DeletedItem } from '../../../../model/ItemModel';
import SelectedList from '../../components/SelectedList';

interface RestoreSuccessListProps {
  listItem: DeletedItem[],
  isConfirmed: boolean
  hideModal: () => void
  restoreType?: number
}

const RestoreSuccessList: React.FC<RestoreSuccessListProps> = ({listItem, isConfirmed, hideModal, restoreType}) => {
  const { t, i18n } = useTranslation();

  function confirmDelete(){
    hideModal()
  }

  function goToFile(itemId: string){
    window.open(("/file/" + itemId), '_blank')
  }

  return (
    <div>
      <CustomModal 
        isModalOpen={isConfirmed}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinCheckHeader} alt='check'/>
        </div>

        {restoreType ==  1 ? 
          (
            <>
              <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
                <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
                  {t("Substituição e restauração realizadas com sucesso!")}
                </div>
              </div>
              <div style={{width: "100%", textAlign:"center"}}>
                <div>
                  {t("RecicleBin.headerRestoreSuccessListType1PartA")}
                </div>
                <div>
                  {t("RecicleBin.headerRestoreSuccessListType1PartB")}
                </div>
              </div>
            </>
          ): restoreType == 2 ? (
            <>        
              <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
                <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
                  {t("Arquivos ignorados e restauração realizada com sucesso!")}
                </div>
              </div>
              <div style={{width: "100%", textAlign: "center"}}>
                <div>
                  {t("RecicleBin.headerRestoreSuccessListType2PartA")}
                </div>
                <div>
                  {t("RecicleBin.headerRestoreSuccessListType2PartB")}
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
                <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
                  {t("Itens atualizados com sucesso!")}
                </div>
              </div>
              <div style={{width: "100%", textAlign:"center"}}>
                <div>
                  {t("RecicleBin.headerRestoreSuccessListType3PartA")}
                </div>
                <div>
                  {t("RecicleBin.headerRestoreSuccessListType3PartB")}
                </div>
              </div>
            </>
          )
        }

        <SelectedList listItem={listItem} onclick={goToFile}/>

        <div style={{ width: '100%', display: 'flex' }}>
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={confirmDelete}  
            text={t("RecicleBin.finishAndClose")}
            backgrondColor='#22638D'
            color='#FFFFFF'
            hoveredBackgrondColor='#0d476d'
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default RestoreSuccessList;