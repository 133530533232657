import React, { useState, useEffect } from "react";
import { ItemToValidationRuleVO, ItemToValidationRuleVOConditions, ValidationTarget } from "../../../model/ValidationRuleModel";
import { useTranslation } from "react-i18next";
import { 
  CreateUserPendencie,
  ValidationPrecondition,
  GetUserAlowed,
  GetUserAlowedByName} from "../../../services/ValidationRuleService";
import { ItemValidationRule, WorkSpaceUserName } from "../../../model/ItemModel";
import MuiAlert from '@mui/material/Alert';
import ChoiceTheValidationRule from "../ValidationRuleList/ValidationRuleChoice/ChoiceTheValidationRule";
import HandleValidation from "../ValidationRuleList/ValidationRuleChoice/HandleValidation";
import { Panel } from "@fluentui/react";
import PanelGenericText from "../../../components/PanelGenericText/PanelGenericText"
import { Box } from "@mui/material";
import LoaderComp from '../../../components/Loader/LoaderComp';
import ValidationRuleConclusionPanel from "../SendToValidationRulePanel/ValidationRuleConclusionPanel/ValidationRuleConclusionPanel";


interface ValidationRuleStartPanelTypes {
  idWorkspace: string;
  idMongo:string;
  setWaitLoader: React.Dispatch<React.SetStateAction<boolean>>
  waitLoader: boolean;
  setWorkspace: ((workSpaceUserName: WorkSpaceUserName, index: number, isUpload: boolean) => void);
  isUpload: boolean;
  indexOfArray: number;
  isOpen: boolean;
  dismissPanel: () => void;
  actionForwadOrBackWait?: boolean;
}

const ValidationRuleStartPanel: React.ForwardRefExoticComponent<ValidationRuleStartPanelTypes> = React.forwardRef(({
  idWorkspace,
  idMongo,
  setWaitLoader,
  waitLoader,
  setWorkspace,
  isUpload,
  indexOfArray,
  isOpen,
  dismissPanel
} , ref) => {


  const { t } = useTranslation();
    const [validationRuleChoiceResponsabilityHide, setValidationRuleChoiceResponsabilityHide] = useState<boolean>(false);
    const [validationRuleTermHide, setValidationRuleTermHide] = useState<boolean>(false);
    const [newPendece, setNewPendece] =  useState<ItemToValidationRuleVO|undefined>(undefined);
    const [conditions, setConditions] = useState<ItemToValidationRuleVOConditions | undefined>(undefined);
    const [erroMessage, setErroMessage] = useState<string|undefined>(undefined);

    const [waitPrecondition, setWaitPrecondition] = useState<boolean>(false);
    const [errorPreConditions, setErrorPreConditions] = useState<string | undefined>(undefined);
    const [concludedStep, setConcludedStep] = useState<boolean>(false);
    const [target, setTarget] = useState<ValidationTarget | undefined>(undefined);
    const [workSpaceUserNameResponse, setWorkSpaceUserNameResponse] = useState<WorkSpaceUserName | undefined>(undefined);

  
    function setConditionsInternal(conditionsItem: ItemToValidationRuleVOConditions |undefined)
    {
        setConditions(conditionsItem)
    }

  
    function dismissPanelInternal()
    {
      if(workSpaceUserNameResponse != undefined)
      {
        setWorkspace(workSpaceUserNameResponse, indexOfArray, isUpload);
        dismissPanel();
        setErroMessage(undefined);
      }
    }

    useEffect(() => {
      if(newPendece == undefined)
      {
        setConditions(undefined);
      }
      else if(newPendece != undefined && conditions == undefined)
      {
        CreateUserPendencieVerifyInternal(newPendece);
      }
      else if(newPendece != undefined && conditions != undefined)
      {
        newPendece.conditions = conditions;
        CreateUserPendencieInternal(newPendece);
      }
    }, [newPendece,conditions]);
    
    function CreateUserPendencieVerifyExternal(newPendecia: ItemToValidationRuleVO){
      setNewPendece(newPendecia);  
    }

    function RemoveUserPendencieVerifyExternal(){
      setNewPendece(undefined);  
    }

    function CreateUserPendencieVerifyInternal(newPendecia: ItemToValidationRuleVO){ 
      setWaitPrecondition(true);
      setErrorPreConditions(undefined);
      ValidationPrecondition(newPendecia)
      .then((response) =>{
          if(response.dinamicUserChoice !== undefined || response.timeDeadline !== undefined)
          {
            if(response.dinamicUserChoice && response.timeDeadline)
            {
              setValidationRuleChoiceResponsabilityHide(false);
              setValidationRuleTermHide(false)
            }
            else if (!response.dinamicUserChoice && !response.timeDeadline) {
              setValidationRuleChoiceResponsabilityHide(true);
              setValidationRuleTermHide(true)
            }
            else if (!response.dinamicUserChoice && response.timeDeadline) {
              setValidationRuleChoiceResponsabilityHide(true);
              setValidationRuleTermHide(false)
            }
            else if (response.dinamicUserChoice && !response.timeDeadline) {
              setValidationRuleChoiceResponsabilityHide(false);
              setValidationRuleTermHide(true)
            }
          }
          else
          {
            CreateUserPendencieInternal(newPendecia);
          }     
      })
      .catch((errorValid) =>{
          if( errorValid.response && errorValid.response.data && typeof errorValid.response.data  === 'string'){
            setErrorPreConditions(t(errorValid.response.data));
          }
          else
          {
            setErrorPreConditions(t("ErroAoSalvar.message"));
          }
      })   
      .finally(() => {
        setWaitPrecondition(false)
      })
  }

  function CreateUserPendencieInternal(newPendecia: ItemToValidationRuleVO)
  {
    setWaitLoader(true);
    CreateUserPendencie(newPendecia)
    .then((idResponse) => {
        setValidationRuleChoiceResponsabilityHide(true);
        setValidationRuleTermHide(true);
        setNewPendece(undefined);
        setConditions(undefined);
        setTarget(idResponse.target)
        setConcludedStep(true);
        setWorkSpaceUserNameResponse(idResponse.itemReturnVOName);
    })
    .catch((error) => {
      if( error.response && error.response.data && typeof error.response.data  === 'string'){
        setErroMessage(t(error.response.data));
      }
      else
      {
        setErroMessage( t("ErroAoSalvar.message"));
      }

    })
    .finally(() => {
      setWaitLoader(false);
    })
  }

  function setError(errorMessage: string | undefined) {
    setErroMessage(errorMessage);
  }

  return (
        <Panel
          isOpen={isOpen}
          onDismiss={dismissPanel}
          styles={{
            main: {
              width: "630px !important",
              padding: '50px',
            },
          }}
          closeButtonAriaLabel="Close"
          
          >
        {concludedStep && workSpaceUserNameResponse != undefined ? (
          <ValidationRuleConclusionPanel 
          dismissPanel={dismissPanelInternal}
          target={target}
          errorMessage={erroMessage}
          />
          ) : concludedStep ?(<LoaderComp />): (
            <>
            <PanelGenericText
              text={t("validationRulePanel.title")}
              paragraph={t("validationRulePanel.select")}
            />
            <ChoiceTheValidationRule
              workSpaceId={idWorkspace}
              idMongo={idMongo}
              CreateUserPendencieExternal={CreateUserPendencieVerifyExternal}
              RemoveUserPendencieVerifyExternal={RemoveUserPendencieVerifyExternal}
              setError={setError}
            />

            {(newPendece != undefined && waitPrecondition == false && errorPreConditions == undefined )&& (
              <div>
                <HandleValidation 
                  getUser={GetUserAlowed}
                  getUserFiltred={GetUserAlowedByName}
                  filterAlowed={idMongo}
                  setConditions={setConditionsInternal}
                  validationRuleChoiceResponsabilityHide={validationRuleChoiceResponsabilityHide!}
                  validationRuleTermHide={validationRuleTermHide!}
                  waitLoader={waitLoader!}
                  errorExternal={erroMessage}
                />
              </div>
            )}
          </>
        )}

        {waitPrecondition && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px"
            }}
          >
            <LoaderComp />
          </Box>
        )}

        {errorPreConditions != undefined && (
          <MuiAlert elevation={6} variant="filled" sx={{ width: '90%', margin:"5%" }} severity="error">
          {errorPreConditions}
        </MuiAlert>
        )}
      </Panel>
    
  )
})

export default ValidationRuleStartPanel;