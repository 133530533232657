import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoaderComp from "../../components/Loader/LoaderComp";
import { DownloadViwer, GetOneItem } from "../../services/Item/ItemServicesGet";
import PageNotFound from "../WorkSpaceItem/PageNotFound";
import ChoicerViwer from "../WorkSpaceItem/Viwer/ChoicerViwer";

const LinkToFilePage = (props: any) => {
    const { t } = useTranslation();
    const { idFile } = useParams();
    const [name, setName] = useState<string | undefined>(undefined);
    const [extension, setExtension] = useState<string | undefined>(undefined);
    
    const [error, setError] = useState<string | undefined>(undefined);
    const [wait, setWait] = useState<boolean>(false);

    const nameOrgItem = useRef<string>();

    useEffect(() => {
        if (idFile != undefined) {
            setWait(true);
            GetOneItem(idFile)
                .then((result) => {
                    setName(result.name);
                    setExtension(result.extension);
                })
                .catch((erro) => {
                    setError(t("WithoutPermission.message"));
                })
                .finally(() => {
                    setWait(false);
                });
        }
        else {

        }
    }, []);
    
    return (
        <div style={{height:"100%"}}>
            <div>
                {idFile != undefined && (
                    wait ?
                        (
                            <LoaderComp big={true} />
                        ) : error == undefined &&
                        (
                            <ChoicerViwer
                                idItem={idFile}
                                download={DownloadViwer}
                                getItem={GetOneItem}
                                positionAbsolute={true}
                                title={`${name}.${extension}`}
                                isFilePage={true}
                                fullScreenSetted={false}
                                isAcept={false}
                                isSharedFilePage={false}
                            />
                        )
                )}
            </div>
            {idFile == undefined || error != undefined && (
                <div style={{height:"100%"}}>
                    {error != undefined ? <PageNotFound/> : t("ItemIdNotFound.message")}
                </div>
            )}
        </div>
    );
};

export default LinkToFilePage;
