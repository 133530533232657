interface FilterButtonProps {
  color?: string;
}

export const FilterButtonIcon: React.FC<FilterButtonProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.051" height="20" viewBox="0 0 20.051 20">
    <path 
      id="filter_alt_24dp_FILL1_wght400_GRAD0_opsz24" 
      d="M167.952-780a1.209,1.209,0,0,1-.891-.359,1.21,1.21,0,0,1-.359-.891v-7.5l-7.25-9.25a1.19,1.19,0,0,1-.141-1.312,1.162,1.162,0,0,1,1.141-.687h17.5a1.162,1.162,0,0,1,1.141.688,1.19,1.19,0,0,1-.141,1.313l-7.25,9.25v7.5a1.21,1.21,0,0,1-.359.891,1.209,1.209,0,0,1-.891.359Z" 
      transform="translate(-159.176 800)" 
      fill={color ? color : "#2d2d2d"}
      opacity="0.8"
    />
  </svg>
);