import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DownloadAgent } from '../../assets/icons/DownloadAgent';
import { CloseButton } from '../../assets/icons/CloseButton';
import { DownloadButton } from '../../assets/icons/DownloadButton';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style: SxProps<Theme> | undefined = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 272,
  background: '#FCFCFC 0% 0% no-repeat padding-box',
  borderRadius: '10px',
  boxShadow: 24,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:focus-visible': {
    outline: 'none',
  },
};

const titleStyle: React.CSSProperties | undefined = {
  font: 'normal normal 600 20px/39px Segoe UI',
  letterSpacing: '0px',
  color: '#2D2D2D',
}

const nameAppTitleStyle: React.CSSProperties | undefined = {
  font: 'normal normal bold 20px/39px Segoe UI',
  letterSpacing: '0px',
  color: '#2D2D2D',
}

const descriptionStyle: SxProps<Theme> | undefined = {
  font: 'normal normal normal 16px/24px Segoe UI',
  letterSpacing: '0px',
  color: '#575757',
  textAlign: 'center',
  marginTop: '-24px',
}

const nameAppDescriptionStyle: React.CSSProperties | undefined = {
  font: 'normal normal 600 16px/24px Segoe UI',
  letterSpacing: '0px',
  color: '#575757',
}

const divButtonStyle: SxProps<Theme> | undefined = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
}

const cancelButtonStyle: SxProps<Theme> | undefined = {
  width: '260px',
  gap: '8px',
  '&:hover': {
    backgroundColor: '#0D476D',
    '& > span': {
      color: '#FCFCFC !important',
    },
    '& > svg > path': {
      fill: '#FCFCFC !important',
    },
  },
}

const downloadButtonStyle: SxProps<Theme> | undefined = {
  width: '260px',
  gap: '8px',
  '&:hover': {
    backgroundColor: '#0D476D',
  },
}

const cancelTextStyle: React.CSSProperties | undefined = {
  font: 'normal normal normal 16px/21px Segoe UI',
  letterSpacing: '0px',
  color: '#22638D',
  textTransform: 'none',
}

const downloadTextStyle: React.CSSProperties | undefined = {
  font: 'normal normal normal 16px/21px Segoe UI',
  letterSpacing: '0px',
  color: '#FFFFFF',
  textTransform: 'none',
}

export interface DownloadAgentModalProps {
  open: boolean;
  url: string;
  handleClose: () => void;
}

export default function DownloadAgentModal({ open, url, handleClose }: DownloadAgentModalProps) {
  const { t } = useTranslation();

  const downloadExecutable = () => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'keydrivelocaleditsetup.exe');
    link.click()
    link.parentNode?.removeChild(link);
    handleClose();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DownloadAgent />
          <Typography id="modal-modal-title" variant="h6" component="h2" style={titleStyle}>
            {t("DownloadAgentModal.haveThe")} <span style={nameAppTitleStyle}>KeyDriveLocalEdit</span> {t("DownloadAgentModal.onYourComputer")}
          </Typography>
          <Typography id="modal-modal-description" sx={descriptionStyle}>
            {t("DownloadAgentModal.toViewTheSelectedItemOnYourComputerClickTheButtonBelowToDownloadAndInstallThe")} <span style={nameAppDescriptionStyle}>KeyDriveLocalEdit</span>
          </Typography>
          <Box sx={divButtonStyle}>
            <Button variant="outlined" sx={cancelButtonStyle} onClick={handleClose}>
              <CloseButton />
              <Typography variant='button' style={cancelTextStyle}>
                {t("DownloadAgentModal.cancel")}
              </Typography>
            </Button>
            <Button variant="contained" sx={downloadButtonStyle} onClick={downloadExecutable}>
              <DownloadButton />
              <Typography variant='button' style={downloadTextStyle}>
                {t("DownloadAgentModal.downloadKeyDriveLocalEdit")}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}