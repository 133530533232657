import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckIcon } from "../../../assets/icons/icons.tsx/CheckIcon";
import qrCodeError from "../../../assets/icons/qrCodeError.svg";
import LoaderComp from "../../../components/Loader/LoaderComp";
import { IUser } from "../../../context/AuthProvider/types";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { GetUniqueLinkSearchParams, LoginRequestMFA } from "../../../context/AuthProvider/util";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { putMfaKey } from "../../../services/IdentitiServices";
import { RedirectNavigate } from "../../PublicLayout/RedirectNavigate";
import BoxMfa from "./components/BoxMfa";
import OTPInput from "./components/OTPInput";

export default function MfaOTP() {

    const auth = useAuth();
    const profile = useProfile();
    const { t } = useTranslation();
    const { email } = useParams();
    const navigate = useNavigate();

    const [otpCode, setOtpCode] = useState<string>("")
    const [load, setLoad] = useState<boolean>(false);
    const uniqueLink = GetUniqueLinkSearchParams();
    const [error, setError] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>();

    useEffect(()=>{
      setIsDisabled(otpCode.length < 6);
      setError(false)
    },[otpCode])
    
    const handleNavigateLogin = () => {
      navigate(`/login${uniqueLink != undefined ? "?link=" + uniqueLink : '' }`);
    }

    function setProfile(token:string)
    {
      profile
        .create(token)
        .then(() => {
          setLoad(false);
          let user: IUser = {
            token:token
          };
          auth.setUserExternal(user);
          if (uniqueLink != undefined){
            RedirectNavigate(navigate, uniqueLink)
          }
          else {
            RedirectNavigate(navigate)
          }
        })
        .catch(() => {
          auth.logout();
          handleNavigateLogin()
          setLoad(false);
        });
    }

    function verifyOtp(otpCode: string) {
      if (email != undefined) {
        setLoad(true)
        LoginRequestMFA(otpCode, email)
        .then(data => {
          setProfile(data.data.access_token)
        })
        .catch(() => { 
          setError(true)
        })
        .finally(() => setLoad(false))
      }
    } 
    
    const handleMfaKey = () => {
      putMfaKey(email!)
      .finally(() => {
        navigate(`/login${uniqueLink != undefined ? "?link=" + uniqueLink : ''}`)
      })
  }

  return (
    <BoxMfa
      header={t("MfaOTP.header")}
      width="480px"
    >
      <Grid item xs={12} marginBottom={'14px'}>
        <OTPInput onComplete={setOtpCode} error={error}/>
      </Grid>
      <Box
        sx={{
          width:"100%",
          display:"flex",
          justifyContent:"center"
        }}
      >
        <Button 
          onClick={handleMfaKey}
          disableRipple 
          disableFocusRipple
          sx={{
            maxWidth:"230px",
            height:"36px",
            textTransform:"none",
            font:"normal normal normal 16px/21px Segoe UI",
            color:"#22638D",
            marginBottom: "30px",
            flex: 1,
            '&:hover': {
              backgroundColor: 'rgb(34, 99, 141, 0.1)',
            },
          }}
        >
          <img src={qrCodeError} alt="QRCodeError" style={{marginRight:"10px"}} />
          {t("MfaOTP.buttonNotQRCode")}
        </Button>
      </Box>

      { load ? (
          <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems:"center",
              marginTop:"20px"
          }}>
              <LoaderComp />
          </Box>
      ) : (
          <Button 
              onClick={()=>verifyOtp(otpCode)}
              disabled={isDisabled}
              sx={{
                  '&.Mui-disabled': {
                      backgroundColor: "rgb(34, 99, 141, 0.2)",
                      color: "#FFFFFF",
                  },                               
                  '&:hover': {
                      backgroundColor: '#1E4A6F',
                      color: '#FFFFFF',
                  },
                  width:"100%",
                  height:"36px",
                  backgroundColor:"#22638D", 
                  color:"#FFFFFF",
                  textTransform:"none"
              }}
          >
              <CheckIcon/>  
              <Typography 
                  sx={{
                      font:"normal normal normal 16px/21px Segoe UI",
                      marginLeft:"10px"
                  }}
              >
                {t("Continuar.message")} 
              </Typography>
          </Button>
      )}
    </BoxMfa>
  )
}