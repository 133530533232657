import React from 'react';
import { CustomModal } from '../../components/CustomModal';
import recycleBinCheckHeader from "../../../../assets/icons/recycleBinCheckHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { DeletedItem } from '../../../../model/ItemModel';
import DeleteList from '../../components/SelectedList';

interface DeletedSuccessListProps {
  listItem: DeletedItem[],
  isConfirmed: boolean
  hideModal: () => void
}

const DeletedSuccessList: React.FC<DeletedSuccessListProps> = ({listItem, isConfirmed, hideModal}) => {
  const { t, i18n } = useTranslation();

  function confirmDelete(){
    hideModal()
  }

  return (
    <div>
      <CustomModal 
        isModalOpen={isConfirmed}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinCheckHeader} alt='check'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.deletedSuccessTitle")}
          </div>
        </div>
        <div style={{width: "100%", display:"flex", justifyContent: "center"}}>
          {t("RecicleBin.deletedSuccessHeader")}
        </div>

        <DeleteList listItem={listItem}/>

        <div style={{ width: '100%', display: 'flex' }}>
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={confirmDelete}  
            text={t("RecicleBin.finishAndClose")}
            backgrondColor='#22638D'
            color='#FFFFFF'
            hoveredBackgrondColor='#0d476d'
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default DeletedSuccessList;