import { Button } from '@mui/material';
import * as React from 'react';

interface CustomButtonProps {
  onclick: () => void;
  setIsHovered?: (value: boolean) => void;
  icon: React.ReactElement<any, any>;
  text: string;
  color?: string;
  backgrondColor?: string;
  hoveredColor?: string;
  hoveredBackgrondColor?: string;
  isDisabled?: boolean;
  menuRight?: boolean;
}

export const CustomButton: React.FunctionComponent<CustomButtonProps> = ({onclick, setIsHovered, icon: Icon, text, color, backgrondColor, hoveredColor, hoveredBackgrondColor, isDisabled, menuRight}) => {
  return (
    <Button 
      onClick={onclick}
      disabled={isDisabled}
      sx={{
        cursor: "pointer",
        marginTop: "30px",
        width: "100%",
        height: "36px",
        borderRadius: menuRight ? "4px 0px 0px 4px" : "4px",
        border: "1px solid #22638D",
        font:"normal normal normal 16px/21px Segoe UI",
        backgroundColor: backgrondColor,
        opacity: isDisabled ? 0.2 : 1,
        color: color,
        textTransform: "none",
        '&:hover': {
          backgroundColor: hoveredBackgrondColor,
          color: hoveredColor,
          borderColor: hoveredBackgrondColor,
        },
      }}
      onMouseEnter={() => setIsHovered ? setIsHovered(true) : undefined}
      onMouseLeave={() => setIsHovered ? setIsHovered(false) : undefined}
    >
      {React.cloneElement(Icon)}
      <div style={{marginLeft:"10px"}}>
        {text}
      </div> 
    </Button>
  );
};


