import React, { useState, useEffect, useCallback, useRef } from "react";
import { ITextFieldStyles } from "@fluentui/react/lib/TextField";
import { Link } from "@fluentui/react/lib/Link";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { DocumentTypeCreate } from "../../model/DocumentType";
import DocumentTypeListComp from "../../pages/DocumentType/DocumentTypeListComp"
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from '../../context/ProfileProvider/useProfile';
import Btn from "../../components/Button/Btn";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import { Stack } from "@fluentui/react/lib/Stack";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "../Style/List.module.css";
import { useTranslation } from "react-i18next";
import { useUpload } from "../../context/UploadProvider/useUpload";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { 
    DeleteDocumentType
} from "../../services/DocumentType";

import {
    Dialog,
    DialogType,
    DialogFooter,
    DialogContent,
  } from "@fluentui/react/lib/Dialog";

const DocumentTypesList  = (props: any) => {
  const [DocumentType, setDocumentType] = useState<DocumentTypeCreate[]>(new Array<DocumentTypeCreate>());
  const navegate = useNavigate();

  const [newOrgName, setNewOrgName] = useState<string>();
  const forceUpdate = useCallback((teste: string) => setNewOrgName(teste), []);
  const { t, i18n } = useTranslation();

  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");
  const [idDocumentTypeDelete, setIdDocumentTypesDelete] = useState<string|undefined|number>(0);
  const [documentTypeEdit, setDocumentTypeEditEdit] = useState<string|undefined|number>(0)
  const profile = useProfile();
  const [isAdminOrganization, setisAdminOrganization] = useState<boolean>(false);

  const { nameOrg } = useParams();
  const { idWorkspace } = useParams();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const iconProps = { iconName: "Filter" };

  useEffect(() => {
    if(typeof idDocumentTypeDelete != "number")
    {
      toggleHideDialog();
    }
  }, [idDocumentTypeDelete]);

  useEffect(() => {
    if(reload == true ){
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          if(organization.isAdmin == true)
          {
            setisAdminOrganization(true);
          }
          else{
            setisAdminOrganization(false);
          }
        }
      }
    }
  }, [profile]);

  function closePopUpOk() {
    setPopupOk(false);
  }

  const handleClose = (event:any, reason:any) => {
    closePopUpOk();
  }

  const handleClosePO = (event:any) => {
    closePopUpOk();
  }

  const onClickNew = () => {
    navegate("/" + nameOrg + "/Workspace/" + idWorkspace + "/documetTypes/new");
  };
  
  

  const toggleDelete = (
    value:string|undefined
  ) => {
    if(value ==  idDocumentTypeDelete)
    {
      if(idDocumentTypeDelete != undefined)
      {
        toggleHideDialog();
      }
    }
    else
    {
        setIdDocumentTypesDelete(value!);
    }
  };
  const onClickDelete = (
    event: React.FormEvent<HTMLElement>
  ) => {
    const { myValue } = event.currentTarget.dataset;
    toggleDelete(myValue);
  };


  function deleteDocumentTypePopup() {
    if(idDocumentTypeDelete != undefined && typeof idDocumentTypeDelete != "number")
    {
      //setChecked(false);
      DeleteDocumentType(idDocumentTypeDelete)
      .then(() => {
        setMessage(t("DocumenType.deleteSucess"));
        setPopupOk(true);
        setReload(true);
      });
    }
    toggleHideDialog();
  }
  const onChangeSearchName =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  };

const updatePanelSave = () => {
    setPopupOk(true);
    setMessage(t("Workspace.createSucess"));
  };
  return (
    <div>
      <Stack horizontal className={styles.hPage}>   
        <Stack.Item grow>
          <h3 className={styles.hList}>Tipos de Documentos</h3>
        </Stack.Item>     
        <Stack className={styles.stackSide}>
          <Stack.Item className={styles.BtnNew}>
            <Btn text={t("Novo.message")} onClick={onClickNew} hidden={isAdminOrganization == false} />
          </Stack.Item>
          <Stack.Item className={styles.InputSearch}>
            <TextFieldComp iconProps={iconProps}  value={searchName} onChange={onChangeSearchName} />
          </Stack.Item>
        </Stack>
      </Stack>
      
      {reload?(<div></div>):(
          idWorkspace != undefined?(
            <DocumentTypeListComp
              workSpaceId={idWorkspace}
              name={searchName}
              nameOrg={nameOrg}
              onClickExcluir={onClickDelete}
            />
          ):(<li className={styles.itemContent}></li>))}

          
      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
          <DialogContent title={t("Excluir.message")}>
            <h3>{t("CertezaExcluirDocumentType.message")}</h3>
          </DialogContent>
          <DialogFooter>
            <Btn onClick={toggleHideDialog} text={t("Cancelar.message")} />
            <Btn onClick={deleteDocumentTypePopup} text={t("Excluir.message")}/>
          </DialogFooter>
        </Dialog>
        <Snackbar
          open={popupOk}
          autoHideDuration={6000}
          message="Archived"
          onClose={handleClose}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          sx={{ bottom: { xs: 50, sm: 20 } }}
        >
          <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity="success" onClose={handleClosePO}>
            {message}
          </MuiAlert>
        </Snackbar>
    </div>
  );
};


export default DocumentTypesList;
