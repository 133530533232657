import React, { useEffect, useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import compare from "../../../../assets/icons/compare.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import CardComponent from './components/CardComponent';
import { ItemDeletedIdWithType } from '../../RecycleBinList';
import LoaderComp from '../../../../components/Loader/LoaderComp';

interface ItemState {
  item: DeletedItem;
  isSelected: boolean;
}

interface ItemMatched {
  itemDeleted: ItemState;
  matchedItem?: ItemState;
}

interface CompareFilesListProps {
  isOpenModal: boolean;
  hideModal: () => void;
  listItemsDeleted: DeletedItem[];
  listItems: DeletedItem[];
  restoreListItems: (ListItemId: ItemDeletedIdWithType[]) => void;
  destiny?: string
}

const CompareFilesList: React.FC<CompareFilesListProps> = ({listItemsDeleted, listItems, restoreListItems, isOpenModal, hideModal, destiny}) => {
  const { t, i18n } = useTranslation();
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);
  const [listItemsSameName, setListItemsSameName] = useState<ItemMatched[] | null>(null);
  const [itemsDeletedWithType, setItemsDeletedWithType] = useState<ItemDeletedIdWithType[]>([]);
  const [load, setLoad] = useState<boolean>(false)

  const handleChangeItemsDeleted = (itemId: string) => {
    setListItemsSameName(prevState => {
      if (!prevState) return prevState;

      return prevState.map(item => {
        if (item.itemDeleted.item.itemId === itemId) {
          return {
            ...item,
            itemDeleted: {
              ...item.itemDeleted,
              isSelected: !item.itemDeleted.isSelected
            }
          };
        }
        return item;
      });
    });
  };

  const handleChangeMatchedItem = (itemId: string) => {
    setListItemsSameName(prevState => {
      if (!prevState) return prevState;

      return prevState.map(item => {
        if (item.matchedItem?.item.itemId === itemId) {
          return {
            ...item,
            matchedItem: {
              ...item.matchedItem,
              isSelected: !item.matchedItem.isSelected
            }
          };
        }
        return item;
      });
    });
  };

  useEffect(()=>{
    if (listItemsSameName) {
      const newItemsDeletedWithType = listItemsSameName.map(({ itemDeleted, matchedItem }) => {
        let selectedType = 0;

        if (itemDeleted.isSelected && matchedItem?.isSelected) {
          selectedType = 3;
        } else if (itemDeleted.isSelected && !matchedItem?.isSelected) {
          selectedType = 1;
        } else if (!itemDeleted.isSelected && matchedItem?.isSelected) {
          selectedType = 2;
        }

        return {
          deleteditemId: itemDeleted.item.itemId,
          matchedItemId: matchedItem?.item.itemId,
          restoreType: selectedType,
        };
      });

      setItemsDeletedWithType(newItemsDeletedWithType);
    }
  },[listItemsSameName])

  function confirmRestore() {
    setLoad(true)

    restoreListItems(itemsDeletedWithType);
  }

  useEffect(()=>{
    if(!isOpenModal){
      setListItemsSameName(null)
      setItemsDeletedWithType([])
    }
  },[isOpenModal])

  useEffect(() => {
    const itemsFilter: ItemState[] = listItems.map(item => ({ item, isSelected: false }));
    const itemsDeletedFilter: ItemState[] = listItemsDeleted.map(item => ({ item, isSelected: false }));

    const itemsSameName: ItemMatched[] = itemsDeletedFilter.map(itemDeleted => {
      const matchedItem = itemsFilter.find(item => item.item.name === itemDeleted.item.name);
      return {
        itemDeleted,
        matchedItem,
      };
    }).filter(item => item.matchedItem !== undefined);

    if (itemsSameName.length > 0) {
      setListItemsSameName(itemsSameName);
    }
  }, [listItems, listItemsDeleted]);

  return (
    <div>
      <CustomModal 
        isModalOpen={isOpenModal}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={compare} alt='Compare'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.compareFilesTitle")}
          </div>
        </div>
        <div style={{width: "100%", display:"flex", justifyContent: "center"}}>
          <div style={{width:"540px", textAlign:"center"}}>
            {t("RecicleBin.headerCompareFiles")}         
          </div>
        </div>

        <div style={{borderTop:"1px solid rgb(45, 45, 45, 0.2)", width:"100%", marginTop:"20px", marginBottom:"30px"}}></div>

        <div style={{display:"flex", width:"100%", gap:"20px", marginBottom:"8px"}}>
            <div style={{width:"50%", display:"flex", gap:"7px"}}>
              {t("RecicleBin.compareFilesTitleList")}  
              <div style={{color:"#22638D"}}>
                {t("RecicleBin.title")}
              </div>
            </div>
            <div style={{width:"50%", display:"flex", gap:"7px"}}>
              {t("RecicleBin.compareFilesTitleList")}  
              <div style={{
                color:"#22638D", 
                width:"194px", 
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
                }}
              >
                {destiny}
              </div>
            </div>
        </div>

        {listItemsSameName?.map(item => (
          <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"12px"}}>
            <CardComponent
              createdDate={item.itemDeleted.item.createdDate}
              extencion={item.itemDeleted.item.extencion}
              itemType={item.itemDeleted.item.itemType}
              handleChange={handleChangeItemsDeleted}
              isSelected={item.itemDeleted.isSelected}
              name={item.itemDeleted.item.name}
              size={item.itemDeleted.item.size}
              itemId={item.itemDeleted.item.itemId}
            />
            <CardComponent
              createdDate={item.matchedItem?.item.createdDate}
              extencion={item.matchedItem?.item.extencion}
              itemType={item.matchedItem?.item.itemType}
              handleChange={handleChangeMatchedItem}
              isSelected={item.matchedItem?.isSelected}
              name={item.matchedItem?.item.name}
              size={item.matchedItem?.item.size}
              itemId={item.matchedItem?.item.itemId}
            />
          </div>
        ))}

        <div style={{borderTop:"1px solid rgb(45, 45, 45, 0.2)", width:"100%", marginTop:"30px"}}></div>

        <div style={{ width: '100%', display: 'flex', gap:"20px", alignItems:"end" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          {load ? (
            <div style={{width:"100%", height:"36px", display:"flex", justifyContent:"center", alignItems:"end", marginBottom:"-10px"}}>
              <LoaderComp/>
            </div>
            ):(
            <CustomButton 
              icon={<ConfirmIcon/>} 
              onclick={confirmRestore}  
              text={t("RecicleBin.confirm")}
              backgrondColor='#22638D'
              color='#FFFFFF !important'
              hoveredBackgrondColor='#0d476d'
              isDisabled={itemsDeletedWithType.some(item => item.restoreType == 0 || item.restoreType == undefined)}
            />
          )}
        </div>

      </CustomModal>
    </div>
  );
};

export default CompareFilesList;