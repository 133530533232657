import { Tooltip } from "@mui/material";
import { ReactElement } from "react";

interface CustomTooltipProps {
    children: ReactElement;
    text: string;
    backgroundColor: string;
    marginLeft?: string;
  }
  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, text, backgroundColor, marginLeft}) => {
    return (
        <Tooltip  
            title={text}
            slotProps={{
            popper:{
                sx:{
                marginLeft: marginLeft
                }
            }, 
            tooltip:{
                sx:{
                backgroundColor: backgroundColor, 
                marginTop:"2px !important", 
                font:"normal normal normal 16px Segoe UI" 
                }
            }
            }}
        >
            {children}
      </Tooltip>
    )
  }

  export default CustomTooltip