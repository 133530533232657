import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { WorkSpaceItemListFoldeComponentConfig } from './WorkSpaceItemListFolderComponent';
import { ConstrictIcon } from '../../assets/icons/icons.tsx/ConstrictIcon';
import { NormalExpandIcon } from '../../assets/icons/icons.tsx/NormalExpandIcon';
import { WorkSpaceItemRecursive } from '../../model/ItemModel';

interface TreeComponentProps {
    items: WorkSpaceItemRecursive[];
    selectedFolderId: string | undefined;
    expandedLinks: Record<string, boolean>;
    toggleExpand: (id: string) => void;
    idWorkspace: string;
    setPopupError: (ok: boolean, message: string) => void;
    setPopupSuccess: (ok: boolean, message: string) => void;
    onDrop: ((event: React.DragEvent<HTMLDivElement>, key: string | undefined, endFunction: () => void) => void) | undefined;
    clickFunction: (id: string, name: string) => void;
    setSelectedFolderId: (id: string) => void;
    treeItemComponent: ComponentType<WorkSpaceItemListFoldeComponentConfig>;
    setWidth: (value: number) => void;
}

const TreeComponent: React.FC<TreeComponentProps> = (props: TreeComponentProps) => {
    const treeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (treeRef.current) {
            props.setWidth(treeRef.current.scrollWidth);
        }
    }, [props.items, props.expandedLinks]);

    const createTree = (items: WorkSpaceItemRecursive[], countFather?: number): JSX.Element => {
        let count = countFather === undefined ? 0 : countFather + 1;
        return (
            <ul className="scroll__folder">
                {items.map(item => (
                    <li key={item.id}>
                        <div style={{
                            display: "flex",
                            marginBottom: "5px",
                            whiteSpace: "nowrap",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            paddingLeft: 10 + (countFather === undefined ? 0 : countFather + 1) * 10,
                            backgroundColor: props.selectedFolderId === item.id ? "#dde4f2" : "transparent"
                        }}>
                            <div style={{ width: "30px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                {item.children && item.children.length > 0 ? (
                                    <button
                                        style={{ cursor: "pointer", border: "none", background: "none", width: "30px" }}
                                        onClick={() => props.toggleExpand(item.id!)}
                                    >
                                        {props.expandedLinks[item.id!] ? <ConstrictIcon /> : <NormalExpandIcon />}
                                    </button>
                                ) : (
                                    <div style={{ width: "30px" }}></div>
                                )}
                            </div>

                            <props.treeItemComponent
                                idWorkspace={props.idWorkspace}
                                menuListProps={item}
                                setPopupError={props.setPopupError}
                                setPopupSucess={props.setPopupSuccess}
                                onDrop={props.onDrop}
                                clickFunction={props.clickFunction}
                                setSelectedFolderId={props.setSelectedFolderId}
                            />
                        </div>
                        {item.children && props.expandedLinks[item.id!] && createTree(item.children, count)}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div ref={treeRef}>
            {createTree(props.items)}
        </div>
    );
};

export default TreeComponent;
