import {
    mergeStyleSets,
  } from '@fluentui/react';
import React, { useEffect, useRef, useState } from "react";
import PreviewThumbnail from './PreviewThumbnail';
import { DownloadThumbnail } from '../../../../services/Item/ItemServicesGet';
import { previewListingStyles } from './PreviewStyles';
import {ChoicerViwerItem} from "../ChoicerViwerModal"

export interface PreviewWorkspaceItemProps {
    currentItemRef: React.RefObject<HTMLDivElement> | null
    icon: JSX.Element;
    isCurrentItem: boolean;
    item: ChoicerViwerItem;
    changeModalContent: (id: string, name: string, extension: string) => void;
    ref: React.Ref<HTMLDivElement>;
}

const PreviewWorkspaceItem: React.ForwardRefExoticComponent<PreviewWorkspaceItemProps> = 
    React.forwardRef(({
        currentItemRef,
        item,
        icon,
        isCurrentItem,
        changeModalContent,
    }, ref: React.Ref<HTMLDivElement>) => {
        const [isThumbnailShow, setIsThumbnailShow] = useState(false);
        const [previewPosition, setPreviewPosition] = useState<DOMRect>();
        const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(undefined);
        const [isPortrait, setIsPortrait] = useState<boolean | undefined>(undefined)

        const divRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
          if(isThumbnailShow){
              if(thumbnailUrl === undefined){
                  if(item.id && item.extension) fetchThumbnail(item.id)
              }
          }
        }, [isThumbnailShow]) 
    
        const fetchThumbnail = (idItem: string) => {
            DownloadThumbnail(idItem)
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob], {type: 'application/jpeg'}),
                );

                verifyIsPortrait(url)
                setThumbnailUrl(url);
            }).catch(() => {
                setThumbnailUrl("");
            })
        }

        const verifyIsPortrait = (url: string) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                if(img.height > img.width){
                    setIsPortrait(true)
                } else {
                    setIsPortrait(false)
                }
            };
        }

        const openThumbnailPreview = () => {
            if(divRef.current){
                const boundingClientRect = divRef.current.getBoundingClientRect();
                setPreviewPosition(boundingClientRect)
            }
            if(!isThumbnailShow) setIsThumbnailShow(true)
        }

        const closeThumbnailPreview = () => {
            if(isThumbnailShow) setIsThumbnailShow(false)
        }

        return(
            <div key={item.id} 
                ref={divRef} 
                className={isCurrentItem == false ? previewListingStyles.containerItem : `${previewListingStyles.containerItem} ${previewListingStyles.currentContent}`} 
                onMouseEnter={openThumbnailPreview}
                onMouseLeave={closeThumbnailPreview}
                onClick={() => changeModalContent(item.id!, item.name!, item.extension!)}
            >
                <div className={previewListingStyles.divFlex}>
                    <div ref={ref} className={previewListingStyles.divItem}>
                        <div>{icon}</div>
                        <div ref={currentItemRef} className={previewListingStyles.listName}>
                            {item.name}.{item.extension}
                        </div>
                    </div>
                    
                    {(isThumbnailShow && previewPosition) && 
                        <PreviewThumbnail 
                            icon={icon}
                            isPortrait={isPortrait}
                            item={item} 
                            previewPosition={previewPosition}
                            url={thumbnailUrl} 
                            changeModalContent={changeModalContent} 
                            fetchThumbnail={fetchThumbnail}
                        />
                    }
                </div>
            </div>
        )
    }
)

export default PreviewWorkspaceItem;