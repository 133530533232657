import { Box, TextField } from "@mui/material";
import PasswordVisibilityIcon from "../../../../../../assets/images/passwordVisibilityIcon.svg"
import PasswordVisibilityOffIcon from "../../../../../../assets/icons/visibilityOffPassword.svg"
import { useState } from "react";


interface IPasswordTextFieldWithReveal{
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string;
    isChecked?: boolean;
    setCheckedState?: (value: React.SetStateAction<boolean>) => void
    label?: string;
    placeholder?: string;
    passwordBorderColor?: string;
    disable?: boolean;
    right?: number;
    top?: number;
    bottom?: number;
    marginX?: string
    marginY?: string
};

export function PasswordTextFieldWithRevealMui({
    onChange, value, setCheckedState, isChecked, label, placeholder, passwordBorderColor, disable, right, top,bottom, marginX, marginY
}: IPasswordTextFieldWithReveal) {

    const [visiblePassword, setVisiblePassword] = useState<{ icon: string, type: "password" | "text" }>({ icon: PasswordVisibilityIcon, type: "password" })

    function toggleVisbilePassword() {
        if (value.length > 0) {
            setVisiblePassword(prevState => ({
                type: prevState.type === "password" ? "text" : "password",
                icon: prevState.icon === PasswordVisibilityIcon ? PasswordVisibilityOffIcon : PasswordVisibilityIcon
            }));
        }
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <TextField
                variant="standard"
                onChange={onChange}
                value={value}
                label={label ? label : undefined}
                placeholder={placeholder ? placeholder : undefined}
                type={visiblePassword.type}
                sx={{
                    width:"100%",
                    '&:hover .MuiInputBase-root::before': {
                        borderBottom: '1px solid rgb(45, 45, 45, 0.2)',
                    },
                    '& .MuiInputBase-root::after': {
                        borderBottom: '1px solid rgb(45, 45, 45, 0.2)',
                    },
                  }}
                  inputProps={{ 
                    style: { 
                      font: "normal normal normal 14px/21px Segoe UI"  
                    } 
                }}
                onFocus={() => {
                    if (isChecked) {
                        setCheckedState!(false)
                    }
                }}
                disabled={disable}
            />
            <Box component={'img'} src={visiblePassword.icon}
                sx={{
                    position: 'absolute',
                    right: right? right: '',
                    top: top ? top :  '',
                    bottom: bottom ? bottom :  '',

                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 1
                    }
                }}
                onClick={() => toggleVisbilePassword()}
            />
        </Box>
    )
}