import React from 'react';
import Radio from '@mui/material/Radio';
import WorkSpaceDeleteListFileComponentImage from '../../../../WorkSpaceItem/WorkSpaceItemListFileComponentImage';
import moment from 'moment';

interface CardComponentProps {
    itemId?: string
    extencion?: string;
    name?: string;
    itemType?: number;
    size?: number;
    createdDate?: Date;
    isSelected?: boolean;
    handleChange: (itemId: string) => void;
}
  
const CardComponent: React.FC<CardComponentProps> = ({ itemId, extencion, name, itemType, size, createdDate, isSelected, handleChange }) => {

    function formatSize(sizeInBytes: number) {
        if (sizeInBytes >= 1024 ** 4) {
          const sizeInTB = sizeInBytes / (1024 ** 4);
          return `(${sizeInTB.toFixed(2)} TB)`;
        } else if (sizeInBytes >= 1024 ** 3) {
          const sizeInGB = sizeInBytes / (1024 ** 3);
          return `(${sizeInGB.toFixed(2)} GB)`;
        } else if (sizeInBytes >= 1024 ** 2) {
          const sizeInMB = sizeInBytes / (1024 ** 2);
          return `(${sizeInMB.toFixed(2)} MB)`;
        } else if (sizeInBytes >= 1024) {
          const sizeInKB = sizeInBytes / 1024;
          return `(${sizeInKB.toFixed(2)} KB)`;
        }
        return `(${sizeInBytes} Bytes)`;
    }

  return (
    <div style={{border: "1px solid rgba(45, 45, 45, 0.2)", minWidth: "260px", height: "56px", borderRadius: "4px", display: "flex", fontSize: "12px"}}>
        <div style={{width: "44px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(45, 45, 45, 0.05)"}}>
            <WorkSpaceDeleteListFileComponentImage 
            extension={extencion} 
            isFolder={itemType === 1} 
            />
        </div>
        <div style={{width: "100%", padding: "8px"}}>
          <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
            <div style={{
              width: "174px",  
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
              }}
            >
              {name}
            </div>
          <Radio
              checked={isSelected}
              onClick={(e)=>handleChange(itemId!)}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
              sx={{
              padding: '0px',
              '& .MuiSvgIcon-root': {
                  width: '20px',
                  height: '20px',
              }
              }}
          />
          </div>
          <div style={{display: "flex", color: "rgba(45, 45, 45, 0.2)"}}>
              <div>
                  {extencion ? "." + extencion : ""}
              </div>
              <div style={{margin: "0px 7px"}}>
                  {formatSize(size ? size : 0)}
              </div>
              <div>
                  {moment(createdDate).format('DD/MM/YYYY HH:mm')}
              </div>
          </div>
        </div>
    </div>
  );
};

export default CardComponent;
