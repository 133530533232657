import React, { useState } from "react";
import { WorkSpaceItemRecursive } from "../../model/ItemModel";
import styles from "./WorkSpaceItemList.module.css";
import { useTranslation } from "react-i18next";
import { useUpload } from "../../context/UploadProvider/useUpload";
import { folderIcon } from '../../assets/icons'

export interface WorkSpaceItemListFoldeComponentConfig {
	idWorkspace: string;
	setPopupSucess: (ok: boolean, message: string) => void;
	setPopupError: (ok: boolean, message: string) => void;
	menuListProps: WorkSpaceItemRecursive;
	onDrop?: (
		event: React.DragEvent<HTMLDivElement>,
		key: string | undefined,
		endFunction: () => void,
	) => void;
	clickFunction: (id: string, name: string) => void; 
	setSelectedFolderId: (id: string) => void
}

const WorkSpaceItemListFolderComponent: React.FC<WorkSpaceItemListFoldeComponentConfig> = ({
	menuListProps,
	onDrop,
	clickFunction,
	setSelectedFolderId
}) => {
	const { t, i18n } = useTranslation();

	const [isHover, setisHover] = useState<boolean>(false);

	const upload = useUpload();

	function getUpload() {
		return upload;
	}

	const preventDefaultBehaviour = (event: React.DragEvent<HTMLDivElement>) => {
		event.stopPropagation();

		event.preventDefault();
	};

	const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
		preventDefaultBehaviour(event);

		setisHover(true);
	};

	const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		preventDefaultBehaviour(event);

		setisHover(false);
	};

	function leave() {
		setisHover(false);
	}

	return (
		<div
			style={{ display: "flex", height: "38px", marginLeft: "10px", paddingRight: "5px", alignItems: "center", cursor: "pointer"}}
			onDragEnter={(e) => onDragEnterAndOver(e)}
			onDragLeave={(e) => onDragLeave(e)}
			onDragOver={(e) => onDragEnterAndOver(e)}
			onDrop={(e) =>  onDrop!= undefined && onDrop(e, menuListProps.id, leave)}
			className={isHover ? styles.hoverDrop : undefined}
			onClick={() => {clickFunction(menuListProps.id!, menuListProps.name!); setSelectedFolderId(menuListProps.id!);
			}}
		>
			<img width={20} src={folderIcon} draggable={false} style={{ marginRight: '10px' }} />

			<div
				style={{
					color: "black",
					fontWeight: 600,
				}}
			>
				{menuListProps.name}
			</div>
		</div>
	);
};

export default WorkSpaceItemListFolderComponent;

