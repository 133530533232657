import React, { useEffect, useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import compare from "../../../../assets/icons/compare.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import CardComponent from './components/CardComponent';
import { ItemDeletedIdWithType } from '../../RecycleBinList';
import LoaderComp from '../../../../components/Loader/LoaderComp';

interface ItemState {
  item: DeletedItem;
  isSelected: boolean;
}

interface ItemMatched {
  itemDeleted: ItemState;
  matchedItem?: ItemState;
}

interface CompareFilesProps {
  isOpenModal: boolean;
  hideModal: () => void;
  itemDeleted: DeletedItem;
  item: DeletedItem;
  restoreItem: (itemId: ItemDeletedIdWithType) => void;
  destiny?: string
}

const CompareFiles: React.FC<CompareFilesProps> = ({itemDeleted, item, restoreItem, isOpenModal, hideModal, destiny}) => {
  const { t, i18n } = useTranslation();
  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);
  const [itemSameName, setItemSameName] = useState<ItemMatched | null>(null);
  const [itemDeletedWithType, setItemDeletedWithType] = useState<ItemDeletedIdWithType>();
  const [load, setLoad] = useState<boolean>(false)

  const handleChangeItemsDeleted = (itemId: string) => {
    setItemSameName(prevState => {
      if (!prevState) return prevState;
      
      if (prevState.itemDeleted.item.itemId === itemId) {
        return {
          ...prevState,
          itemDeleted: {
            ...prevState.itemDeleted,
            isSelected: !prevState.itemDeleted.isSelected
          }
        };
      }
  
      return prevState;
    });
  };

  const handleChangeMatchedItem = (itemId: string) => {
    setItemSameName(prevState => {
      if (!prevState) return prevState;
  
      if (prevState.matchedItem?.item.itemId === itemId) {
        return {
          ...prevState,
          matchedItem: {
            ...prevState.matchedItem,
            isSelected: !prevState.matchedItem.isSelected
          }
        };
      }
  
      return prevState;
    });
  };

  useEffect(() => {
    if (itemSameName) {
      const { itemDeleted, matchedItem } = itemSameName;
      let selectedType = 0;
  
      if (itemDeleted.isSelected && matchedItem?.isSelected) {
        selectedType = 3;
      } else if (itemDeleted.isSelected && !matchedItem?.isSelected) {
        selectedType = 1;
      } else if (!itemDeleted.isSelected && matchedItem?.isSelected) {
        selectedType = 2;
      }
  
      const newItemDeletedWithType = {
        deleteditemId: itemDeleted.item.itemId,
        matchedItemId: matchedItem?.item.itemId,
        restoreType: selectedType,
      };

      setItemDeletedWithType(newItemDeletedWithType);
    }
  }, [itemSameName]);
  

  function confirmRestore() {
    setLoad(true)

    if(itemDeletedWithType){
      restoreItem(itemDeletedWithType);
    }
  }

  useEffect(()=>{
    if(!isOpenModal){
      setItemSameName(null)
      setItemDeletedWithType(undefined)
    }
  },[isOpenModal])

  useEffect(() => {
    const itemFilter: ItemState = { item, isSelected: false };
    const itemDeletedFilter: ItemState = { item: itemDeleted, isSelected: false };
  
    let matchedItem: ItemMatched | null = null;
  
    if (itemDeletedFilter.item.name === itemFilter.item.name) {
      matchedItem = {
        itemDeleted: itemDeletedFilter,
        matchedItem: itemFilter,
      };
    }
  
    if (matchedItem) {
      setItemSameName(matchedItem);
    }
  }, [item, itemDeleted]);

  return (
    <div>
      <CustomModal 
        isModalOpen={isOpenModal}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={compare} alt='Compare'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.compareFilesTitle")}
          </div>
        </div>
        <div style={{width: "100%", display:"flex", justifyContent: "center"}}>
          <div style={{width:"540px", textAlign:"center"}}>
            {t("RecicleBin.headerCompareFiles")}         
          </div>
        </div>

        <div style={{borderTop:"1px solid rgb(45, 45, 45, 0.2)", width:"100%", marginTop:"20px", marginBottom:"30px"}}></div>

        <div style={{display:"flex", width:"100%", gap:"20px", marginBottom:"8px"}}>
            <div style={{width:"50%", display:"flex", gap:"7px"}}>
              {t("RecicleBin.compareFilesTitleList")}  
              <div style={{color:"#22638D"}}>
                {t("RecicleBin.title")}
              </div>
            </div>
            <div style={{width:"50%", display:"flex", gap:"7px"}}>
              {t("RecicleBin.compareFilesTitleList")}  
              <div style={{
                color:"#22638D", 
                width:"194px", 
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
                }}
              >
                {destiny}
              </div>
            </div>
        </div>

        {itemSameName &&
          <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"12px"}}>
            <CardComponent
              createdDate={itemSameName.itemDeleted.item.createdDate}
              extencion={itemSameName.itemDeleted.item.extencion}
              itemType={itemSameName.itemDeleted.item.itemType}
              handleChange={handleChangeItemsDeleted}
              isSelected={itemSameName.itemDeleted.isSelected}
              name={itemSameName.itemDeleted.item.name}
              size={itemSameName.itemDeleted.item.size}
              itemId={itemSameName.itemDeleted.item.itemId}
            />
            <CardComponent
              createdDate={itemSameName.matchedItem?.item.createdDate}
              extencion={itemSameName.matchedItem?.item.extencion}
              itemType={itemSameName.matchedItem?.item.itemType}
              handleChange={handleChangeMatchedItem}
              isSelected={itemSameName.matchedItem?.isSelected}
              name={itemSameName.matchedItem?.item.name}
              size={itemSameName.matchedItem?.item.size}
              itemId={itemSameName.matchedItem?.item.itemId}
            />
          </div>
        }

        <div style={{borderTop:"1px solid rgb(45, 45, 45, 0.2)", width:"100%", marginTop:"30px"}}></div>

        <div style={{ width: '100%', display: 'flex', gap:"20px", alignItems:"end" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          {load ? (
            <div style={{width:"100%", height:"36px", display:"flex", justifyContent:"center", alignItems:"end", marginBottom:"-10px"}}>
              <LoaderComp/>
            </div>
            ):(
            <CustomButton 
              icon={<ConfirmIcon/>} 
              onclick={confirmRestore}  
              text={t("RecicleBin.confirm")}
              backgrondColor='#22638D'
              color='#FFFFFF !important'
              hoveredBackgrondColor='#0d476d'
              isDisabled={itemDeletedWithType ? (itemDeletedWithType.restoreType == 0 || itemDeletedWithType.restoreType == undefined) : true}
            />
          )}
        </div>

      </CustomModal>
    </div>
  );
};

export default CompareFiles;