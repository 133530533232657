import { Box, Button, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import qrCode from '../../../../assets/icons/qrCode.svg';
import arrowBack from '../../../../assets/icons/arrowBack.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface BoxMfaProps {
  header: string;
  children: ReactNode;
  width: string;
}

const BoxMfa: React.FC<BoxMfaProps> = ({ header, children, width }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateLogin = () => {
        navigate(`/login`);
    }
  return (
    <Box sx={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#ffff'}}>
        <Box 
            sx={{
                width: width
            }}
        >
            <Box             
                sx={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center",
                    marginBottom:"20px"
                }}
            >
                <Box             
                    sx={{
                        width: "80px",
                        height:"80px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        borderRadius:"50%",
                        backgroundColor:"rgb(45, 45, 45, 0.05)"
                    }}
                >
                    <img src={qrCode} alt="QRCode" />
                </Box>
            </Box>

            <Typography
                sx={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center",
                    font:"normal normal 600 32px/53px Segoe UI",
                    color:"#2D2D2D",
                    marginBottom:"10px"
                }}
            >
                {t("BoxMfa.title")}
            </Typography>
            
            <Typography
                sx={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center",
                    font:"normal normal normal 16px/24px Segoe UI",
                    color:"rgb(45, 45, 45, 0.6)",
                    marginBottom:"30px",
                    textAlign:"center"
                }}
            >
                {header}
            </Typography>
            {children}
            <Box 
                sx={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center",
                    marginTop:"30px"
                }}
            >
                <Button 
                    onClick={navigateLogin}
                    disableRipple 
                    disableFocusRipple
                    sx={{
                        background:"none !important",
                        textTransform:"none",
                        font:"normal normal 600 18px/27px Segoe UI",
                        color:"#22638D"
                    }}
                >
                    <img src={arrowBack} alt="arrowBack" style={{marginRight:"10px"}} />
                    {t("BoxMfa.button")}
                </Button>
            </Box>           
        </Box>
    </Box>
  );
};

export default BoxMfa;
