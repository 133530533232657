
interface ArrowRightIconProps {
  color?: string;
}

export const ArrowRightIcon: React.FC<ArrowRightIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
    <path 
      id="keyboard_backspace_24dp_E8EAED_FILL1_wght400_GRAD0_opsz24" 
      d="M132-708l6-6-6-6-1.4,1.4,3.6,3.6H120v2h14.2l-3.6,3.6Z" 
      transform="translate(-120 720)" 
      fill={color ? color : "#22638d"}
    />
  </svg>
);