import React, {createContext, useEffect, useState} from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IAuthProvider } from '../AuthProvider/types';
import { UserProfile, IContextProfile } from "../../model/UserModel";

import {  GetActualUser } from "../../services/UserServices";
import { useAuth } from "../AuthProvider/useAuth"

export const ProfileContext = createContext<IContextProfile>({} as IContextProfile);


export const ProfileProvider = ({ children }: IAuthProvider) => {
    const[user, setUser] = useState<UserProfile|null>(null);

    const auth = useAuth();

    async function create (token : string|undefined) {
        if(token == undefined)
            throw console.error("Sem token");

        const response = await GetUser(token);
        if(response != null)
        {
            setUser(response);
        }else
        {
            throw console.error("Erro no profile");
        }
    };

    function remove() {
        setUser(null); 
    };

    return (
        <ProfileContext.Provider value={{...user, create, remove}}>
            {children}
        </ProfileContext.Provider>
    );
}

export async function GetUser(token: string){
    return GetActualUser(token)
        .then((userProfile) => {
            if(userProfile != undefined)
            {
                return userProfile;
            }
            else
            {
                return null;
            }
        })
        .catch((error) => {
            return null;
        });
}