import React, { useEffect, useState, useRef, useCallback } from "react";
// import axios from 'axios';
import { User } from "../../../model/OrganizationModel";
import {
  GetOrganizationUser,
  GetOrganizationUserTrim, GetOrganizationUserTrimName
} from "../../../services/OrganizationServices";
import { Link, MessageBarType } from "@fluentui/react";
import stylesList from "../../Style/List.module.css";
import { useNavigate } from "react-router-dom";
import LongMenu from  "../../../components/MenuContexto/MenuContexto";
import { useTranslation } from "react-i18next";
import LoaderComp from '../../../components/Loader/LoaderComp';
import { AlertColor } from "@mui/material";
export interface ListUserCompConfig {
  skip: number,
  id: string | undefined
}

function useVisibility(cb: (isVisible: boolean) => void, deps: React.DependencyList): (node: any) => void {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  return useCallback(node => {

    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    intersectionObserver.current = new IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    });

    if (node) intersectionObserver.current.observe(node);
  }, deps);
}

interface UserItemProps {
  id: string;
  nameOrg: string;
  name?: string;
  ref?: React.Ref<HTMLLIElement>;
}

interface ListUserCompId {
  nameOrg: string;
  name: string;
  onClickEditar?: React.MouseEventHandler<HTMLElement> | undefined;
  onClickExcluir?: React.MouseEventHandler<HTMLElement> | undefined;
  setUsersQuantity: React.Dispatch<React.SetStateAction<number | undefined>>
  setAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setAlertColor: React.Dispatch<React.SetStateAction<AlertColor>>;
}

const ListUserComp: React.FC<ListUserCompId> = ({ nameOrg, name, onClickEditar, onClickExcluir, setUsersQuantity, setAlert, setMessage, setAlertColor }) => {
  const [user, setUsers] = useState<User[]>([]);
  const [wait, setWait] = useState<boolean>(false);
  let [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  let [userMax, setUserMax] = useState<boolean>(true)
  const { t, i18n } = useTranslation();

  const PeopleItem: React.ForwardRefExoticComponent<UserItemProps> =
  React.forwardRef(({ id, nameOrg, name }, ref: React.Ref<HTMLLIElement>) => {
    const navegate = useNavigate();
    return (
      <li className={`${stylesList.itemContent} ${stylesList.row}`} key={id} ref={ref}>
        {name}{" "}    
        <LongMenu onClickEditar={onClickEditar} onClickExcluir={onClickExcluir} idMenu={id}/>  
      </li>
    );
  });

  const lastBook = useVisibility(visible => {
    if (visible) {
      setWait(true);
      if(name == "")
      {
        GetOrganizationUserTrim(nameOrg, skip, limit).then((newUser) => {
          if(newUser.length < limit) {
            setUserMax (false);
          }
          setSkip(skip + newUser.length);
          setUsers([...user, ...newUser]);
          setWait(false);
        });
      }
      else
      {
        GetOrganizationUserTrimName(nameOrg, skip, limit, name).then((newUser) => {
          if(newUser.length < limit) {
            setUserMax (false);
          }
          setSkip(skip + newUser.length);
          setUsers([...user, ...newUser]);
          setWait(false);
        });
      }
    }
  }, [skip, user]);

  useEffect(() => {
    setSkip(0);
    setUsers([]);
    setUserMax(true);
    setWait(true);
    if(name == "")
    {
      GetOrganizationUserTrim(nameOrg, 0, limit).then((newUser) => {
        if (newUser.length < limit) {
          setUserMax(false);
        }
        setSkip(newUser.length);
        setUsers(newUser);
        setWait(false);
      });
    }
    else
    {
      GetOrganizationUserTrimName(nameOrg, 0, limit, name).then((newUser) => {
        if (newUser.length < limit) {
          setUserMax(false);
        }
        setSkip(newUser.length);
        setUsers(newUser);
        setWait(false);
      });
    }

    GetOrganizationUser(nameOrg).then((orgUsers) => {
      if(orgUsers && orgUsers.length > 0){
        setUsersQuantity(orgUsers.length);
      }
    }).catch((error) => {
      setAlert(true);
      setMessage(t("Paywall.error"));
      setAlertColor('warning')
    });
  }, [nameOrg, name]);

  return (
    <div>
      <ul className={stylesList.ulContent}>
      <li className={`${stylesList.itemContent} ${stylesList.title}`}>
          <>{t("Nome.username")}</>{" "}
        </li>
        {user.map(userC => (
          <PeopleItem
            key={userC.id}
            id={userC.id}
            nameOrg={nameOrg}
            name={userC.name}
            ref={user[user.length - 1].id === userC.id && userMax ? lastBook : null}
          />
        ))}
      </ul>
      {wait && (<LoaderComp big={true}/>)}
    </div>
  );
}

export default ListUserComp;