import { Api } from "../api";
import AxiosRequestConfig from 'axios';
import {
    WorkSpaceItemCreate,
    FolderInformation,
    NewFolder ,
    SetFolow,
    RenameItem,
    Owner,
    WorkspaceItemMove,
    WorkspaceItemMultiMove,
    ItemDescription,
    Archiving,
    BatchDownloadModel,
    FisicItemInformation,
    BatchChangeStatusModel,
    TemporalityVO,
    CopyFolderStructureVO,
    DeletedItem
} from "../../model/ItemModel";

import { ObjectPermission, ObjectPermissionUser } from "../../model/PermissionModel";
import {IdModel} from "../../model/IdModel"
import { TagName,TagsVO } from "../../model/Tags";
import { ItemDeletedIdWithType } from "../../pages/RecicleBin/RecycleBinList";

export async function DeletePermanentItem(itemId: string) {
  const request = await Api.put("item/permanentDelete/", {itemId});

  return request.data;
}


export async function DeletePermanentListItems(listItemId: string[]) {
  const request = await Api.put("item/permanentDeleteListItems", {listItemId});

  return request.data;
}


export async function RestoreItem(itemIdWithType: ItemDeletedIdWithType, workspaceId: string, fatherId?: string) {
  const request = await Api.put("item/restore", {itemIdWithType, workspaceId, fatherId});

  return request.data;
}


export async function RestoreListItems(listItemIdWithType: ItemDeletedIdWithType[], workspaceId: string, fatherId?: string) {
  const request = await Api.put("item/restoreListItems", {listItemIdWithType, workspaceId, fatherId});

  return request.data;
}

export async function CheckItemSameName(itemId: string, workspaceId: string, fatherId?: string) {
  const request = await Api.post<DeletedItem>("item/checkItemSameName", {itemId, workspaceId, fatherId});

  return request.data;
}

export async function ChecksItemsSameName(listItemId: string[], workspaceId: string, fatherId?: string) {
  const request = await Api.post<DeletedItem[]>("item/checksItemsSameName", {listItemId, workspaceId, fatherId});

  return request.data;
}

export async function CreateWorkSpaceItem(WorkspaceId: WorkSpaceItemCreate) {
    const request = await Api.post<any>("item/file", WorkspaceId);

    return request.data;
}


export async function UpdateFisicPosition (fisicItemInformation: FisicItemInformation) {
  const request = await Api.put<FisicItemInformation>("item/FisicPosition/", fisicItemInformation);

  return request.data;
}

export async function GetFisicPosition (itemId: string) {
  const request = await Api.get<FisicItemInformation>(`item/FisicPosition/${itemId}`);

  return request.data;
}


export async function Rename(renameItem: RenameItem) {
    const request = await Api.put<any>("item/rename/",renameItem);

    return request.data;
}

export async function Move(WorkSpaceItem: WorkspaceItemMove) {
  const request = await Api.put<any>("item/move/",WorkSpaceItem);

  return request.data;
}

export async function MoveList(WorkSpaceItem: WorkspaceItemMultiMove) {
  const request = await Api.put<any>("item/moveList/",WorkSpaceItem);

  return request.data;
}
  
export async function Delete(id: string) {
    const request = await Api.delete(
        "item/",    
        { data: { idMongo:  id} }    
    );

    return request.data;
}


  export async function CreateFolder(newFolder:NewFolder) {
    const request = await Api.post<FolderInformation>("Item/folder", newFolder);
  
    return request.data;
  }

export async function CopyFolderStructure(copyFolderStructureVO: CopyFolderStructureVO) {
  const request = await Api.post<any>("Item/copyFolderStructure", copyFolderStructureVO);

  return request.data;
}
  

  export async function SetFolderFolow(setFolow: SetFolow) {
    const request = await Api.post<any>(
      "Item/permission/", setFolow
    );
    return request.data;
  }

  export async function SetItensFromWorkspaceHash(WorkspaceItem: WorkSpaceItemCreate){   
    const request = await Api.post<any>(
      "workspace/VerifyHash",WorkspaceItem
    );
    return request.data;
  }

  
export async function SetDraft(idItem: string) {
  const request = await Api.put<any>("item/draft/", {idChangeStatus:  idItem}    );

  return request.data;
}

export async function SetBatchDraft(batchDraft: BatchChangeStatusModel) {
  const request = await Api.put<any>("item/batchDraft/", batchDraft);

  return request.data;
}

export async function UndoDraft(idItem: string) {
  const request = await Api.put<any>("item/undoDraft/", {idChangeStatus:  idItem}    );

  return request.data;
}

export async function SetRelease(idItem: string) {
  const request = await Api.put<any>("item/release/", { idChangeStatus:  idItem}   );

  return request.data;
}

export async function SetBatchRelease(batchRelease: BatchChangeStatusModel) {
  const request = await Api.put<any>("Item/batchRelease/", batchRelease);

  return request.data;
}

export async function SetDescription(itemDescription: ItemDescription) {
  const request = await Api.put<any>("item/Description/", itemDescription);

  return request.data;
}

export async function SetOwner(owner: Owner) {
  const request = await Api.put(
    "Item/owner",owner     
  );

  return request.data;
}

export async function SetArchiving(archiving: Archiving) {
  const request = await Api.put<any>(
    "Item/archiving",archiving     
  );

  return request.data;
}

export async function AddPermissionUser(objectPermission: ObjectPermissionUser) {
  const request = await Api.put<any>("item/permissionUser/addUser",objectPermission);

  return request.data;
}

export async function RemovePermissionUser(objectPermission: ObjectPermissionUser) {
  const request = await Api.put<any>("item/permissionUser/removeUser",objectPermission);

  return request.data;
}

export async function AddPermissionGroup(objectPermission: ObjectPermission) {
  const request = await Api.put<any>("item/permission/addGroup",objectPermission);

  return request.data;
}

export async function RemovePermissionGroup(objectPermission: ObjectPermission) {
  const request = await Api.put<any>("item/permission/removeGroup",objectPermission);

  return request.data;
}
export async function BatchDownloadPost(batchDownload: BatchDownloadModel) {
  const request = await Api.post<IdModel>(
    "/BatchDownload",
    batchDownload
  );

  return request.data;
}

export const SetItemTag = async (tagsVO: TagsVO) => {
  const request = await Api.put<TagName[]>('item/SetItemTag', tagsVO)
  return request.data
}

export const SetTemporality = async (TemporalityVO: TemporalityVO) => {
  const request = await Api.post('item/temporality/set', TemporalityVO)
  return request.data
}

export async function ResetTemporality(id: string) {
  const request = await Api.delete(
      "item/temporality/reset/",    
      { data: { id:  id} }    
  );

  return request.data;
}