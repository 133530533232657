import { Api } from "../api";
import {
    WorkSpaceItem,
    FolderInformation,
    WorkSpaceUserName,
    WorkSpaceItemRevision,
    WorkSpaceItemTrakker,
    RevisionIsD4SignSigned,
    TrakkerList,
    trackerItemVO,
    BooleanVr,
    GetFlowInformationByItemVO,
    ItemTemporality,
    EnumAction,
    RevisionTrackerRequest,
    RevisionTracker,
    ReturnAutoArchiveVO,
    ReturnValidateDocumentVO,
    DeletedItem
} from "../../model/ItemModel";
import { GroupPermission } from "../../model/GroupModel";
import { UserPermission } from "../../model/UserModel";
import { Axios } from "axios";
import {ParameterViwer} from "../../pages/WorkSpaceItem/Viwer/ParameterViwer"
import { ReturnParameterViwer } from "../ReturnParameterViwer"; 

export async function GetFolderGroup(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<GroupPermission[]>(
      "Item/permission/" + idFolder + "?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }

export async function GetOneItem(itemId:string) {
    const request = await Api.get<WorkSpaceUserName>("item/" + itemId);
  
    return request.data;
  }
  
  export async function GetWorkSpace(WorkspaceId: string) {
      const request = await Api.get<WorkSpaceItem>( "item/workspace?workspaceId=" + WorkspaceId);
    
      return request.data;
  }

  export async function GetWork(WorkspaceId: string) {
    const request = await Api.get<WorkSpaceItem>( "item/workspace/" + WorkspaceId);
  
    return request.data;
}
  
  export async function GetWorkSpaceItemTrim(WorkspaceId: string, skip:number, limit:number, desc:boolean) {
      const request = await Api.get<WorkSpaceUserName[]>(
        "Item/workspace/"+ WorkspaceId +"?skip=" + skip + "&limit=" + limit + "&desc=" + desc 
      );
    
      return request.data; 
  }
  
  export async function GetWorkspaceItemTrimName(WorkspaceId: string, skip:number, limit:number,  desc:boolean, name: string) {
      const request = await Api.get<WorkSpaceUserName[]>(
          "Item/workspace/"+ WorkspaceId +"?skip=" + skip + "&limit=" + limit + "&desc=" + desc +"&name=" + name
      );
    
      return request.data;
    }
  
  export async function GetTreeByWorkspaceFolderIdAll(WorkspaceId: string) {
      const request = await Api.get<WorkSpaceUserName[]>( "Item/workspaceFolder/" + WorkspaceId);
    
      return request.data;
  }
  
  
  export async function GetWorkSpaceItemTrimRecursive(WorkspaceId: string,folderId:string, skip:number, limit:number, desc:boolean) {
      const request = await Api.get<WorkSpaceUserName[]>(
          "Item/workspace/" + WorkspaceId + "/" + folderId+"?skip=" + skip + "&limit=" + limit + "&desc=" + desc 
      );
    
      return request.data; 
  }
  
  export async function GetWorkspaceItemTrimNameRecursive(WorkspaceId: string,folderId:string, skip:number, limit:number,  desc:boolean, name: string) {
      const request = await Api.get<WorkSpaceItem[]>(
          "Item/workspace/" + WorkspaceId + "/" + folderId+"?skip=" + skip + "&limit=" + limit + "&desc=" + desc  +"&name=" + name
      );
    
      return request.data;
    }
  
    export async function GetFolderInformation(folderId:string) {
      const request = await Api.get<FolderInformation>(
          "Item/folderInformation/" + folderId
      );
    
      return request.data;
    }
  
    export async function DownloadViwer(idItem: string, parameter?:ParameterViwer, onDownloadProgress?: (progressEvent: any) => void){
      let returnParameter = ReturnParameterViwer(parameter, true);
      const request = await  Api.get<any>(
        "/Item/"+ idItem +"/file/DownloadViwer" + returnParameter,
        {
            responseType: 'blob',
            onDownloadProgress:onDownloadProgress
        }
      );

      return request.data;    
    }

    export async function Download(idItem: string, onDownloadProgress?: (progressEvent: any) => void){
        
      const request = await  Api.get<any>(
          "/Item/"+ idItem +"/file",
          {
              responseType: 'blob',
              onDownloadProgress: onDownloadProgress
          }
      );
  
      return request.data;
    }

    export async function DownloadThumbnail(idItem: string, onDownloadProgress?: (progressEvent: any) => void){
     
      const request = await  Api.get<any>(
          "/Item/"+ idItem +"/file/DownloadThumbnail",
          {
              responseType: 'blob',
              onDownloadProgress:onDownloadProgress
          }
      );
      return request.data;
    }

    export function DownloadWithoutLogin (idItem: string) {
      const config = require("../../services/config.json");
      const request = config.baseUrlApp + "Download/" + idItem;
      return request;
    }

    export async function DownloadRevision(idRevision: string, onDownloadProgress?: (progressEvent: any) => void){
        
      const request = await  Api.get<any>(
          "/Item/file/"+idRevision,
          {
              responseType: 'blob',
              onDownloadProgress:onDownloadProgress
          }
      );
  
      return request.data;
    }

    export async function GetD4SignStatus(idObject: string){
      const request = await Api.get<RevisionIsD4SignSigned>(
        "Item/GetD4SignSigned/" + idObject 
      );
      return request.data; 
    }

    export async function GetD4SignStatusRevision(idObject: string){
      const request = await Api.get<RevisionIsD4SignSigned>(
        "Item/GetD4SignSignedRevision/" + idObject 
      );
      return request.data; 
    }

    export function DownloadStream(idItem: string){
        
      return Api.get<any>(
          "/Item/"+ idItem +"/file",
          {
              responseType: 'blob',
          }
      );
    }
  
  export async function GetFolderGroupName(idFolder: string, skip:number, limit:number, name:string) {
      const request = await Api.get<GroupPermission[]>(
        "Item/permission/" + idFolder + "?skip=" + skip + "&limit=" + limit + "&name=" + name
      );
      return request.data; 
  }
      
  export async function GetFolderGroupAll(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<GroupPermission[]>(
      "Item/permission/" + idFolder + "/all?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
  
  export async function GetFolderGroupAllName(idFolder: string, skip:number, limit:number, name:string) {
    const request = await Api.get<GroupPermission[]>(
      "Item/permission/" + idFolder + "/all?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }
  
  export async function GetFolderFolow(idFolder: string) {
    const request = await Api.get<any>(
      "Item/permission/" + idFolder + "/folow"
    );
    return request.data;
  }

  //Busca o workspace inteiro
  export async function GetItensFromWorkspaceIdSearch(idWorkspace: string, skip:number, limit:number, name:string) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "Item/workspaceSearch/" + idWorkspace + "?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }
  
  //Busca no root
  export async function GetItensFromWorkspaceIdSearchOnlyRoot(idWorkspace: string,skip:number, limit:number, name:string) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "Item/folderSearch/" + idWorkspace + "?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }  

  //Busca no folder
  export async function GetItensFromWorkspaceIdSearchOnlyFolder(idWorkspace: string,folderId:string, skip:number, limit:number, name:string) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "Item/folderSearch/" + idWorkspace + "/"+ folderId + "?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }      
  
  export async function GetRevision(idItem: string){
    const request = await Api.get<WorkSpaceItemRevision[]>("item/Revision/" + idItem);

    return request.data;
  }

  export async function GetRevisionTracker(idItem: string){
    const request = await Api.get<WorkSpaceItemTrakker>("item/Revision/" + idItem + "/tracker");

    return request.data;
  }

  
  export async function GetAllTracker(OrganizationId: string,begin:string,end:string){
    const request = await Api.get<trackerItemVO[]>("Item/tracker/"+OrganizationId+"?begin="+begin+"&end="+end);

    return request.data;
  } 

  export async function GetAllRecentTracker(internalName: string){
    const request = await Api.get<trackerItemVO[]>("Item/recentTracker/"+internalName);

    return request.data;
  } 

  export async function GetAllTrackerTrim(OrganizationId: string,begin:string,end:string, skip:number,limit:number){
    const request = await Api.get<trackerItemVO[]>("Item/tracker/"+OrganizationId+"?begin="+begin+"&end="+end+"&skip="+skip+"&limit="+limit);

    return request.data;
  } 

  export async function GetItemUser(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<UserPermission[]>(
      "item/permissionUser/" + idFolder + "?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
  
  export async function GetItemUserName(idFolder: string, skip:number, limit:number, name:string) {
    const request = await Api.get<UserPermission[]>(
      "item/permissionUser/" + idFolder + "?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data; 
  }
  
  export async function GetItemUserAll(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<UserPermission[]>(
      "item/permissionUser/" + idFolder + "/all?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
  
  export async function GetItemUserAllName(idFolder: string, skip:number, limit:number, name:string) {
    const request = await Api.get<UserPermission[]>(
      "item/permissionUser/" + idFolder + "/all?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }

  export async function GetUsersItemPermissions(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<UserPermission[]>(
      "item/permissionUser/" + idFolder + "/usersAll?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
    
  export async function GetUsersItemPermissionsName(idFolder: string, skip:number, limit:number, name:string) {
    const request = await Api.get<UserPermission[]>(
      "item/permissionUser/" + idFolder + "/usersAll?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }




  export async function GetItemGroup(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<GroupPermission[]>(
      "item/permission/" + idFolder + "?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
  
  export async function GetItemGroupName(idFolder: string, skip:number, limit:number, name:string) {
    const request = await Api.get<GroupPermission[]>(
      "item/permission/" + idFolder + "?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data; 
  }
  
  export async function GetItemGroupAll(idFolder: string, skip:number, limit:number) {
    const request = await Api.get<GroupPermission[]>(
      "item/permission/" + idFolder + "/all?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
  
  export async function GetItemGroupAllName(idFolder: string, skip:number, limit:number, name:string) {
    const request = await Api.get<GroupPermission[]>(
      "item/permission/" + idFolder + "/all?skip=" + skip + "&limit=" + limit + "&name=" + name
    );
    return request.data;
  }

  export async function GetValidationByItem(idFolder: string,  idUser:string) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "item/ResponsibleValidationByItem/" + idFolder + "/" + idUser
    );
    return request.data;
  }

  export async function GetValidationByItemGroup(idFolder: string, groupId:string) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "item/ResponsibleValidationByItemGroup/" + idFolder + "/"+ groupId
    );
    return request.data;
  }

  export async function ItemValidationRulesBool(idVr: string){
    const request = await Api.get<BooleanVr>(
      "item/itemValidationRuleBool/" + idVr
    );
    return request.data;
  }

  export async function ItemWithValidation(idVr: string){
    const request = await Api.get<GetFlowInformationByItemVO>(
      "item/ItemWithValidation/" + idVr
    );
    return request.data;
  }

  export async function getEnumAction() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumAction")
  
    return request.data;
  }

  export async function getEnumOrder() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumOrder")
  
    return request.data;
  }

  export async function GetTrackerWithFilters(
    itemId: string, 
    enumOrderRevisionTracker: number,
    revisionSeletedId: string[],
    isPageReview: boolean
  ) {
      let path = `Item/filter/${itemId}?page=${isPageReview}&order=${enumOrderRevisionTracker}`;
      if (revisionSeletedId.length > 0) {
        path = `Item/filter/${itemId}?page=${isPageReview}&order=${enumOrderRevisionTracker}&revisions=${revisionSeletedId}`;
      }
      const request = await Api.get<RevisionTracker[]>(path);
      return request.data;
  }


  export async function GetItemByTag(idTag: string, skip:number, limit:number) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "Item/ItemByTag/" + idTag + "?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }

  export async function GetItemByTagAndFolder(idTag: string,folderId:string, skip:number, limit:number) {
    const request = await Api.get<WorkSpaceUserName[]>(
      "Item/ItemByTag/" + idTag + "/" + folderId + "?skip=" + skip + "&limit=" + limit
    );
    return request.data; 
  }
  export async function GetItemWithTemporality(itemId: string) {
    const request = await Api.get<ReturnValidateDocumentVO>(
      "Item/WithTemporality/" + itemId);
    return request.data;
  }
  
  export async function GetAllTemporalityByFilterDate(workspaceId: string,enumTemporality:number,begin:string,end:string, skip:number,limit:number){
    const request = await Api.get<ItemTemporality[]>("Item/TemporalityByFilterDate/"+workspaceId+"?enumTemporality="+enumTemporality+"&begin="+begin+"&end="+end+"&skip="+skip+"&limit="+limit);

    return request.data;
  } 

  export async function GetAllDeletedItems(nameOrg: string, filterMyFiles: boolean, workspaceId: string, searchParams: string, skip:number, limit:number){
    const request = await Api.get<DeletedItem[]>("Item/Deleted/"+nameOrg+"?filterMyFiles="+filterMyFiles+"&workspaceId="+workspaceId+"&searchParams="+searchParams+"&skip="+skip+"&limit="+limit);

    return request.data;
  } 

  export async function GetEnumTemporality() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumTemporality")
    
    return request.data;
  }

  export async function GetEnumValidationSize() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumValidationSize")
  
    return request.data;
  }

  export async function GetEnumValidationOrientation() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumValidationOrientation")
  
    return request.data;
  }

  export async function GetEnumValidationPalette() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumValidationPalette")
  
    return request.data;
  }

  
  export async function GetEnumValidationConvertionType() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumValidationConvertionType")
  
    return request.data;
  }

  
  export async function GetEnumValidationBackgroundColors() {
    const request = await Api.get<EnumAction[]>("Item/GetEnumValidationBackgroundColors")
  
    return request.data;
  }