import * as React from 'react';
import { Box, Modal } from '@mui/material';

interface CustomModalProps {
  children: React.ReactNode;
  isModalOpen: boolean;
  hideModal: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  padding: '30px',
  maxHeight: '95%',
  overflow: 'auto',
  scrollbarWidth: "thin"
};

export const CustomModal: React.FunctionComponent<CustomModalProps> = ({children, isModalOpen, hideModal}) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {children}
      </Box>
    </Modal>
  );
};

