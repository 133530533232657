import React, { FC, ReactElement } from 'react';

export interface CustomButtonProps {
  icon: ReactElement<any, any>;
  text?: string;
  isSelectedCheckBox?: boolean;
  onClick: () => void;
  handleHovered: (value: boolean) => void
  isHovered: boolean
}

const ButtonDeleteAllOrRestoreAll: FC<CustomButtonProps> = ({ icon: Icon, text, isSelectedCheckBox, onClick, handleHovered, isHovered }) => {
  return (
    <button
      disabled={isSelectedCheckBox}
      onClick={onClick}
      onMouseEnter={() => handleHovered(true)}
      onMouseLeave={() => handleHovered(false)}
      style={{
        cursor: 'pointer',
        height: '44px',
        padding: '10px 12px',
        fontSize: '18px',
        borderRadius: '6px',
        border: '1px solid',
        borderColor: isHovered ? 'rgb(34, 99, 141, 0.1)' : 'rgba(45, 45, 45, 0.2)',
        marginRight: '20px',
        backgroundColor: isHovered ? 'rgb(34, 99, 141, 0.1)' : 'transparent',
        display: 'flex',
        alignItems: 'center',
        color: isSelectedCheckBox
          ? 'rgba(45, 45, 45, 0.2)'
          : isHovered
          ? '#22638D'
          : '#2D2D2D'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {React.cloneElement(Icon, {
          color: isHovered ? '#22638D' : '#2d2d2d',
          opacity: isSelectedCheckBox ? '0.2' : isHovered ? '1' : '0.8',
        })}
        <div style={{ marginLeft: '10px', color: isSelectedCheckBox ? 'rgba(45, 45, 45, 0.2)' : isHovered ? '#22638D' : '#2D2D2D' }}>
          {text}
        </div>
      </div>
    </button>
  );
};

export default ButtonDeleteAllOrRestoreAll;
