import React, { useEffect, useState, useRef, useCallback  } from "react";
import stylesList from "../../Style/List.module.css"
import { useTranslation } from "react-i18next";
import { UserPermission } from "../../../model/UserModel";
import { 
  GetUsersItemPermissions, GetUsersItemPermissionsName
 } from "../../../services/Item/ItemServicesGet";
 import Btn from "../../../components/Button/Btn";
 import {SetPseudoValidation} from "../../../services/ValidationRuleService"
 import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertColor} from '@mui/material/Alert';

interface PermissionUserFolderAddConfig {
    idFolder: string;
    name: string;
    idVr:string;
    closeModal:() => void;
    removeUser:() => void;
}

interface UserConfig{
    name:string,
    idUser:string;
    ref?: React.Ref<HTMLLIElement>;
}

  function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
  
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });
  
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

  


const ListGroupValidation: React.FC<PermissionUserFolderAddConfig> = ({idFolder, name,idVr,closeModal,removeUser}) => {
    const { t, i18n } = useTranslation();
    const [userPermission, setUserPermission] = useState<UserPermission[]>([]);
    let [ItemMax, setItemMax] = useState<boolean>(true);
    let [skip, setSkip] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [alertColor, setAlertColor] = useState<AlertColor>("error");
    const [popupOk, setPopupOk] = useState<boolean>(false);

    const Users: React.ForwardRefExoticComponent<UserConfig> =
    React.forwardRef(({name,idUser}, ref: React.Ref<HTMLLIElement>) => {
    
    function onClickSelectionUser(){
        SetPseudoValidation(idVr,idFolder,idUser)
        .then(() => {
            setPopupOkExternalOk(true,"Usuário Atualizado com sucesso!")
        })
        .catch(() => {
            setPopupOkExternalError(true, "Erro ao atualizar usuário!")
        });

        closeModal();
        removeUser();
    }

    return (
        <div>
             <li className={`${stylesList.itemContent} ${stylesList.row}`} key={idUser} ref={ref}>
                    <div>{name}</div>  
                    <Btn  text={t("Select.message")} className={stylesList.btnSeleciont} onClick={onClickSelectionUser}/>           
            </li>
        </div>
    );
    });


    const lastItem = useVisibility(
      (visible) => {
        if (visible) {
          GetUsersItemPermissions(idFolder,skip, limit).then((newGroups) => {
            if (newGroups.length < limit) {
              setItemMax(false);
            }
            setSkip(skip + newGroups.length);
            setUserPermission([...userPermission, ...newGroups]);
          });
        }
        else
        {
          GetUsersItemPermissionsName(idFolder, skip, limit, name).then((newGroups) => {
            if (newGroups.length < limit) {
              setItemMax(false);
            }
            setSkip(skip + newGroups.length);
            setUserPermission([...userPermission, ...newGroups]);
          });
        }
      },
      [skip, idFolder, userPermission]
    );

    useEffect(() => {
      if(name == "")
      {
        GetUsersItemPermissions(idFolder,0, limit).then((newWork) => {
          if (newWork.length < limit) {
            setItemMax(false);
          }
          setSkip(newWork.length);
          setUserPermission(newWork);
        });
      }
      else
      {
        GetUsersItemPermissionsName(idFolder, 0, limit, name).then((newWork) => {
          if (newWork.length < limit) {
            setItemMax(false);
          }
          setSkip(newWork.length);
          setUserPermission(newWork);
        });
      }
    }, [idFolder,name]);

    function setPopupOkExternalError(set:boolean, message:string) {
        setAlertColor('error');
        setErrorMessage(message);
        setPopupOk(set);
      }
      function setPopupOkExternalOk(set:boolean, message:string) {
        setAlertColor('success');
        setErrorMessage(message);
        setPopupOk(set);
      }

      const handleClose = (event:any, reason:any) => {
        closePopUpOk();
      }
      const handleClosePO = (event:any) => {
        closePopUpOk();
      }
      function closePopUpOk() {
        setPopupOk(false);
      }
    
    return (
            <ul className={stylesList.ulContent}>
                <li className={`${stylesList.itemContent} ${stylesList.title}`}>
                    <>{t("Nome.username")}</>{" "}
                    </li>
                        {userPermission.map((userPerm) => (
                            <Users
                            key={userPerm.userId}
                            idUser={userPerm.userId}
                            name={userPerm.userName!}
                            ref={userPermission[userPermission.length - 1].userId === userPerm.userId && ItemMax ? lastItem : null}
                            />
                ))}
                <Snackbar
                   open={popupOk}
                   autoHideDuration={6000}
                   message="Archived"
                   onClose={handleClose}
                   anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                   sx={{ bottom: { xs: 50, sm: 20 } }}
                 >
                    <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                        {errorMessage}
                    </MuiAlert>
                </Snackbar>
            </ul>
    )
}

export default ListGroupValidation;