import React, { useState } from 'react';
import { CustomModal } from '../../components/CustomModal';
import recycleBinRestoreHeader from "../../../../assets/icons/recycleBinRestoreHeader.svg";
import { CustomButton } from '../../components/CustomButton';
import { ConfirmIcon } from '../../../../assets/icons/icons.tsx/ConfirmIcon';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../../assets/icons/icons.tsx/CancelIcon';
import { DeletedItem } from '../../../../model/ItemModel';
import RestoreResult from './components/RestoreResult';

interface RestoreLastConfirmationProps {
  item: DeletedItem;
  isConfirmed: boolean;
  hideModal: () => void;
  hasBeenConfirmed: () => void
}

const RestoreLastConfirmation: React.FC<RestoreLastConfirmationProps> = ({item, isConfirmed, hideModal, hasBeenConfirmed}) => {
  const { t, i18n } = useTranslation();

  const [isHoveredButtonCancel, setIsHoveredButtonCancel] = useState(false);


  function confirmRestore(){
    hideModal()
    hasBeenConfirmed()
  }


  return (
    <div>
      <CustomModal 
        isModalOpen={isConfirmed}
        hideModal={hideModal}
      >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={recycleBinRestoreHeader} alt='Lixeira'/>
        </div>

        <div style={{width:"100%", display:"flex", justifyContent: "center"}}>            
          <div style={{marginTop:"20px", font:"normal normal 600 20px/39px Segoe UI"}}>
            {t("RecicleBin.restoreTitleConfirm")}
          </div>
        </div>
        <div style={{padding:"10px 30px 10px 30px", display:"flex", justifyContent: "center", width:"100%"}}>
          <div style={{font:"normal normal normal 16px/24px Segoe UI", textAlign:"center", width: "540px"}}>
            {t("RecicleBin.headerRestoreLastConfirmation")}
          </div>
        </div>

        {item &&
          <RestoreResult
            fatherFolder={item.fatherFolder}
            extencion={item.extencion}
            itemType={item.itemType}
            name={item.name}
            workspaceName={item.workspaceName}
          />
        }

        <div style={{ width: '100%', display: 'flex', gap:"20px" }}>
          <CustomButton 
            icon={<CancelIcon color={isHoveredButtonCancel ? "#FFFFFF" : "#22638D"}/>} 
            onclick={hideModal} 
            setIsHovered={setIsHoveredButtonCancel} 
            text={t("RecicleBin.cancel")}
            backgrondColor='#FCFCFC'
            color='#22638D'
            hoveredBackgrondColor='#0d476d'
            hoveredColor='#FFFFFF'
          />
          <CustomButton 
            icon={<ConfirmIcon/>} 
            onclick={confirmRestore}  
            text={t("RecicleBin.confirm")}
            backgrondColor='#22638D'
            color='#FFFFFF'
            hoveredBackgrondColor='#0d476d'
          />
        </div>

      </CustomModal>
    </div>
  );
};

export default RestoreLastConfirmation;